//@ts-ignore
import { Button } from '@ux/balance-react';

import ButtonContainer from './ButtonContainer';
import CalendarDateInput from './CalendarDateInput';

function FilterForm(props: any) {

  return (
    <div className='margin-bottom-24'>
      <p className='filter-title'>Filters</p>
      <div className='batch-summary-search'>
      <div className="filter-search-container">
        <form className="filter-container" onSubmit={props.handleFilter} id='batch-summary-form'>
          <CalendarDateInput startDateWithEndPopover={true} label='Enter deposit start date' selected={props.startDate} onChange={props.handleOnChangeStartDate} name='Enter deposit start date' id='input-start-date' />
          <div className="left-24-gap-filters" style={{ display: 'flex' }}>
          <CalendarDateInput label='Enter deposit end date' selected={props.endDate} onChange={props.handleOnChangeEndDate} name='Enter deposit end date' id='input-end-date' showPopover={true} popoverText='View up to 90 days of batch information at a time.' />
          </div>
          <ButtonContainer>
            <Button
              classes="left-24-gap-filters batch-summary-search-button"
              size="medium"
              text="Search"
              type="submit"
              isLoading={props.isLoading}
            />
          </ButtonContainer>
        </form>
      </div>
      <div className='download-container top-gap-24'>
        {props.children}
      </div>
      </div>
    </div>
  );
}

export default FilterForm;
