interface propsPluralize {
    length: number;
}

const getPluralize = (length: number) => {
    if (length > 1) {
        return `${length} lockboxes`;
    } else {
        return `${length} lockbox`;
    }
};

const Pluralize = ({ length }: propsPluralize) => {
    return <span>({getPluralize(length)})</span>;
};

export default Pluralize;
