/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-ignore
import { Button, Modal, RadioGroup } from '@ux/balance-react';
// @ts-ignore
import { IconDownload } from '@ux/balance-icons';
import { useNavigate } from 'react-router-dom-v6';
import { useRecoilState } from 'recoil';
import { NavToggle } from '../atom';

function CustomModal(props: any) {
    const [toggle, setToggle] = useRecoilState(NavToggle);

    const navigate = useNavigate();

    const handleSearchAndReports = () => {
        setToggle(4);
        navigate("/search-and-reports");
        if (props.selfCloseSearchAndReports) {
            props.selfClose();
        };
       
    }

    return (
        <div>
            <div>
                {(!props.ButtonTitle) ?
                <></>
                :(props.downloadIconButton) ?
                <Button
                    clickMethod={props.OpenModal}
                    text={props.ButtonTitle}
                    variant="secondary"
                    size='small'
                    customIcon={IconDownload}
                    iconPosition='left'
                />
                :
                <Button
                    clickMethod={props.OpenModal}
                    text={props.ButtonTitle}
                    variant="text-primary"
                    size='small'
                />
                }
            </div>
            <Modal
                isOpen={props.ModalIsOpen}
                ariaLabel={props.ModalTitle || 'Modal title'}
                closeMethod={props.CloseModal}
                isModalTitleStatic
                modalCTAs={(props.isPDF && !props.isPDFstep2) ? 
                <><Button clickMethod={props.CloseModal} dataTestId="modal_close_button" text="Cancel" variant="secondary" size='small' /><Button clickMethod={props.GoToNext} dataTestId="modal_next_button" text="Next" variant="primary" size='small' /></>
                :(props.routeType) ?
                <><Button clickMethod={handleSearchAndReports} dataTestId="modal_close-route_button" text="Go to reports" variant="primary" size='small' /></>
                :(props.okType) ?
                <><Button clickMethod={props.CloseModal} dataTestId="modal_close-ok_button" text="Ok" variant="primary" size='small' /></>
                :(props.okRefreshType) ?
                <><Button clickMethod={props.Refresh} dataTestId="modal_refresh_button" text="Ok" variant="primary" size='small' /></> 
                :(props.yesOrNoType) ?
                <><Button clickMethod={props.CloseModal} dataTestId="modal_close_button" text={props.noText} variant="secondary" size='small' /><Button clickMethod={props.yesClickMethod} dataTestId="modal_next_button" text={props.yesText} variant="primary" size='small' /></>
                :(props.downloadReports) ?
                <>{props.showClose ? <Button clickMethod={props.CloseModal} dataTestId="modal_close_button" text="Close" variant="secondary" size='small' /> : <></>}<Button clickMethod={handleSearchAndReports} dataTestId="modal_report_button" text="Go to reports" variant="primary" size='small' /></>
                :(props.downloadIconButton) ?
                <>{props.showClose ? <Button clickMethod={props.CloseModal} dataTestId="modal_close_button" text="Close" variant="secondary" size='small' /> : <></>}<Button clickMethod={handleSearchAndReports} dataTestId="modal_report_button" text="Go to reports" variant="primary" size='small' /></>
                :
                <><Button clickMethod={handleSearchAndReports} dataTestId="modal_report_button" text="Go to reports" variant="primary" size='small' /></>
            }
                modalTitle=""
            >
                <h5>{props.ModalTitle}</h5>
                {(props.isPDF && !props.isPDFstep2) ?
                    <div className='gap-12'>
                        <RadioGroup
                            groupFormControlId="PDF Selection"
                            legendLabel="What information do you want to include in your PDF?"
                            name="file-format"
                            radioItems= {props.radioItems}
                        />
                    </div>
                    : <></>
                }
                {!props.isPDFstep2 ?
                
                <p
                    className="text-style-body-2"
                    style={{
                        marginTop: '1rem',
                        maxWidth: '100%'
                    }}
                >
                    <span>{props.ModalText} </span>
                </p>
                :
                <p
                    className="text-style-body-2"
                    style={{
                        marginTop: '1rem',
                        maxWidth: '100%'
                    }}
                >
                    <span>{props.ModalSTextStep2}</span>
                </p>
            }
            </Modal>
        </div>
    )
}

export default CustomModal;
