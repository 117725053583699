//@ts-ignore
import { TextInput, Button } from '@ux/balance-react';

import ButtonContainer from './ButtonContainer';

function SearchForm(props: any) {


  return (
    <div className='margin-bottom-24'>
      <div className='batch-detail-search'>
      <div>
      <p className='text-style-body-2 text-selection'>To view a different batch, enter a specific batch number and then select search.</p>
      <form className="filter-search-container" onSubmit={props.handleSearch}>
        <div className="download-dropdown-container">
          <div>
            <TextInput
              handleOnChange={props.handleOnChangeBatchNumber}
              initialValue={props.batchNumber}
              inputFieldButtonTestId="input-field-batch-number-button"
              label="Enter batch number"
              type="text"
              classes='width-152'
              size='small'
            />
          </div>
          <ButtonContainer >
            <Button
              classes="left-24-gap-filters"
              size="medium"
              text="Search"
              type="submit"
            />
          </ButtonContainer>
        </div>
      </form>
      </div>
      
      <div className='download-container top-gap-66'>
        {props.children}
      </div>
      </div>
    </div>
  );
}

export default SearchForm;
