/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom-v6';
import dateformat from "dateformat";
// @ts-ignore
import { Button, Select } from '@ux/balance-react';
// @ts-ignore
import { IconSearch } from '@ux/balance-icons';

import { LockboxSummaryData, banker_summary_reqBody_startDate, banker_summary_reqBody_endDate, Client_code, batch_summary_reqBody_lockbox, BatchSummaryMirror, ExceptionSubscribe, Exception_Banker_CurrentTab, exception_lockbox_code, exception_summary_processDate, exception_reportDate, exception_batch_ID, selected_corp_code } from '../atom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import LockboxdataSummary from '../API_Integrations/LockboxSummary';
import { exceptionSummaryData, ExceptionSummaryRes, ExceptionCorpCodeRes, exceptionsCorpCodeData, generateExceptionSummaryReport } from '../API_Integrations/exceptionManagement';
import { ILockboxSummary_req_body } from '../interfaces/ILockboxSummary';

import Table from '../components/Table';
import ClientHeader from '../components/ClientHeader';
import CalendarDateInput from '../components/CalendarDateInput';
import ModalsContainer from '../components/ModalsContainer';
import CustomModal from '../components/CustomModal';
import HeadingReportingSummary from '../components/HeadingReportingSummary';
import Loader from '../components/Loader';
import { useTitle } from '../utils/use-title';
import DateDisplay from '../components/DateDisplay';
import BooleanDisplay from '../components/BooleanDisplay';
import { useDifferenceDate, ifStartNull, ifEndNull } from '../utils/use-date-calculator';
import { useFocusOnElemnt } from '../utils/use-focus-on-element';
import { useRepeatAnnouncement } from '../utils/use-repeat-live-announcement';
interface TableCaptionDates { start: string | null, end: string | null }

function BankerLockboxSummary() {
    useTitle("Lockbox summary banker");
    const subscribeException = useRecoilValue(ExceptionSubscribe);
    const [currentTab, setCurrentTab] = useRecoilState(Exception_Banker_CurrentTab);
    const setException_lockboxCode = useSetRecoilState(exception_lockbox_code);
    const [loading, setLoading] = useState(false);
    const [modalIsOpenViewReport, setModalIsOpenViewReport] = useState(false);
    const [startDate, setStartDate] = useRecoilState(banker_summary_reqBody_startDate);
    const [endDate, setEndDate] = useRecoilState(banker_summary_reqBody_endDate);
    const [data_lockbox, setData_lockbox] = useRecoilState(LockboxSummaryData);
    const [lockbox, setLockbox] = useRecoilState(batch_summary_reqBody_lockbox);
    const [batchMirror, setBatchMirror] = useRecoilState(BatchSummaryMirror);
    const setException_batchID = useSetRecoilState(exception_batch_ID);
    const setSelected_corpCode = useSetRecoilState(selected_corp_code);
    const [showValidationDateDiff, setShowValidationDateDiff] = useState(false);
    const [tableCaptionDates, setTableCaptionDates] = useState<TableCaptionDates>({ start: '', end: '' });
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [liveAnnouncementText, setLiveAnnouncementText] = useState("");
    const [processDate, setProcessDate] = useRecoilState(exception_summary_processDate);
    const [FormattedProcessDate, setFormattedProcessDate] = useState('');
    const [data_exceptions, setData_exceptions] = useState<ExceptionSummaryRes[]>([]);
    const [loadingExceptions, setLoadingExceptions] = useState(false);
    const [loadingExceptionsSearch, setLoadingExceptionsSearch] = useState(false);
    const exception_reports_options = [{ label: 'Daily decision detail', value: 'dailyDecisionDetail' }, { label: 'Daily decision summary', value: 'dailyDecisionSummary' }];
    const [selectedReports, setSelectedReports] = useState('dailyDecisionDetail');
    const [reportDate, setReportDate] = useRecoilState(exception_reportDate);
    const [FormattedReportDate, setFormattedReportDate] = useState('');
    const [modalIsOpenDownload, setModalIsOpenDownload] = useState(false);
    const [corpCodes, setCorpCodes] = useState<ExceptionCorpCodeRes[]>([]);
    const initialTextRef = useRef<HTMLHeadingElement | null>(null);

    useFocusOnElemnt(initialTextRef);
    const navigate = useNavigate();

    let announcement = "Loading, please wait.";
    const repeatLoadingAnnouncement = () => {
        setLiveAnnouncementText(announcement);
        announcement += '.';
    };

    useRepeatAnnouncement({ repeatLoadingAnnouncement, loadingSearch });

    //lockbox API
    const HandleLockboxSearch = async () => {
        let start_date = startDate;
        let end_date = endDate;
        const formattedDateStart = start_date !== null ? dateformat(start_date, "yyyy-mm-dd") : dateformat(ifStartNull(end_date), "yyyy-mm-dd");
        const formattedDateEnd = end_date !== null ? dateformat(end_date, "yyyy-mm-dd") : dateformat(ifEndNull(start_date), "yyyy-mm-dd");
        const isNotvalid: boolean = useDifferenceDate(start_date, end_date);
        if (!isNotvalid) {
            try {
                setLoadingSearch(true);
                setLiveAnnouncementText("Loading, please wait.");
                const result = await LockboxdataSummary.details({
                    startDate: formattedDateStart,
                    endDate: formattedDateEnd
                });
                if (result !== undefined) {
                    setData_lockbox(result);
                    const formattedCaptionDateStart = start_date !== null ? dateformat(start_date, "mm/dd/yyyy") : dateformat(ifStartNull(end_date), "mm/dd/yyyy");
                    const formattedCaptionDateEnd = end_date !== null ? dateformat(end_date, "mm/dd/yyyy") : dateformat(ifEndNull(start_date), "mm/dd/yyyy");
                    setTableCaptionDates({ start: formattedCaptionDateStart, end: formattedCaptionDateEnd });
                    if (result && result.length > 1) {
                        setLiveAnnouncementText(`${result.length} results found.`);
                    } else if (result && result.length === 1) {
                        setLiveAnnouncementText(`${result.length} result found.`);
                    } else {
                        setLiveAnnouncementText("No result found. Please set filters and try again.");
                    }

                } else {
                    console.log('items not found');

                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoadingSearch(false);
            }
        } else {
            setShowValidationDateDiff(true);
        }
    }

    useEffect(() => {
        setStartDate(new Date());
        setEndDate(new Date());
        setProcessDate(new Date());
        getLockboxSummary({
            startDate: dateformat(new Date(), "yyyy-mm-dd"),
            endDate: dateformat(new Date(), "yyyy-mm-dd")
        }).then(() => {
            setTableCaptionDates({ start: dateformat(new Date(), "mm/dd/yyyy"), end: dateformat(new Date(), "mm/dd/yyyy") });
        });
        //
        setFormattedProcessDate(dateformat(new Date(), "mm/dd/yyyy"));
        const date = dateformat(new Date(), "yyyy-mm-dd");
        getExceptionData(date);
    }, []);

    useEffect(() => {
    }, [data_lockbox]);


    //start date
    const HandleOnChangeStartDate = (date: any) => {
        setStartDate(date);
    }

    //end date
    const HandleOnChangeEndDate = (date: any) => {
        setEndDate(date);
    }

    //lockbox summary API
    const getLockboxSummary = async (ReqBody: ILockboxSummary_req_body) => {
        setLoading(true);
        let result = await LockboxdataSummary.details(ReqBody);
        if (result !== undefined) {
            setData_lockbox(result);
            setLoading(false);
        } else {
            console.log('items not found');
        }

    }

    // View Report as PDF
    const handleViewReport = () => {
        LockboxdataSummary.generateReport({ startDate: dateformat(startDate, "yyyy-mm-dd"), endDate: dateformat(endDate, "yyyy-mm-dd"), fileType: 'Pdf' });
        setModalIsOpenViewReport(true);
    }

    const handleLockboxSelection = (row: any) => {
        setLockbox(row.lockboxCode);
        setBatchMirror({
            lockboxCode: row.lockboxCode,
            lockboxName: row.lockboxName || '',
            siteName: row.siteName || '',
            totalDeposits: row.totalDepositAmountFormatted,
            totalBatches: row.batchesCountFormatted,
            totalTransactions: row.transactionsCountFormatted,
            status: row.status
        });
        navigate(`/batch-summary/${row.lockboxCode}`);
    };

    const handleCSV = () => {
        generateExceptionSummaryReport({ processingDate: dateformat(processDate, "yyyy-mm-dd"), fileType: 'Csv' })
        setModalIsOpenViewReport(true);
    }

    const handleDownloadReports = () => {
        handleCSV();
        setModalIsOpenDownload(true);
    }
    //process date

    const HandleOnChangeProcessDate = (date: any) => {
        setProcessDate(date);
    }
    const HandleOnChangeReportDate = (date: any) => {
        setReportDate(date);
    }

    const getExceptionData = async (date: string) => {
        setLoadingExceptions(true);
        let result = await exceptionSummaryData(date);
        let corpCodesResult = await exceptionsCorpCodeData(date);
        if (result !== undefined) {
            setData_exceptions(result);
            setLoading(false);
        } else {
            console.log('items not found');
        }
        if(corpCodesResult !== undefined) {
            setCorpCodes(corpCodesResult);
        }
        setLoadingExceptions(false);
    }

    useEffect(() => {
        if (loadingExceptionsSearch) {
            const intervalId = setInterval(() => {
                repeatLoadingAnnouncement();
            }, 5000);

            return () => {
                clearInterval(intervalId);
            }
        }
    }, [loadingExceptionsSearch, repeatLoadingAnnouncement]);

    const HandleExceptionSearch = async () => {
        const date = dateformat(processDate, "yyyy-mm-dd");
        setLoadingExceptionsSearch(true);
        try {
            setLiveAnnouncementText("Loading, please wait.");
            const result = await exceptionSummaryData(date);
            let corpCodesResult = await exceptionsCorpCodeData(date);
            if(corpCodesResult !== undefined) {
                setCorpCodes(corpCodesResult);
            }
            if (result !== undefined) {
                setData_exceptions(result);
                setFormattedProcessDate(dateformat(processDate, "mm/dd/yyyy"));
                if (result && result.length > 1) {
                    setLiveAnnouncementText(`${result.length} results found.`);
                } else if (result && result.length === 1) {
                    setLiveAnnouncementText(`${result.length} result found.`);
                } else {
                    setLiveAnnouncementText("You don’t have any exceptions for this date. Please choose another process date.");
                }

            } else {
                console.log('items not found');

            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingExceptionsSearch(false);
        }
    }

    useEffect(() => {
        const selectedTab = document.querySelector('[aria-selected="true"]') as HTMLElement | null;
        if(selectedTab) {
            selectedTab.focus();
        }
    }, [currentTab]);
    
    useEffect(() => {
        const keyDownHandler = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                e.preventDefault();

                const targetedElement = document.activeElement as HTMLElement;
                const searchForm = document.getElementById('reporting-summary-form') as HTMLFormElement | null;
                const searchFormException = document.getElementById('exception-summary-form') as HTMLFormElement | null;
                
                if (searchForm && searchForm.checkValidity() && (targetedElement.classList.contains('reporting-summary-button') || targetedElement.tagName === 'BODY')) {
                    HandleLockboxSearch();
                }
                if (searchFormException && searchFormException.checkValidity() && (targetedElement.classList.contains('exception-summary-search-button') || targetedElement.tagName === 'BODY')) {
                    HandleExceptionSearch();
                }
                if(targetedElement.tagName === 'BUTTON' && (!targetedElement.classList.contains('reporting-summary-button') || !targetedElement.classList.contains('exception-summary-search-button'))) {
                    targetedElement.click();
                }
                if (targetedElement.tagName === 'A' && (!targetedElement.classList.contains('reporting-summary-button') || !targetedElement.classList.contains('exception-summary-search-button'))) {
                    targetedElement.click();
                }       
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };

    }, [HandleLockboxSearch, HandleExceptionSearch]);

    const handleExceptionBoxSelection = (row: any) => {
        setException_lockboxCode(row.lockboxCode);
        navigate(`/exception-batch-summary/${row.lockboxCode}`);
    };
    const handleOnChangeSelectReports = (event: Event) => setSelectedReports((event.target as HTMLInputElement).value);

    const handleGoToReports = () => {
        if(selectedReports === 'dailyDecisionSummary') {
            navigate(`/exception-daily-summary/`);
        } else if(selectedReports === 'dailyDecisionDetail') {
            navigate(`/exception-daily-decision/`);
        }
    }

    const handleCorpCodeSelection = (row: any) => {
        setException_batchID(0);
        setSelected_corpCode(row.corpCode);
        setException_lockboxCode(row.lockboxCode);
        navigate(`/exception-batch-detail/${row.lockboxCode}`);
    }

    return (
        <main>
            <ClientHeader showClientName={true} showBreadcrumbs={false} />
            {subscribeException.type === 'Retail' && subscribeException.subscribed ?
                <div className='tabs-container'>
                    <div role='tablist' className='tabs-header'>
                        <button
                            role='tab'
                            aria-selected={currentTab === "REPORTINGSUMMARY"}
                            aria-controls="REPORTINGSUMMARY"
                            onClick={() => { setCurrentTab('REPORTINGSUMMARY') }}
                            className={currentTab === 'REPORTINGSUMMARY' ? 'custom-tabs active-tabs' : 'custom-tabs'}>Reporting summary {data_lockbox.length > 1 ? `(${data_lockbox.length} lockboxes)` : `(${data_lockbox.length} lockbox)`}</button>
                        <button
                            role='tab'
                            aria-selected={currentTab === "EXCEPTIONMANAGEMENT"}
                            aria-controls="EXCEPTIONMANAGEMENT"
                            onClick={() => { setCurrentTab('EXCEPTIONMANAGEMENT') }}
                            className={currentTab === 'EXCEPTIONMANAGEMENT' ? 'custom-tabs active-tabs' : 'custom-tabs'}>
                            Exception management ({data_exceptions.length})
                        </button>
                    </div>
                    <div role='tabpanel' className='tabs-content top-gap-40'>
                        {currentTab === 'REPORTINGSUMMARY' ? (
                            <div>
                                {!loading ?
                                    <>
                                        <form className='lockbox-search-control' onSubmit={(e: FormEvent<HTMLFormElement>) => { e.preventDefault(); HandleLockboxSearch(); }} id='reporting-summary-form'>
                                            <CalendarDateInput startDateWithEndPopover={true} label='Enter deposit start date' selected={startDate} onChange={HandleOnChangeStartDate} name='Enter deposit start date' id='input-start-date' />
                                            <div className="left-24-gap-filters" style={{ display: 'flex' }}>
                                                <CalendarDateInput label='Enter deposit end date' selected={endDate} onChange={HandleOnChangeEndDate} name='Enter deposit end date' id='input-end-date' showPopover={true} popoverText='View up to 90 days of information at a time.' />
                                            </div>
                                            <div className='lockbox-search-button reporting-summary-button'>
                                                <Button
                                                    classes="reporting-summary-search-button"
                                                    size="medium"
                                                    text="Search"
                                                    type="submit"
                                                    isLoading={loadingSearch}
                                                />
                                            </div>
                                        </form>
                                        <ModalsContainer>
                                            <CustomModal okType ModalText='Please enter a date range up to 90 days.' ModalTitle='Incorrect date range' ModalIsOpen={showValidationDateDiff} CloseModal={() => { setShowValidationDateDiff(false) }} />
                                            <CustomModal
                                                downloadIconButton={true}
                                                showClose={true}
                                                ButtonTitle='Download report as PDF'
                                                ModalIsOpen={modalIsOpenViewReport}
                                                OpenModal={handleViewReport}
                                                CloseModal={() => { setModalIsOpenViewReport(false) }}
                                                ModalText='Your PDF has been requested and will be available in Reports.'
                                                ModalTitle='Request submitted' />
                                        </ModalsContainer>
                                        <div className='lockbox-summary-table'>
                                            <span aria-live='polite' className='announcement-text'>{liveAnnouncementText}</span>
                                            <Table
                                                ariaDescribedBy={null}
                                                caption={`Reporting Summary, ${data_lockbox.length > 1 ? `${data_lockbox.length} lockboxes` : `${data_lockbox.length} lockbox`} ${tableCaptionDates.start !== tableCaptionDates.end ? `from` : `for`} ${tableCaptionDates.start} ${tableCaptionDates.start !== tableCaptionDates.end ? `to ${tableCaptionDates.end}` : ``}`}
                                                classes=""
                                                columnData={
                                                    [
                                                        {
                                                            rowCellTableRowComponentsArrayIndex: 0,
                                                            headerId: 'lockboxCode',
                                                            type: 'component',
                                                            headerLabel: 'Box ID'
                                                        },
                                                        {
                                                            headerId: 'siteName',
                                                            headerLabel: 'Site'
                                                        },
                                                        {
                                                            headerId: 'lockboxName',
                                                            headerLabel: 'Box name'
                                                        },
                                                        {
                                                            headerId: 'totalDepositAmountFormatted',
                                                            headerLabel: 'Deposit'
                                                        },
                                                        {
                                                            headerId: 'batchesCountFormatted',
                                                            headerLabel: 'Batches'
                                                        },
                                                        {
                                                            headerId: 'transactionsCountFormatted',
                                                            headerLabel: 'Transactions'
                                                        },
                                                        {
                                                            headerId: 'archiveDaysFormatted',
                                                            headerLabel: 'Archive days'
                                                        },
                                                        {
                                                            headerId: 'from',
                                                            headerLabel: 'From',
                                                            type: 'component',
                                                            rowCellTableRowComponentsArrayIndex: 3
                                                        },
                                                        {
                                                            headerId: 'to',
                                                            headerLabel: 'To',
                                                            type: 'component',
                                                            rowCellTableRowComponentsArrayIndex: 4
                                                        },
                                                        {
                                                            headerId: 'status',
                                                            headerLabel: 'Status'
                                                        },
                                                        {
                                                            headerId: 'isMedical',
                                                            headerLabel: 'HIPAA',
                                                            type: 'component',
                                                            rowCellTableRowComponentsArrayIndex: 1
                                                        },
                                                        {
                                                            headerId: 'isRms',
                                                            headerLabel: 'RMS',
                                                            type: 'component',
                                                            rowCellTableRowComponentsArrayIndex: 2
                                                        },
                                                    ]
                                                }
                                                data={data_lockbox}
                                                tableRowComponents={[
                                                    (props: any) => (
                                                        <Button
                                                            variant="text-primary"
                                                            clickMethod={() => handleLockboxSelection(props.row.original)}
                                                            text={props.row.original.lockboxCode}
                                                            classes="handle-lockbox-selection underlined-links"
                                                            dataTestId={props.row.original.lockboxCode}
                                                        />
                                                        // <p>
                                                        //     <a onClick={() => handleLockboxSelection(props.row.original)} href={`/batch-summary/${props.row.original.lockboxCode}`} className="table-row-links handle-lockbox-selection">{props.row.original.lockboxCode}</a>
                                                        // </p>
                                                    ),
                                                    (props: any) => (
                                                        <div className='medical-items'>
                                                            <BooleanDisplay value={props.row.original.isMedical} />
                                                        </div>
                                                    ),
                                                    (props: any) => (
                                                        <div className='rms-items'>
                                                            <BooleanDisplay value={props.row.original.isRms} />
                                                        </div>
                                                    ),
                                                    (props: any) => (
                                                        <DateDisplay date={props.row.original.from} format="paddedShortDate" gmt />
                                                    ),
                                                    (props: any) => (
                                                        <DateDisplay date={props.row.original.to} format="paddedShortDate" gmt />
                                                    )
                                                ]}
                                            />
                                        </div>
                                    </>
                                    :
                                    <Loader />
                                }
                            </div>
                        )
                            : currentTab === 'EXCEPTIONMANAGEMENT' ? (
                                <div>
                                    {!loadingExceptions ?
                                        <div>
                                            <div className='exception-summary-heading'>
                                                <h4 ref={initialTextRef} tabIndex={-1} className="text-style-heading-4 heading-focus-text">Exception summary for {FormattedProcessDate}</h4>
                                            </div>
                                            <form className='exception-search-control' onSubmit={(e: FormEvent<HTMLFormElement>) => { e.preventDefault(); HandleExceptionSearch(); }} id='exception-summary-form'>
                                                <CalendarDateInput label='Select process date' selected={processDate} onChange={HandleOnChangeProcessDate} name='Select process date' id='input-process-date' />
                                                <div className=''>
                                                    <Button
                                                        classes="exception-summary-search-button"
                                                        size="medium"
                                                        text="Search"
                                                        type="submit"
                                                        customIcon={IconSearch}
                                                        iconPosition='left'
                                                        isLoading={loadingExceptionsSearch}
                                                    />
                                                </div>
                                            </form>
                                             <span aria-live='polite' className='announcement-text'>{liveAnnouncementText}</span>
                                            {!loadingExceptions && data_exceptions.length > 0 ?
                                                <div className='lockbox-summary-table'>
                                                    <ModalsContainer>
                                                        <CustomModal
                                                            downloadIconButton={true}
                                                            showClose={true}
                                                            ButtonTitle='Download report as CSV'
                                                            ModalIsOpen={modalIsOpenDownload}
                                                            OpenModal={handleDownloadReports}
                                                            CloseModal={() => { setModalIsOpenDownload(false) }}
                                                            ModalText='Your report has been requested and will be available in Reports.'
                                                            ModalTitle='Request submitted'
                                                        />
                                                    </ModalsContainer>
                                                    <Table
                                                        ariaDescribedBy={null}
                                                        caption={`Exception Summary, for ${FormattedProcessDate}`}
                                                        classes="vertically-ruled-table"
                                                        columnData={
                                                            [
                                                                {
                                                                    rowCellTableRowComponentsArrayIndex: 0,
                                                                    headerId: 'lockboxCode',
                                                                    type: 'component',
                                                                    headerLabel: 'Box ID'
                                                                },
                                                                {
                                                                    headerId: 'lockboxName',
                                                                    headerLabel: 'Box name'
                                                                },
                                                                {
                                                                    headerId: 'totalVolumeFormatted',
                                                                    headerLabel: 'Total volume'
                                                                },
                                                                {
                                                                    headerId: 'totalAmountFormatted',
                                                                    headerLabel: 'Total amount'
                                                                },
                                                                {
                                                                    headerId: 'completedVolumeFormatted',
                                                                    headerLabel: 'Completed volume'
                                                                },
                                                                {
                                                                    headerId: 'completedAmountFormatted',
                                                                    headerLabel: 'Completed amount'
                                                                },
                                                                {
                                                                    headerId: 'returnedVolumeFormatted',
                                                                    headerLabel: 'Returns volume'
                                                                },
                                                                {
                                                                    headerId: 'returnedAmountFormatted',
                                                                    headerLabel: 'Returns amount',
                                                                },
                                                                {
                                                                    headerId: 'escalatedVolumeFormatted',
                                                                    headerLabel: 'Escalated volume',
                                                                },
                                                                {
                                                                    headerId: 'escalatedAmountFormatted',
                                                                    headerLabel: 'Escalated amount'
                                                                },
                                                                {
                                                                    headerId: 'rejectedVolumeFormatted',
                                                                    headerLabel: 'Rejected volume',
                                                                },
                                                                {
                                                                    headerId: 'rejectedAmountFormatted',
                                                                    headerLabel: 'Rejected amount'
                                                                },
                                                            ]
                                                        }
                                                        data={data_exceptions}
                                                        tableRowComponents={[
                                                            (props: any) => (
                                                                <Button
                                                                    variant="text-primary"
                                                                    clickMethod={() => handleExceptionBoxSelection(props.row.original)}
                                                                    text={props.row.original.lockboxCode}
                                                                    classes="handle-lockbox-selection underlined-links"
                                                                    dataTestId={props.row.original.lockboxCode}
                                                                />
                                                                // <p>
                                                                //     <a onClick={() => handleExceptionBoxSelection(props.row.original)} className="table-row-links" href={`/exception-batch-summary/${props.row.original.lockboxCode}`}>{props.row.original.lockboxCode}</a>
                                                                // </p>
                                                            ),
                                                        ]}
                                                    />
                                                    {(corpCodes && corpCodes.length > 0) && <div>
                                                        <div className='light-divider'></div>
                                                        <p className='corp-code-title'>Corp Codes</p>
                                                        <div>
                                                            <Table
                                                                ariaDescribedBy={null}
                                                                caption={`Corp codes for ${FormattedProcessDate}`}
                                                                classes=""
                                                                columnData={
                                                                    [
                                                                        {
                                                                            headerId: 'lockboxCode',
                                                                            headerLabel: 'Box ID'
                                                                        },
                                                                        {
                                                                            rowCellTableRowComponentsArrayIndex: 0,
                                                                            headerId: 'code',
                                                                            type: 'component',
                                                                            headerLabel: 'Corp code'
                                                                        },
                                                                        {
                                                                            headerId: 'exceptions',
                                                                            headerLabel: 'Exceptions'
                                                                        },
                                                                        {
                                                                            headerId: 'exceptionsTotalFormatted',
                                                                            headerLabel: 'Exception total'
                                                                        }
                                                                    ]
                                                                }
                                                                data={corpCodes}
                                                                tableRowComponents={[
                                                                    (props: any) => (
                                                                        <Button
                                                                            variant="text-primary"
                                                                            clickMethod={() =>handleCorpCodeSelection(props.row.original)}
                                                                            text={`${props.row.original.corpCode} - ${props.row.original.corpCodeName}`}
                                                                            classes="handle-lockbox-selection underlined-links"
                                                                            dataTestId={props.row.original.corpCode}
                                                                        />
                                                                        // <p>
                                                                        //     <a onClick={() => handleCorpCodeSelection(props.row.original)} className="table-row-links" href={`/exception-transaction-detail/${props.row.original.corpCode}`}>{props.row.original.corpCode} - {props.row.original.corpCodeName}</a>
                                                                        // </p>
                                                                    ),
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                    }
                                                    <div className='light-divider'></div>
                                                    <p className='exception-reports-title'>Exception reports</p>
                                                    <div className='exception-report-control '>
                                                        <Select
                                                            label="Report type"
                                                            name="Report type"
                                                            size='small'
                                                            classes='width-242'
                                                            options={exception_reports_options}
                                                            handleOnChange={handleOnChangeSelectReports}
                                                            value={selectedReports}
                                                        />
                                                        <div className="left-24-gap-filters width-226 top-gap-8">
                                                            <CalendarDateInput label='Enter report date' selected={reportDate} onChange={HandleOnChangeReportDate} name='Enter report date' id='input-report-date' />
                                                        </div>
                                                        <div className='left-24-gap-filters top-gap-32 '>
                                                            <Button
                                                                classes="exception-summary-report-button"
                                                                size="medium"
                                                                text="Go to report"
                                                                variant="secondary"
                                                                clickMethod={() => handleGoToReports()}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                : !loadingExceptions && data_exceptions.length === 0 ?
                                                    <p className='zero-state'>You don’t have any exceptions for this date. Please choose another process date.</p>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        :
                                        <Loader />
                                    }
                                </div>
                            )
                                : (
                                    <></>
                                )
                        }
                    </div>

                </div>
                :
                <div>
                    {!loading ?
                        <>
                            <HeadingReportingSummary length={data_lockbox.length} />
                            <form className='lockbox-search-control' onSubmit={(e: FormEvent<HTMLFormElement>) => { e.preventDefault(); HandleLockboxSearch(); }} id='reporting-summary-form'>
                                <CalendarDateInput startDateWithEndPopover={true} label='Enter deposit start date' selected={startDate} onChange={HandleOnChangeStartDate} name='Enter deposit start date' id='input-start-date' />
                                <div className="left-24-gap-filters" style={{ display: 'flex' }}>
                                    <CalendarDateInput label='Enter deposit end date' selected={endDate} onChange={HandleOnChangeEndDate} name='Enter deposit end date' id='input-end-date' showPopover={true} popoverText='View up to 90 days of information at a time.' />
                                </div>
                                <div className='lockbox-search-button reporting-summary-button'>
                                    <Button
                                        classes="reporting-summary-search-button"
                                        size="medium"
                                        text="Search"
                                        type="submit"
                                        isLoading={loadingSearch}
                                    />
                                </div>
                            </form>
                            <ModalsContainer>
                                <CustomModal okType ModalText='Please enter a date range up to 90 days.' ModalTitle='Incorrect date range' ModalIsOpen={showValidationDateDiff} CloseModal={() => { setShowValidationDateDiff(false) }} />
                                <CustomModal ButtonTitle='Download report as PDF' ModalIsOpen={modalIsOpenViewReport} OpenModal={handleViewReport} CloseModal={() => { setModalIsOpenViewReport(false) }} ModalText='Your PDF has been requested and will be available in Reports.' ModalTitle='Request submitted' />
                            </ModalsContainer>
                            <div className='lockbox-summary-table'>
                                <span aria-live='polite' className='announcement-text'>{liveAnnouncementText}</span>
                                <Table
                                    ariaDescribedBy={null}
                                    caption={`Reporting Summary, ${data_lockbox.length > 1 ? `${data_lockbox.length} lockboxes` : `${data_lockbox.length} lockbox`} ${tableCaptionDates.start !== tableCaptionDates.end ? `from` : `for`} ${tableCaptionDates.start} ${tableCaptionDates.start !== tableCaptionDates.end ? `to ${tableCaptionDates.end}` : ``}`}
                                    classes=""
                                    columnData={
                                        [
                                            {
                                                rowCellTableRowComponentsArrayIndex: 0,
                                                headerId: 'lockboxCode',
                                                type: 'component',
                                                headerLabel: 'Box ID'
                                            },
                                            {
                                                headerId: 'siteName',
                                                headerLabel: 'Site'
                                            },
                                            {
                                                headerId: 'lockboxName',
                                                headerLabel: 'Box name'
                                            },
                                            {
                                                headerId: 'totalDepositAmountFormatted',
                                                headerLabel: 'Deposit'
                                            },
                                            {
                                                headerId: 'batchesCountFormatted',
                                                headerLabel: 'Batches'
                                            },
                                            {
                                                headerId: 'transactionsCountFormatted',
                                                headerLabel: 'Transactions'
                                            },
                                            {
                                                headerId: 'archiveDaysFormatted',
                                                headerLabel: 'Archive days'
                                            },
                                            {
                                                headerId: 'from',
                                                headerLabel: 'From',
                                                type: 'component',
                                                rowCellTableRowComponentsArrayIndex: 3
                                            },
                                            {
                                                headerId: 'to',
                                                headerLabel: 'To',
                                                type: 'component',
                                                rowCellTableRowComponentsArrayIndex: 4
                                            },
                                            {
                                                headerId: 'status',
                                                headerLabel: 'Status'
                                            },
                                            {
                                                headerId: 'isMedical',
                                                headerLabel: 'HIPAA',
                                                type: 'component',
                                                rowCellTableRowComponentsArrayIndex: 1
                                            },
                                            {
                                                headerId: 'isRms',
                                                headerLabel: 'RMS',
                                                type: 'component',
                                                rowCellTableRowComponentsArrayIndex: 2
                                            },
                                        ]
                                    }
                                    data={data_lockbox}
                                    tableRowComponents={[
                                        (props: any) => (
                                            <Button
                                                variant="text-primary"
                                                clickMethod={() => handleLockboxSelection(props.row.original)}
                                                text={props.row.original.lockboxCode}
                                                classes="handle-lockbox-selection underlined-links"
                                                dataTestId={props.row.original.lockboxCode}
                                            />
                                        ),
                                        (props: any) => (
                                            <div className='medical-items'>
                                                <BooleanDisplay value={props.row.original.isMedical} />
                                            </div>
                                        ),
                                        (props: any) => (
                                            <div className='rms-items'>
                                                <BooleanDisplay value={props.row.original.isRms} />
                                            </div>
                                        ),
                                        (props: any) => (
                                            <DateDisplay date={props.row.original.from} format="paddedShortDate" gmt />
                                        ),
                                        (props: any) => (
                                            <DateDisplay date={props.row.original.to} format="paddedShortDate" gmt />
                                        )
                                    ]}
                                />
                            </div>
                        </>
                        :
                        <Loader />
                    }
                </div>
            }

        </main>);
}

export default BankerLockboxSummary;
