import { Loader, NotificationBanner } from '@ux/balance-react';
import { Suspense, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { getActiveBanners } from '../API_Integrations/banners';
import { activeBannersState } from '../atom';

function BannerList() {
    const [banners, setBanners] = useRecoilState(activeBannersState);

    useEffect(() => {
        const fetchBanners = async () => {
            return await getActiveBanners();
        };
        
        fetchBanners().then(banners => setBanners(banners));
    }, [setBanners]);

    return (
        <Suspense fallback={<Loader isLoading={true} />}>
            <div className="banner-list">
                {banners.map(banner => (
                    <NotificationBanner
                        key={banner.id}
                        classes="banner-item"
                        ctaHref={banner.linkUrl}
                        ctaLabel={banner.linkTitle}
                        description={banner.content}
                        isDismissible
                        title={banner.title}
                        variant="informative"
                    />
                ))}
            </div>
        </Suspense>
    );
};

export default BannerList;
