import { useEffect } from "react";
import { replaceImages } from "../utils/image-utils";

type AuthenticatedImageProps = {
    imageFilename: string;
    imageUrl: string;
    className?: string;
    alt?: string;
};

function AuthenticatedImage({ imageFilename, imageUrl, className, alt }: AuthenticatedImageProps) {
    useEffect(() => {
        replaceImages();
    }, [imageFilename]);

    const getImageUrl = (): string => {
        return imageUrl;
    }

    return (<img className={className} src={getImageUrl()} alt={alt} crossOrigin="anonymous" />);
}

export default AuthenticatedImage;
