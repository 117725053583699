import requests from "./apiRequest";

export interface Report {
    id: number,
    requestedOn: Date,
    status: string,
    fileType: "Csv" | "Pdf" | "Zip",
    reportType: "BatchDetail" | "BatchSummary" | "LockboxSummary" | "ReportingSummary" | "TransactionDetail" | "ExceptionDailyDetailSummary" | "ExceptionSummary" | "ExceptionBatchSummary" | "ExceptionDetailSummary" | "DailyExceptionSummary",
    fileName?: string,
    reportUrl?: string
}

export const getReports = async (): Promise<Report[]> => {
    let reports = await requests.get("/reports");
    return reports;
}

export const downloadReport = async (reportUrl: string) => {
    window.open(reportUrl, "_blank");
}

export const deleteReport = async (reportId: number) => {
    await requests.delete("/reports/" + reportId);
}
