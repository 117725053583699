/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom-v6";

import { useRecoilValue, useRecoilState } from "recoil";
import { BreadcrumbLoader, batch_ID, batch_summary_reqBody_lockbox, userState, Client_code } from "../atom";


function Breadcrumbs() {
  const [loaded, setLoaded] = useRecoilState(BreadcrumbLoader);
  const userType = useRecoilValue(userState);
  const clientCode = useRecoilValue(Client_code);
  //Batch summary
  const lockboxId = useRecoilValue(batch_summary_reqBody_lockbox);
  //Batch detail
  const batchId = useRecoilValue(batch_ID);

  let navigate = useNavigate();
  let location = useLocation();
  let path = location.pathname;

  useEffect(() => {
  }, [lockboxId]);

  useEffect(() => {

  }, [batchId]);

  useEffect(() => {
  }, [location]);

  const toLockboxSummary = () => {
    if (userType.isBanker) {
      navigate(`/banker-lockbox-summary/${clientCode}`);
    } else {
      navigate("/dashboard-summary");
    }
  }


  const toBatchSummary = () => {
    setLoaded(false);
    if (lockboxId) {
      console.log(loaded);
      setLoaded(true);
      navigate(`/batch-summary/${lockboxId}`);
    } else {
      console.log('empty')
    }
  }

  const toBatchDetail = () => {
    setLoaded(false);
    if (batchId) {
      //console.log(loaded);
      setLoaded(true);
      navigate(`/batch-detail/${batchId}`);
    } else {
      console.log('empty')
    }
  }

  useEffect(() => {
  }, [toBatchSummary]);


  return (
    <nav className="breadcrumbs">
      {
        (path.includes('/batch-summary')) ?
          <span><span className="parent"><button onClick={toLockboxSummary}>Lockbox summary</button><span className="slash">/</span></span><span className="current" >Batch summary</span></span>
          : (path.includes('/batch-detail')) ?
            <span><span className="parent"><button onClick={toLockboxSummary}>Lockbox summary</button><span className="slash">/</span></span><span className="parent" ><button onClick={toBatchSummary}>Batch summary</button><span className="slash">/</span></span><span className="current" >Batch detail</span></span>
            :
            (path.includes('transaction-detail/')) ?
              <span><span className="parent"><button onClick={toLockboxSummary}>Lockbox summary</button><span className="slash">/</span></span><span className="parent" ><button onClick={toBatchSummary}>Batch summary</button><span className="slash">/</span></span><span className="parent" ><button onClick={toBatchDetail}>Batch detail</button><span className="slash">/</span></span><span className="current" >Transaction detail</span></span>
              :
              <></>
      }
    </nav>
  );
}

export default Breadcrumbs;
