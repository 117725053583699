/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Loader, TextInput, Select, RadioGroup, Textarea, ErrorSummary } from '@ux/balance-react';
// @ts-ignore
import { IconEditPencil, IconTrash } from '@ux/balance-icons';
import { useEffect, useState, useRef } from "react";
import Lockbox from '../API_Integrations/Lockbox';
import { getAlerts, getAlert, createAlert, editAlert, deleteAlert } from '../API_Integrations/alerts';
import { ILockbox_options } from '../interfaces/ICorrespondence';
import { ILockbox, ILockbox_req_body } from '../interfaces/iLockBox';
import { IAlerts, IAlertsCreateEdit } from '../interfaces/IAlerts';
import ButtonContainer from '../components/ButtonContainer';
import ClientHeader from '../components/ClientHeader';
import CorpCodes from '../API_Integrations/CorpCodes';
import { ICorpCodes } from '../interfaces/ICorpCodes';
import CustomModal from '../components/CustomModal';
import DateDisplay from '../components/DateDisplay';
import ModalsContainer from '../components/ModalsContainer';
import Table from '../components/Table';
import { useTitle } from '../utils/use-title';
import { useFocusOnElemnt } from '../utils/use-focus-on-element';
interface ErrorMessage { ariaLabel: string, text: string, inputId: string }
interface CorpCode { label: string, value: string, isDisabled?: boolean }

const Alerts = () => {
    useTitle("Alerts");
    enum AlertsState { CurrentAlerts, EditAlerts, AddAlerts };
    const [edit_alert_Id, setEdit_alert_Id] = useState(0);
    const [corpcode, setCorpcode] = useState<{ value: string, options: CorpCode[] }>({ value: 'select corp code', options: [] });
    const [delete_alert_Id, setDelete_alert_Id] = useState(0);
    const [loading, setLoding] = useState(true);
    const [manageState, setManageState] = useState(AlertsState.CurrentAlerts);
    const [alerts_data, setAlerts_data] = useState<IAlerts[]>([]);
    const [alertCreateOrEdit, setAlertCreateOrEdit] = useState<IAlertsCreateEdit>({ id: 0, lockboxCode: '', lockboxName: '', alertType: '', amount: null, accountNumber: '', routingNumber: '', recipients: [], alertFrequency: undefined });
    const [lockbox_options, setLockbox_options] = useState<ILockbox_options[]>([]);
    const [showValidationLockboxID, setShowValidationLockboxID] = useState(false);
    const [showSaveAlertModal, setshowSaveAlertModal] = useState(false);
    const [errorMessagetitle, setErrorMessagetitle] = useState('');
    const [errorMessagetext, setErrorMessagetext] = useState('');
    const [modalIsOpenDelete, setModalIsOpenDelete] = useState(false);
    const [errorMessagesSummary, seterrorMessagesSummary] = useState<ErrorMessage[]>([]);
    const [isvalidAlertType, setIsvalidAlertType] = useState(true);
    const [isvalidAlertFrequency, setIsvalidAlertFrequency] = useState(true);
    const errorSummaryRef = useRef<HTMLElement | null>(null);
    const initialTextRef = useRef<HTMLHeadingElement | null >(null);

    useFocusOnElemnt(initialTextRef);

    const lockbox_list = [
        {
            isDisabled: true,
            label: 'Select',
            value: ''
        }
    ];
    const alertOptions = [
        {
            isDisabled: true,
            label: 'Select one',
            value: ''
        },
        {
            label: 'Correspondence processed',
            value: 'CorrespondenceProcessed'
        },
        {
            label: 'Dollar amount limit',
            value: 'DollarAmountLimit'
        },
        {
            label: 'Dollar amount limit for a remitter account or routing number',
            value: 'DollarAmountLimitForRemitterAccount'
        },
        {
            label: 'Processing complete',
            value: 'ProcessingCompleted'
        },
        {
            label: 'Specific remitter account number or routing number',
            value: 'RemitterAccount'
        }
    ];

    const getLockboxOptions = (options: ILockbox[]) => {
        let LockboxOptions = options.map(lockbox => ({ label: lockbox.code, value: lockbox.code }));
        if (LockboxOptions.length === 1) {
            setLockbox_options([...LockboxOptions]);
            setAlertCreateOrEdit({ ...alertCreateOrEdit, lockboxCode: LockboxOptions[0].value });
        } else {
            setLockbox_options([...lockbox_list, ...LockboxOptions]);
        }
        setLoding(false);
        return LockboxOptions;
    };

    const fetchAlertData = async () => {
        let result = await CorpCodes.list();
        if (result && result.length > 1) {
            let CorpCodeOptions = result.map((el: ICorpCodes) => ({
                label: el.code,
                value: el.code
            }));
            setCorpcode({ value: 'select corp code', options: [...[{ label: 'Select', value: 'select corp code', isDisabled: true, }], ...CorpCodeOptions] });
        }
        return await getAlerts();
     
    }
    const fetchSingleAlert = async (ID: number) => {
        return await getAlert(ID);
    }
    const getLockboxData = async (ReqBody: ILockbox_req_body) => {
        let result = await Lockbox.listClientLockbox(ReqBody)
        if (result !== undefined) {
            return result;
        } else {
            console.log('item not found')
        }
    }

    useEffect(() => {
        setManageState(AlertsState.CurrentAlerts);
        const fetchData = async () => {
            const res = await fetchAlertData();
            setAlerts_data(res);
            const result = await getLockboxData({
                lockboxNumber: '',
                lockboxName: ''
            });
            getLockboxOptions(result);
            setLoding(false);
        }
        fetchData();
    }, []);

    useEffect(() => {
    }, [alertCreateOrEdit]);

    const columnData = [
        { headerId: 'lockboxCode', headerLabel: 'Lockbox' },
        { headerId: 'amount', headerLabel: 'Alert description', rowCellTableRowComponentsArrayIndex: 1, type: 'component' },
        { headerId: 'modifiedOn', headerLabel: 'Last updated', rowCellTableRowComponentsArrayIndex: 3, type: 'component' },
        { headerId: 'emailReceipients', headerLabel: 'Email Receipients', rowCellTableRowComponentsArrayIndex: 2, type: 'component' },
        { headerId: 'action', headerLabel: 'Edit or delete', rowCellTableRowComponentsArrayIndex: 0, type: 'component', disableSorting: true }
    ];
    const handleOnChangeSelectLockboxId = (event: Event) => { setAlertCreateOrEdit({ ...alertCreateOrEdit, lockboxCode: (event.target as HTMLInputElement).value }) }
    const HandleOnChangeSelectCorpCode = (event: Event) => {
        const corpCode = (event.target as HTMLInputElement).value;
        setCorpcode({ ...corpcode, value: corpCode });
    }
    const handleOnChangeSelectAlertType = (event: Event) => {
        const newAlertType = (event.target as HTMLInputElement).value;
        setAlertCreateOrEdit((prevAlertCreateOrEdit) => {
            const updatedAlert = {...prevAlertCreateOrEdit, alertType: newAlertType};
            if (newAlertType === 'CorrespondenceProcessed' || newAlertType === 'ProcessingCompleted') {
                updatedAlert.amount = null;
                updatedAlert.accountNumber = '';
                updatedAlert.routingNumber= '' ;
            }
            if (newAlertType === 'RemitterAccount') {
                updatedAlert.amount = null;
            }
            if(newAlertType === 'DollarAmountLimit') {
                updatedAlert.accountNumber = '';
                updatedAlert.routingNumber= '' ;
            }
            return updatedAlert;
        })
    }

    const handleOnChangeAmount = (event: any) => {
        const inputAmount: string = event.target.value;
        const numberAmount: number | null = inputAmount === '' ? null : Number(inputAmount);
        setAlertCreateOrEdit({ ...alertCreateOrEdit, amount: numberAmount });
    };

    const handleOnChangeRemitterAccountNumber = (event: any) => {
        setAlertCreateOrEdit({ ...alertCreateOrEdit, accountNumber: event.target.value });
    };

    const handleOnChangeRoutingNumber = (event: any) => {
        setAlertCreateOrEdit({ ...alertCreateOrEdit, routingNumber: event.target.value });
    };

    const handleOnChangeEmailAddressess = (event: any) => {
        const inputRecipients: string = event.target.value;
        const arrayRecipients: string[] = inputRecipients.split(';').map((email) => email.trim());
        setAlertCreateOrEdit({ ...alertCreateOrEdit, recipients: arrayRecipients });
    };

    const handleOnChangeAlertFrequency = (frequencyVal: "Daily" | "Occurrence") => {
        setAlertCreateOrEdit({ ...alertCreateOrEdit, alertFrequency: frequencyVal });
    }


    const HandleEmptyAlert = () => {
        setAlertCreateOrEdit({ id: 0, lockboxCode: '', lockboxName: '', alertType: '', amount: null, accountNumber: '', routingNumber: '', recipients: [], alertFrequency: undefined });
        setErrorMessagetitle('');
        setErrorMessagetext('');
    }

    const HandleGoBack = () => {
        setManageState(AlertsState.CurrentAlerts);
        setAlertCreateOrEdit({ id: 0, lockboxCode: '', lockboxName: '', alertType: '', amount: null, accountNumber: '', routingNumber: '', recipients: [], alertFrequency: undefined });
        setLoding(true);
        fetchAlertData().then(res => {
            setAlerts_data(res);
            setLoding(false);
        });
    }

    const HandleCancelAlerter = () => {
        HandleEmptyAlert();
        setManageState(AlertsState.CurrentAlerts);
    }

    const validateForm = () => {
        const errors: ErrorMessage[] = [];

        if (alertCreateOrEdit.alertType === "") {
            errors.push({ ariaLabel: "Alert type is required", text: "Choose alert type", inputId: 'select-alert-type' });
            setIsvalidAlertType(false);
        } else {
            setIsvalidAlertType(true);
        }

        if (alertCreateOrEdit.alertFrequency === undefined) {
            errors.push({ ariaLabel: "Alert frequency is required", text: "Choose alert frequency", inputId: 'alertFrequencySelection' });
            setIsvalidAlertFrequency(false);
        } else {
            setIsvalidAlertFrequency(true);
        }

        seterrorMessagesSummary(errors);
        return errors;
    }

    const handleEmptyErrorMessages = () => {
        seterrorMessagesSummary([]);
        setIsvalidAlertType(true);
        setIsvalidAlertFrequency(true);
    };

    const HandleCreateNewUser = async () => {
        if(lockbox_options.length === 1) {
            setAlertCreateOrEdit({ id: 0, lockboxCode: lockbox_options[0].value, lockboxName: '', alertType: '', amount: null, accountNumber: '', routingNumber: '', recipients: [], alertFrequency: undefined });
            setErrorMessagetitle('');
            setErrorMessagetext('');
        } else {
            HandleEmptyAlert();
        }
        setManageState(AlertsState.AddAlerts);
    }

    const HandleSaveCreateAlert = () => {
        setLoding(true);
        delete alertCreateOrEdit.id;
        delete alertCreateOrEdit.lockboxName;
        let lockbox_selected = alertCreateOrEdit.lockboxCode;
        if (lockbox_selected !== '') {
            let catchError = validateForm();
            if (catchError.length === 0) {
                createAlert(alertCreateOrEdit).then(() => {
                    setErrorMessagetitle('Alert created');
                    setErrorMessagetext('Your new alert has been created. You can edit or delete it in Current alerts.');
                    setshowSaveAlertModal(true);
                    HandleGoBack();
                    setLoding(false);
                }).catch((error) => {
                    const errorMessages = [];
                    for (const key in error.errors) {
                        if (error.errors.hasOwnProperty(key)) {
                            errorMessages.push(error.errors[key][0]);
                        }
                    }
                    const ErrorMessagesTexts = errorMessages.join('');
                    setErrorMessagetitle(error.title);
                    setErrorMessagetext(ErrorMessagesTexts);
                    setshowSaveAlertModal(true);
                    setLoding(false);
                })
            } else {
                setLoding(false);
            }
        } else {
            setShowValidationLockboxID(true);
            setLoding(false);
        }

    }

    const HandleEditUser = (ID: number) => {
        setLoding(true);
        setManageState(AlertsState.EditAlerts);
        setEdit_alert_Id(ID);
        HandleEmptyAlert();
        handleEmptyErrorMessages();
        fetchSingleAlert(ID).then((result) => {
            setAlertCreateOrEdit(result);
            setLoding(false);
        })
    }

    const HandleSaveEditAlert = () => {
        setLoding(true);
        delete alertCreateOrEdit.id;
        delete alertCreateOrEdit.lockboxName;
        let lockbox_selected = alertCreateOrEdit.lockboxCode;
        if (lockbox_selected !== '') {
            let catchError = validateForm();
            if (catchError.length === 0) {
                editAlert(edit_alert_Id, alertCreateOrEdit).then(() => {
                    setErrorMessagetitle('Alert updated');
                    setErrorMessagetext('Your alert has been updated. You can edit or delete it in Current alerts.');
                    setshowSaveAlertModal(true);
                    HandleGoBack();
                }).catch((error) => {
                    const errorMessages = [];
                    for (const key in error.errors) {
                        if (error.errors.hasOwnProperty(key)) {
                            errorMessages.push(error.errors[key][0]);
                        }
                    }
                    const ErrorMessagesTexts = errorMessages.join('');
                    setErrorMessagetitle(error.title);
                    setErrorMessagetext(ErrorMessagesTexts);
                    setshowSaveAlertModal(true);
                    setLoding(false);
                })
            } else {
                setLoding(false);
            }
        } else {
            setShowValidationLockboxID(true);
            setLoding(false);
        }

    }

    const HandleDeleteUser = (ID: number) => {
        setDelete_alert_Id(ID);
        setModalIsOpenDelete(true);
    }

    const handleConfirmDelete = async () => {
        setModalIsOpenDelete(false);
        setLoding(true);
        await deleteAlert(delete_alert_Id).then(() => {
            fetchAlertData().then(res => {
                setAlerts_data(res);
                setLoding(false);
            });
        });
    }

    return (
        <main>
            <ClientHeader showClientName={true} showBreadcrumbs={false} />
            <h4 ref={initialTextRef} tabIndex={-1} className='heading-title heading-focus-text'>Alerts<span className='pipe'>|</span>{manageState === AlertsState.CurrentAlerts ? 'Current email alerts' : manageState === AlertsState.EditAlerts ? 'Edit email alerts' : 'Create new email alert'}</h4>
            <CustomModal okType ModalText='Please select a Lockbox ID to start your search.' ModalTitle='Required information missing' ModalIsOpen={showValidationLockboxID} CloseModal={() => { setShowValidationLockboxID(false) }} />
            <div className={manageState === AlertsState.CurrentAlerts ? 'mange-buttons-container' : 'mange-buttons-container-actions'}>
                {(manageState === AlertsState.CurrentAlerts) ?
                    <Button
                        classes=""
                        clickMethod={() => { HandleCreateNewUser() }}
                        text="Create new alerts"
                        variant="secondary"
                        size="medium"
                    />
                    : (manageState === AlertsState.EditAlerts || manageState === AlertsState.AddAlerts) ?
                        <Button
                            classes="go-back"
                            clickMethod={() => { HandleGoBack() }}
                            text="Go back to current alerts"
                            variant="text-primary"
                            icon='IconChevronLeft'
                            iconPosition='left'
                        />
                        :
                        <></>
                }
            </div>
            {manageState === AlertsState.CurrentAlerts ?
                <div className='loader-section'>
                    <Loader isLoading={loading} />
                    {alerts_data && alerts_data.length > 0 ?
                        <div className='current-alerts-container'>
                            <ModalsContainer alerts>
                            <CustomModal okRefreshType Refresh={() => { setshowSaveAlertModal(false); setErrorMessagetitle(''); setErrorMessagetext(''); }} ModalText={errorMessagetext} ModalTitle={errorMessagetitle} ModalIsOpen={showSaveAlertModal} CloseModal={() => {
                            setshowSaveAlertModal(false); setErrorMessagetitle('');
                            setErrorMessagetext('');
                        }} />
                                <CustomModal yesOrNoType ModalIsOpen={modalIsOpenDelete} CloseModal={() => { setModalIsOpenDelete(false) }} noText='No, I do not want to delete it' yesText='Yes, I want to delete it' yesClickMethod={handleConfirmDelete} ModalText='Are you sure you want to delete this alert? The information will no longer be available.' ModalTitle='Deleting alert' />
                            </ModalsContainer>
                            <Table
                                caption="Users"
                                columnData={columnData}
                                data={alerts_data}
                                isLined
                                isSortable
                                tableRowComponents={[
                                    (props: any) => (
                                        <div>
                                            {props.row.original.status === "Deleted" ?
                                                <></>
                                                :
                                                <div className='report-actions'>
                                                    <span className='action-icon'><IconEditPencil style={{ color: '#007C8C' }} width="20" height="20" /></span>
                                                    <Button
                                                        clickMethod={() => { HandleEditUser(props.row.original.id) }}
                                                        text="Edit"
                                                        variant="text-primary"
                                                    /><span className='action-separator'> | </span>
                                                    <span className='action-icon'><IconTrash style={{ color: '#007C8C' }} width="18" height="18" /></span>
                                                    <Button
                                                        clickMethod={() => { HandleDeleteUser(props.row.original.id) }}
                                                        text="Delete"
                                                        variant="text-primary"
                                                    />
                                                </div>
                                            }
                                        </div>
                                    ),
                                    (props: any) => (
                                        <div>
                                            {props.row.original.alertType === 'CorrespondenceProcessed' ?
                                            <span className='action-icon'>Correspondence processed</span>
                                            :props.row.original.alertType === 'ProcessingCompleted' ?
                                            <span className='action-icon'>Processing complete</span>
                                            :props.row.original.alertType === 'RemitterAccount' ?
                                            <span className='action-icon'>Specific remitter account number or routing number</span>
                                            :
                                            <span className='action-icon'>Dollar amount limit is {props.row.original.amountFormatted}</span>
                                            }
                                        </div>
                                    ),
                                    (props: any) => (
                                        <div>
                                            {props.row.original.recipients.length === 0 ?
                                                <span></span>
                                                :
                                                <div>
                                                    {props.row.original.recipients.map((recipient: string, index: number) => (
                                                        <span key={index}>
                                                            {recipient}
                                                            {index !== props.row.original.recipients.length - 1 ? ';' : null}
                                                            <br />
                                                            {' '}
                                                        </span>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    ),
                                    (props: any) => (
                                        <DateDisplay date={props.row.original.modifiedOn} format="paddedShortDateTime" gmt />
                                    )
                                ]}
                            />
                        </div>
                        :
                        <p className='zero-state'>You don’t have any alerts yet.</p>
                    }
                </div>
                :
                <div className='loader-section'>
                    <Loader isLoading={loading} />
                    <ModalsContainer noGap>
                        <CustomModal okRefreshType Refresh={() => { setshowSaveAlertModal(false); setErrorMessagetitle(''); setErrorMessagetext(''); }} ModalText={errorMessagetext} ModalTitle={errorMessagetitle} ModalIsOpen={showSaveAlertModal} CloseModal={() => {
                            setshowSaveAlertModal(false); setErrorMessagetitle('');
                            setErrorMessagetext('');
                        }} />
                    </ModalsContainer>
                    {errorMessagesSummary.length !== 0 &&
                        <ErrorSummary
                            errors={errorMessagesSummary}
                            ref={errorSummaryRef}
                        />
                    }
                    <div className='create-edit-fields'>
                        {corpcode.options && corpcode.options.length < 1 ?
                            <Select
                                handleOnChange={handleOnChangeSelectLockboxId}
                                value={alertCreateOrEdit.lockboxCode}
                                inputId='select-lockbox-id'
                                label='Select your Lockbox ID (required)'
                                name='search-by-lockbox-id'
                                options={lockbox_options}
                                size='small'
                                isRequired
                                classes='width-554 bottom-gap-24'
                            />
                            :
                            <div className="download-dropdown-container">
                                <div>
                                    <Select
                                        handleOnChange={handleOnChangeSelectLockboxId}
                                        value={alertCreateOrEdit.lockboxCode}
                                        inputId='select-lockbox-id'
                                        label='Select your Lockbox ID (required)'
                                        name='search-by-lockbox-id'
                                        options={lockbox_options}
                                        size='small'
                                        isRequired
                                        classes='width-322 bottom-gap-24'
                                    />
                                </div>
                                <div>
                                    <Select
                                        handleOnChange={HandleOnChangeSelectCorpCode}
                                        value={corpcode.value}
                                        inputId='select-lockbox-id'
                                        label='Corp Code'
                                        name='search-by-lockbox-id'
                                        options={corpcode.options}
                                        size='small'
                                        isRequired
                                        classes='width-206 bottom-gap-24  left-24-gap-filters'
                                    />
                                </div>
                            </div>
                        }
                        <Select
                            handleOnChange={handleOnChangeSelectLockboxId}
                            value={alertCreateOrEdit.lockboxCode}
                            inputId='select-lockbox-id'
                            label='Select your Lockbox ID (required)'
                            name='search-by-lockbox-id'
                            options={lockbox_options}
                            size='small'
                            isRequired
                            classes='width-554 bottom-gap-24'
                        />
                        <Select
                            handleOnChange={handleOnChangeSelectAlertType}
                            inputId='select-alert-type'
                            label='Alert type'
                            name='select-alert-type'
                            options={alertOptions}
                            size='small'
                            classes='width-554 bottom-gap-24'
                            value={alertCreateOrEdit.alertType}
                            errorMessages={["Select alert type."]}
                            isValid={isvalidAlertType}
                        />
                        {(alertCreateOrEdit.alertType === 'DollarAmountLimitForRemitterAccount' || alertCreateOrEdit.alertType === 'DollarAmountLimit' || alertCreateOrEdit.alertType === '') ?
                            <TextInput
                                label="Amount"
                                initialValue={alertCreateOrEdit.amount === null ? '' : alertCreateOrEdit.amount?.toString()}
                                size='small'
                                handleOnChange={handleOnChangeAmount}
                                type="text"
                                classes='width-554 bottom-gap-24'
                            />
                            :
                            <></>}
                        {(alertCreateOrEdit.alertType === 'RemitterAccount' || alertCreateOrEdit.alertType === 'DollarAmountLimitForRemitterAccount' || alertCreateOrEdit.alertType === '') ?
                            <div>
                                <TextInput
                                    label="Remitter account number"
                                    initialValue={alertCreateOrEdit.accountNumber}
                                    size='small'
                                    handleOnChange={handleOnChangeRemitterAccountNumber}
                                    type="text"
                                    classes='width-554 bottom-gap-24'
                                />

                                <TextInput
                                    label="Routing number"
                                    initialValue={alertCreateOrEdit.routingNumber}
                                    size='small'
                                    handleOnChange={handleOnChangeRoutingNumber}
                                    type="text"
                                    classes='width-554 bottom-gap-24'
                                />
                            </div>
                            :
                            <></>}
                        <Textarea
                            label="Email recipients"
                            labelPopoverContent="To add multiple email recipients, separate each email address with a semicolon(;)."
                            labelPopoverIcon="IconHelpCircle"
                            value={alertCreateOrEdit.recipients.join(';')}
                            size='small'
                            handleOnChange={handleOnChangeEmailAddressess}
                            type="text"
                            classes='width-554'
                        />
                        <div className='card-divider width-card-divider'></div>
                        <RadioGroup
                            groupFormControlId="alertFrequencySelection"
                            legendLabel="Schedule alerts"
                            name="radio-group-name"
                            size='small'
                            radioItems={[
                                {
                                    handleOnChange: () => handleOnChangeAlertFrequency("Daily"),
                                    inputId: 'radio-daily-alerts',
                                    isChecked: alertCreateOrEdit.alertFrequency === "Daily",
                                    label: 'Receive daily emails',
                                    value: "Daily",
                                    classes: 'bottom-gap-10',
                                },
                                {
                                    handleOnChange: () => handleOnChangeAlertFrequency("Occurrence"),
                                    inputId: 'radio-event-alert',
                                    isChecked: alertCreateOrEdit.alertFrequency === "Occurrence",
                                    label: 'Receive email each time the event occurs',
                                    value: "Occurenence"
                                }
                            ]}
                            errorMessages={["Select alert frequency."]}
                            isValid={isvalidAlertFrequency}
                        />
                    </div>
                    <div className='card-divider width-card-divider'></div>
                    <div className='bottom-gap-52'>
                        <ButtonContainer noTopGap>
                            {manageState === AlertsState.AddAlerts ?
                                <Button
                                    clickMethod={() => { HandleSaveCreateAlert() }}
                                    size="medium"
                                    text="Save"
                                />
                                : manageState === AlertsState.EditAlerts ?
                                    <Button
                                        clickMethod={() => { HandleSaveEditAlert() }}
                                        size="medium"
                                        text="Save"
                                    />
                                    :
                                    <></>
                            }
                            <Button
                                clickMethod={() => { HandleCancelAlerter() }}
                                size="medium"
                                text="Cancel"
                                variant="secondary"
                                classes="left-24-gap-filters"
                            />
                        </ButtonContainer>
                    </div>
                </div>

            }

        </main>
    );
};

export default Alerts;
