import { DropdownMenu, DropdownMenuContent, DropdownTrigger, MenuButton, MenuList } from '@ux/balance-react';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { deleteClientEmulation } from "../API_Integrations/ClientLockboxSearch";
import CurrentUser from "../API_Integrations/CurrentUser";
import { oktaAuth } from "../App";
import { ClientSelected, Client_code, DynamicPageName, NavToggle, ShowCientModal, userState, ExceptionSubscribe, ExceptionSubscribeClient, Exception_Banker_CurrentTab, Exception_Client_CurrentTab, Client__Selected_Code, exception_setup_unsaved_changes, exception_setup_lockboxId } from "../atom";
import CustomModal from './CustomModal';
import { useIdleTimer } from 'react-idle-timer';

function LockboxDropdownMenu() {
    const TIMEOUT_MILLISECONDS = 1_000 * 60 * 10;

    const [clientSelected, setClientSelected] = useRecoilState(ClientSelected);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useRecoilState(userState);
    const [clientCode, setClientCode] = useRecoilState(Client_code);
    const client_selected_code = useRecoilValue(Client__Selected_Code);
    const setModalOpen = useSetRecoilState(ShowCientModal);
    const setDynamicPageName = useSetRecoilState(DynamicPageName);
    const setToggle = useSetRecoilState(NavToggle);
    const setBankerExceptionCurrentTab = useSetRecoilState(Exception_Banker_CurrentTab);
    const setClientExceptionCurrentTab = useSetRecoilState(Exception_Client_CurrentTab);
    const subscribeException = useRecoilValue(ExceptionSubscribe);
    const subscribeExceptionClient = useRecoilValue(ExceptionSubscribeClient);
    const [unsavedChangesExceptionSetup, setUnsavedChangesExceptionSetup] = useRecoilState(exception_setup_unsaved_changes);
    const setSelectedLockboxIdExeceptionSetup = useSetRecoilState(exception_setup_lockboxId);
    const [modalUnsavedExceptionSetup, setModalUnsavedExceptionSetup] = useState(false);
    const [textUnsavedExceptionSetup, setTextUnsavedExceptionSetup] = useState({text:'', title:''});
    const navigate = useNavigate();
    let location = useLocation();
    let pathUrl = location.pathname;

    useIdleTimer({
        onIdle: () => signOut(),
        timeout: TIMEOUT_MILLISECONDS
    })

    useEffect(() => {
        setIsLoading(true);

        getCurrentUser()
            .then(user => setUser(user))
            .finally(() => setIsLoading(false));
    }, [setUser])

    const getCurrentUser = async () => {
        return await CurrentUser();
    }

    const handleClientPageSelection = (path: string, name: string) => {
        if (user.isBanker) {
            if (!clientSelected) {
                setDynamicPageName(name);
                setModalOpen(true);
            } else {
                if (path === "/search-and-reports") {
                    setToggle(1);
                }
                navigate(path);
            }
        } else {
            if (path === "/search-and-reports") {
                setToggle(1);
            }
            navigate(path);
        }
    }

    const handleExceptionPageSelection =() => {
        if (user.isBanker) {
            if (!clientSelected) {
                setDynamicPageName("Exception management");
                setModalOpen(true);
            } else {
                if(client_selected_code) {
                    navigate(`/banker-lockbox-summary/${client_selected_code}`);
                } else {
                    navigate(`/banker-lockbox-summary/${clientCode}`);
                }
                setBankerExceptionCurrentTab('EXCEPTIONMANAGEMENT');
            }
        } else {
                navigate('/dashboard-summary');
                setClientExceptionCurrentTab('EXCEPTIONMANAGEMENT');
            }
        
    }

    const exitClicked = () => {
        if (unsavedChangesExceptionSetup.custom_dropdown || unsavedChangesExceptionSetup.corp_code) {
            if (pathUrl.includes('/exception-setup')) {
                setTextUnsavedExceptionSetup({ text: 'Are you sure you want to leave this page? Changes you made will not be saved.', title: 'Unsaved changes' });
                setModalUnsavedExceptionSetup(true);
            } else {
                setTextUnsavedExceptionSetup({ text: 'Are you sure you want to log out? Exception setup changes you made will not be saved.', title: 'Exception setup changes not saved' });
                setModalUnsavedExceptionSetup(true);
            }
        } else {
            signOut();
        }
    }

    const signOut = () => {
        oktaAuth.tokenManager.clear();
        localStorage.clear();
        window.opener = null;
        window.open('', '_self');
        window.close();
;    }

    if (isLoading) {
        return null;
    }

    return (
    <>
        <DropdownMenu
            classes={null}
            dataTestId={null}
            id={null}
        >
            <DropdownTrigger
                ariaLabel="Greetings to user"
                id="lockbox-dropdown-menu-trigger"
                dataTestId="lockbox-dropdown-menu-trigger"
                text={`Hello, ${user?.firstName}`}
            />
            <DropdownMenuContent>
                <MenuList>
                    {
                        user.isBanker ?
                            <MenuButton
                                text="LockboxIQ client search"
                                icon="IconHome"
                                clickMethod={() => {
                                    deleteClientEmulation();
                                    setClientCode('');
                                    setSelectedLockboxIdExeceptionSetup('');
                                    setUnsavedChangesExceptionSetup({custom_dropdown: false, corp_code: false});
                                    sessionStorage.removeItem('CustomTableItems');
                                    sessionStorage.removeItem('CorpCodeTableItems');
                                    navigate("/banker-lockbox-search");
                                }}
                                dataTestId="LockboxIQ-summary-banker"
                            />
                            :
                            <MenuButton
                                text="LockboxIQ summary"
                                icon="IconHome"
                                clickMethod={() => navigate("/dashboard-summary")}
                                dataTestId="LockboxIQ-summary-client"
                            />
                    }
                    {
                        ((user.isBanker && subscribeException.type === 'Retail' && subscribeException.subscribed) || ((!user.isBanker && subscribeExceptionClient.type === 'Retail' && subscribeExceptionClient.subscribed) && (user.isAdmin || user.canManageExceptions))) ?
                            <MenuButton
                                text="Exception management"
                                clickMethod={() => handleExceptionPageSelection()}
                                dataTestId="manage-exception"
                            />
                            :
                            <></>
                            
                    }
                    {
                        user.isBanker ?
                            <MenuButton
                                text="Exception setup"
                                clickMethod={() => handleClientPageSelection("/exception-setup", "Exception setup")}
                                dataTestId="exception-setup"
                            />
                            :
                            <></>
                    }
                    
                    {
                        user.isBanker ?
                            <MenuButton
                                text="Client maintenance"
                                clickMethod={() => {
                                    deleteClientEmulation();
                                    setClientSelected(false);
                                    setClientCode('');
                                    setSelectedLockboxIdExeceptionSetup('');
                                    setUnsavedChangesExceptionSetup({custom_dropdown: false, corp_code: false});
                                    sessionStorage.removeItem('CustomTableItems');
                                    sessionStorage.removeItem('CorpCodeTableItems');
                                    navigate("/client-maintenance")
                                }}
                                dataTestId="client-maintenance"
                            />
                            :
                            <></>
                    }
                    <MenuButton
                        text="User management"
                        clickMethod={() => handleClientPageSelection("/users", "User management")}
                        dataTestId="manage-users"
                    />
                    <MenuButton
                        text="Remitters"
                        clickMethod={() => handleClientPageSelection("/remitters", "Remitters")}
                        dataTestId="remitters"
                    />
                    <MenuButton
                        text="Correspondence"
                        clickMethod={() => handleClientPageSelection("/correspondence", "Correspondence")}
                        dataTestId="correspondence"
                    />
                    <MenuButton
                        text="Alerts"
                        clickMethod={() => handleClientPageSelection("/alerts", "Alerts")}
                        dataTestId="alerts"
                    />
                    <MenuButton
                        text="Banner management"
                        clickMethod={() => navigate(user.isBanker ? "/banners" : "/client-banners")}
                        dataTestId="manage-banners"
                    />
                    <MenuButton
                        text="Search and reports"
                        clickMethod={() => handleClientPageSelection("/search-and-reports", "Search and reports")}
                        dataTestId="search-and-reports"
                    />
                    <hr />
                    <MenuButton
                        text="Help and support"
                        clickMethod={() => navigate("/help-and-support")}
                        dataTestId="help"
                    />
                    <MenuButton
                        text="Exit"
                        clickMethod={exitClicked}
                        dataTestId="exit"
                    />
                </MenuList>
            </DropdownMenuContent>
        </DropdownMenu>
        <CustomModal
            yesOrNoType
            ModalIsOpen={modalUnsavedExceptionSetup}
            CloseModal={() => { setModalUnsavedExceptionSetup(false) }}
            noText='Cancel'
            yesText='Yes, log out'
            yesClickMethod={signOut}
            ModalText={textUnsavedExceptionSetup.text}
            ModalTitle={textUnsavedExceptionSetup.title} />
    </>
    )
}

export default LockboxDropdownMenu
