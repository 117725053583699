/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, forwardRef, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import UTIF from 'utif';
import IconEnlarge from '../../src/assets/images/IconEnlarge.png';
import { ExceptionLastFocusedImageBtn } from '../atom';

interface ImageViewerProps {
    id: string
    imgSource: string
    onClickEnlarge: () => void
    children?: ReactNode
    altImageText: string
    exceptionTypeChildren?: ReactNode
    iconButtonRef: React.RefObject<HTMLButtonElement>
    imageButtonRef: React.RefObject<HTMLButtonElement>
}

const ExceptionImageViewer = forwardRef<HTMLButtonElement | null, ImageViewerProps>(
    ({ imgSource, id, onClickEnlarge, iconButtonRef, imageButtonRef, children, altImageText, exceptionTypeChildren }, ref) => {
    const [imgDimension, setImgDimension] = useState<{width: number, height: number}>({width: 0, height: 0});
    const setExceptionLastFocusedBtn = useSetRecoilState(ExceptionLastFocusedImageBtn);

    useEffect(() => {
        UTIF.replaceIMG();
    }, [imgSource, onClickEnlarge, children]);

    const handleClick = (buttonType: 'icon' | 'image') => {
        onClickEnlarge();
        if(id === 'exceptionImg') {
            setExceptionLastFocusedBtn(prev => ({...prev, [id]: buttonType}));
        }
        UTIF.replaceIMG();
    }

    const handleImgLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const { naturalHeight } = event.currentTarget as HTMLImageElement;
        const { naturalWidth } = event.currentTarget as HTMLImageElement;
        setImgDimension({width: naturalWidth, height: naturalHeight});
    }
    
    const showTop25 = imgDimension.height >  imgDimension.width;
    const showTopStyles: React.CSSProperties ={
        clipPath: showTop25 ? 'inset(0 0 75% 0)' : 'none',
    }

        return (
            <div className='image-container exception-image-container'>
                <div className='image-and-download-container'>
                <div className="exception-image-enlarge-button">
                    <span className='enlarge-text' aria-hidden="true" id='enlarge-instructions'>Click image to enlarge</span><button onClick={() => {handleClick('icon')}} tabIndex={0} autoFocus ref={iconButtonRef}><span className='sr-only'>enlarge image</span><img alt="" className='image-enlarge-icon' src={IconEnlarge} /></button>
                </div>
                <div className="exception-download-image bottom-gap-22">
                    {exceptionTypeChildren}
                </div>
                </div>
                <div className="image">
                    <button aria-describedby='enlarge-instructions' onClick={() => { handleClick('image')}} tabIndex={0} autoFocus ref={imageButtonRef}>
                        <img
                            alt={altImageText || 'check/document image'}
                            className="small-image"
                            src={`${imgSource}`}
                            style={showTopStyles}
                            onLoad={handleImgLoad}
                            crossOrigin="anonymous"
                        />
                    </button>
                </div>
                <div className="image-pagination">
                    {children}
                </div>
            </div>
        )
    

});

export default ExceptionImageViewer;
