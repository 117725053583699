import requests from "./apiRequest";
import { ILockboxCorrespondenceReport, ILockboxCorrespondence_req_body } from "../interfaces/ICorrespondence";

const Correspndencedata = {
    details: async (ID: number) => {
        try {
            let result = await requests.get(`/correspondence/${ID}`);
            return result;
        } catch (error) {
        console.log('error from transactions api integration: ', error);
        }
    },
    lockboxes: async (lockboxCode: string, body: ILockboxCorrespondence_req_body) => {
        try {
            let result = await requests.getParams(`/lockboxes/${lockboxCode}/correspondence`, body);
            return result;
        } catch (error) {
        console.log('error from transactions api integration: ', error);
        }
    },
    generateReport: async (lockboxCode: string, body: ILockboxCorrespondenceReport) => {
        let result = await requests.post(`/lockboxes/${lockboxCode}/correspondence/report`, body);
        return result;
    } 
}
export default Correspndencedata;
