import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import "react-datepicker/dist/react-datepicker.css";
import { Outlet, useNavigate } from "react-router-dom-v6";
import getConfiguration from './config';
import './styles/local-app-styles.scss'; // Project styles after Balance CSS.

const configuration = getConfiguration();
export const oktaAuth = new OktaAuth(configuration!.oidc);

// @ts-ignore
window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {
    const navigate = useNavigate();
    const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    }

    return (
        <>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <Outlet />
            </Security>
        </>
    );
}

export default App;
