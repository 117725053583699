export interface ICustomFields {
  key?: string
  keyType?: string
  value?: string
}
export interface IDetailData {
  itemSequenceNumber: number
  fields: ICustomFields[]
}
export type ILockboxesTransactionSearch = IResNoAdditionalData | IResWithAdditionalData;

  export interface IResNoAdditionalData {
    id: number
    transactionId: number
    batchId: number
    itemType: string
    itemSequenceNumber: number
    batchItemSequenceNumber: number
    processingDate: string
    processingDateFormatted: string
    filename?: string
    amount: number
    amountFormatted: string
    routingNumber: string
    accountNumber: string
    serialNumber: string
    remitterId: number
    remitterName: string
    paymentMethod: string
  }

  export interface IResWithAdditionalData {
    id: number
    transactionId: number
    batchId: number
    itemType: string
    itemSequenceNumber: number
    batchItemSequenceNumber: number
    processingDate: string
    processingDateFormatted: string
    filename?: string
    amount: number
    amountFormatted?: string
    routingNumber?: string
    accountNumber?: string
    serialNumber?: string
    remitterId?: number
    remitterName?: string
    paymentMethod?: string
    batchNumber?: string
    detailData?: IDetailData[]
  }
  export interface ILockboxesTransactionSearch_req_body {
    startDate?: string 
    endDate?: string
    filters?: string
    detailFilters?: string
    corpCode?: string
    sorts?: string
}

  export interface ITransactionDetailOptions {
    label: string,
    value: string,
    id: number
    isDeleted: boolean
  }
  export interface IColumnOptions {
    component?: string
    headerId?: string
    headerLabel?: string
    type?: string
    value: string
    label: string
    id: number
    isIncluded?: boolean
    isDisabled?: boolean
  }
  export interface IFilterQuery {
    [key: string]: string;
  }
  export interface IAdditionalTransactionOptions  {
    label: string
    value: string
    id: number
    isDeleted: boolean
}
export interface IDetailKeyRes  {
  name: string
  keyType: string
  validName?: string
}
export const InitialTransactionsEnum =  [
        
  {
      label: 'Amount',
      value: 'amount',
      id: 1,
      isDeleted: false,
      type: 'Currency',
      isInitial: true
  },
  {
      label: 'Serial number',
      value: 'serialNumber',
      id: 2,
      isDeleted: false,
      type: 'Text',
      isInitial: true
  },
  {
      label: 'Routing number',
      value: 'routingNumber',
      id: 3,
      isDeleted: false,
      type: 'Text',
      isInitial: true
  },
  {
      label: 'Account number',
      value: 'accountNumber',
      id: 4,
      isDeleted: false,
      type: 'Text',
      isInitial: true
  },
];

export const InitialColumnsEnum = [
  { headerId: 'amountFormatted', headerLabel: 'Amount', value: 'amount', label: 'Amount', id: 3, isIncluded: true, isDisabled: false, type: 'Currency' },
  { headerId: 'routingNumber', headerLabel: 'R/T', value: 'routingNumber', label: 'R/T', id: 5, isIncluded: true, isDisabled: false, type: 'Text' },
  { headerId: 'processingDateFormatted', headerLabel: 'Date', value: 'date', label: 'Date', id: 8, isIncluded: true, isDisabled: false, type: 'Text' },
  { headerId: 'serialNumber', headerLabel: 'Serial no.', value: 'serialNumber', label: 'Serial no.', id: 4, isIncluded: true, isDisabled: false, type: 'Text' },
  { headerId: 'accountNumber', headerLabel: 'Account no.', value: 'accountNumber', label: 'Account no.', id: 6, isIncluded: true, isDisabled: false, type: 'Text' },
  { headerId: 'batchItemSequenceNumber', headerLabel: 'Seq. no.', value: 'batchItemSequenceNumber', label: 'Seq. no.', id: 7, isIncluded: true, isDisabled: false, type: 'Text' },
  { headerId: 'batchNumber', headerLabel: 'Batch', value: 'batchNumber', label: 'Batch', id: 9, isIncluded: true, isDisabled: false, type: 'Text' },
] as IColumnOptions[];

export const OperationOptionEnums = [
      {
          label: 'Select',
          value: 'select',
          id: 0,
          isDisabled: true
      },
      {
          label: 'Equal to',
          value: '==',
          id: 1,
      },
      {
          label: 'Greater than',
          value: '>',
          id: 2,
          notApplicableTo: 'Text'
      },
      {
          label: 'Less than',
          value: '<',
          id: 3,
          notApplicableTo: 'Text'
      },
      {
          label: 'Greater than or equal to',
          value: '>=',
          id: 4,
          notApplicableTo: 'Text'
      },
      {
          label: 'Less than or equal to',
          value: '<=',
          id: 5,
          notApplicableTo: 'Text'
      },
      {
          label: 'Begins with',
          value: '_=',
          id: 6,
          notApplicableTo: 'Currency'
      },
      {
          label: 'Ends with',
          value: '_-=',
          id: 7,
          notApplicableTo: 'Currency'
      },
      {
          label: 'Contains',
          value: '@=',
          id: 8,
          notApplicableTo: 'Currency'
      },
      {
          label: 'Range from',
          value: 'range',
          id: 9,
          notApplicableTo: 'Text'
      }
  ];