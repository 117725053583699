type BooleanDisplayProps = {
    value: boolean
};

function BooleanDisplay({ value }: BooleanDisplayProps) {
    return value
        ? <span>Yes</span>
        : <span>No</span>
}

export default BooleanDisplay;
