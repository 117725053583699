/* eslint-disable react-hooks/exhaustive-deps */
import { NotificationBanner, Button, ErrorSummary, Stack, Textarea, TextInput } from '@ux/balance-react';
import { useEffect, useState, useRef } from 'react';
import { useRecoilState, useSetRecoilState } from "recoil";
import { createBanner, CreateEditBanner, deleteBanner, editBanner, getActiveBanners, getClientBanners } from '../API_Integrations/banners';
import { activeBannersState, bannersState } from "../atom";
import CalendarDateInput from '../components/CalendarDateInput';
import Table from '../components/Table';
import ClientHeader from '../components/ClientHeader';
import ModalsContainer from '../components/ModalsContainer';
import CustomModal from '../components/CustomModal';
import { useTitle } from '../utils/use-title';
import { useFocusOnElemnt } from '../utils/use-focus-on-element';
import moment from 'moment-timezone';

const ClientBannerManagement = () => {
    useTitle("Banner management");

    enum ViewState { Viewing, Creating, Editing }
    interface ErrorMessage { ariaLabel: string, text: string, inputId: string }

    const setActiveBanners = useSetRecoilState(activeBannersState);
    const [banners, setBanners] = useRecoilState(bannersState);
    const [viewState, setViewState] = useState(ViewState.Viewing);
    const [isDeleting, setIsDeleting] = useState(false);
    const [itemToBeEdited, setItemToBeEdited] = useState(0);
    const [itemToBeDeleted, setItemToBeDeleted] = useState(0);
    const [bannerToCreateOrEdit, setBannerToCreateOrEdit] = useState<CreateEditBanner>({ title: "", content: "", siteCodes: [] });
    const [isPreviewing, setIsPreviewing] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [errorMessagesSummary, seterrorMessagesSummary] = useState<ErrorMessage[]>([]);
    const [isvalidBannerTitle, setIsvalidBannerTitle] = useState(true);
    const [isvalidBannerText, setIsvalidBannerText] = useState(true);
    const [isvalidStartDate, setIsvalidStartDate] = useState(true);
    const [isvalidEndDate, setIsvalidEndDate] = useState(true);
    const [isSmallEndDate, setIsSmallEndDate] = useState(true);
    const errorSummaryRef = useRef<HTMLElement | null>(null);
    const initialTextRef = useRef<HTMLHeadingElement | null >(null);

    useFocusOnElemnt(initialTextRef);

    useEffect(() => {
        const fetchData = async () => {
            return await getClientBanners();
        }

        fetchData().then(banners => setBanners(banners));
    }, [setBanners, viewState]);

    useEffect(() => {
        if (isPreviewing) {
            let errors = validateForm();
            if (errors.length !== 0) {
                setIsPreviewing(false);
            }
        }
    }, [bannerToCreateOrEdit, isPreviewing]);

    const columnData = [
        { headerId: 'title', headerLabel: 'Title' },
        { headerId: 'content', headerLabel: 'Content' },
        { headerId: 'startOnFormatted', headerLabel: 'Start date' },
        { headerId: 'endOnFormatted', headerLabel: 'End date' },
        {
            rowCellTableRowComponentsArrayIndex: 0,
            headerId: 'delete',
            headerLabel: 'Edit or delete',
            type: 'component',
            disableSorting: true
        }
    ];

    const onClickCreate = () => {
        setViewState(ViewState.Creating);
    };

    const onClickCancelCreate = () => {
        setViewState(ViewState.Viewing);
        setBannerToCreateOrEdit({ title: "", content: "", siteCodes: [] });
        setIsPreviewing(false);
        setIsPublished(false);
        setIsDeleting(false);
        handleEmptyErrorMessages();
    };

    const onClickEdit = (bannerId: number) => {
        const banner = banners.find(banner => banner.id === bannerId);
        if (banner === undefined) {
            return;
        }

        setViewState(ViewState.Editing);
        setItemToBeEdited(bannerId);

        let startDate = moment(banner.startOn).tz('America/Chicago')?.format('MM/DD/YYYY');
        let endDate = moment(banner.endOn).tz('America/Chicago')?.format('MM/DD/YYYY');
        let startDateTimeChange = new Date(startDate);
        let endDateTimeChange = new Date(endDate);
        startDateTimeChange.setHours(0, 0, 0, 0);
        endDateTimeChange.setHours(23, 59, 59, 0); 

        setBannerToCreateOrEdit({
            title: banner.title,
            content: banner.content,
            siteCodes: banner.sites.map(s => s.siteCode),
            linkTitle: banner.linkTitle,
            linkUrl: banner.linkUrl,
            startOn: startDateTimeChange,
            endOn: endDateTimeChange
        });
    }

    const onClickDelete = (bannerId: number) => {
        setItemToBeDeleted(bannerId);
        setIsDeleting(true);
    };

    const onConfirmDelete = async () => {
        setIsDeleting(false);
        await deleteBanner(itemToBeDeleted);
        setBanners(banners => banners.filter(banner => banner.id !== itemToBeDeleted));

        // Refresh the active banners
        var activeBanners = await getActiveBanners();
        setActiveBanners(activeBanners);
    };

    const onBannerPreview = () => {
        const errors = validateForm();

        if (errors.length === 0) {
            setIsPreviewing(true);
        }
    }

    const onBannerPublish = async () => {
        validateForm();

        if (errorMessagesSummary.length === 0) {
            if (itemToBeEdited === 0) {
                await createBanner(bannerToCreateOrEdit);
            } else {
                await editBanner(itemToBeEdited, bannerToCreateOrEdit);
            }

            setViewState(ViewState.Viewing);
            setIsPreviewing(false);
            setIsPublished(true);

            // Refresh the active banners
            var activeBanners = await getActiveBanners();
            setActiveBanners(activeBanners);
        }
    };

    const getActions = (props: any) => {
        return (
            <div className='report-actions'>
                <Button
                    clickMethod={() => onClickEdit(props.row.original.id)}
                    icon="IconEditPencil"
                    size="small"
                    text="Edit"
                    variant="text-primary"
                />
                <span className='action-separator'> | </span>
                <Button
                    clickMethod={() => onClickDelete(props.row.original.id)}
                    icon="IconTrash"
                    size="small"
                    text="Delete"
                    variant="text-primary"
                />
            </div>
        );
    };

    const handleBannerTitleChange = (event: any) => {
        setBannerToCreateOrEdit({ ...bannerToCreateOrEdit, title: event.target.value });
    };

    const handleBannerTextChange = (event: any) => {
        setBannerToCreateOrEdit({ ...bannerToCreateOrEdit, content: event.target.value });
    };

    const handleStartDateOnChange = (value: any) => {
        setBannerToCreateOrEdit({ ...bannerToCreateOrEdit, startOn: value })
    }

    const handleEndDateOnChange = (value: any) => {
        setBannerToCreateOrEdit({ ...bannerToCreateOrEdit, endOn: value })
    }

    const getHeader = () => {
        if (viewState === ViewState.Creating) {
            return " Add a new banner";
        }
        else if (viewState === ViewState.Editing) {
            return "Edit banner";
        }
        else {
            return "Current banners";
        }
    }

    const validateForm = () => {
        const errors: ErrorMessage[] = [];

        if (bannerToCreateOrEdit.title.trim() === "") {
            errors.push({ ariaLabel: "Banner title (required)", text: "Banner title (required)", inputId: 'bannerTitle' });
            setIsvalidBannerTitle(false);
        } else {
            setIsvalidBannerTitle(true);
        }

        if (bannerToCreateOrEdit.content.trim() === "") {
            errors.push({ ariaLabel: "Banner content (required)", text: "Banner content (required)", inputId: 'bannerContent' });
            setIsvalidBannerText(false);
        } else {
            setIsvalidBannerText(true);
        }

        if (bannerToCreateOrEdit.startOn === undefined || bannerToCreateOrEdit.startOn === null) {
            errors.push({ ariaLabel: "Banner start date (required)", text: "Banner start date (required)", inputId: 'startDateInput' });
            setIsvalidStartDate(false);
        } else {
            setIsvalidStartDate(true);
        }

        if (bannerToCreateOrEdit.endOn === undefined || bannerToCreateOrEdit.endOn === null) {
            errors.push({ ariaLabel: "Banner end date (required)", text: "Banner end date (required)", inputId: 'endDateInput' });
            setIsvalidEndDate(false);
        } else {
            setIsvalidEndDate(true);
        }

        if (bannerToCreateOrEdit.startOn !== undefined && bannerToCreateOrEdit.startOn !== null && bannerToCreateOrEdit.endOn !== undefined && bannerToCreateOrEdit.endOn !== null && bannerToCreateOrEdit.endOn < bannerToCreateOrEdit.startOn) {
            errors.push({ ariaLabel: "Banner end date (required)", text: "Banner end date (required)", inputId: 'endDateInput' });
            setIsSmallEndDate(false);
        } else {
            setIsSmallEndDate(true);
        }

        seterrorMessagesSummary(errors);
        return errors;
    }

    const handleEmptyErrorMessages = () => {
        seterrorMessagesSummary([]);
        setIsvalidBannerTitle(true);
        setIsvalidBannerText(true);
        setIsvalidStartDate(true);
        setIsvalidEndDate(true);
        setIsSmallEndDate(true);
    };

    return (
        <div className="gap-32-vertical">
            <ClientHeader showClientName={true} showBreadcrumbs={false} />
            <div>
                <h4 ref={initialTextRef} tabIndex={-1} className="flex-grow heading-title-banners heading-focus-text">Banner management<span className='pipe'>|</span>{getHeader()}</h4>
                {viewState === ViewState.Viewing &&
                    <div className="flex-shrink">
                        <Button
                            clickMethod={onClickCreate}
                            size="medium"
                            text="Create new banner"
                            variant="secondary"
                        />
                    </div>
                }
            </div>

            {banners && banners.length === 0 &&
                <div className="banner-margin-top">You don't have any banners yet.</div>
            }

            {banners && banners.length > 0 && viewState === ViewState.Viewing &&
                <Table
                    classes="banner-margin-top"
                    caption="Banners"
                    columnData={columnData}
                    data={banners}
                    isLined
                    tableRowComponents={[
                        getActions
                    ]}
                />
            }

            {viewState !== ViewState.Viewing &&
                <Stack
                    classes="banner-create-edit-container"
                >
                    <div className="banner-create-edit-container">
                        <Button classes="banner-return-button" icon="IconChevronLeft" variant="text-primary" text="Go back to current banners" onClick={onClickCancelCreate} />

                        {errorMessagesSummary.length !== 0 &&
                            <ErrorSummary
                                errors={errorMessagesSummary}
                                ref={errorSummaryRef}
                            />
                        }

                        <TextInput
                            id="bannerTitle"
                            classes="form-control"
                            name="bannerTitle"
                            label="Banner title (required)"
                            handleOnChange={handleBannerTitleChange}
                            value={bannerToCreateOrEdit.title}
                            size='small'
                            errorMessages={["Enter a banner title."]}
                            isValid={isvalidBannerTitle}
                        ></TextInput>
                        <Textarea
                            inputId="bannerText"
                            classes="form-control"
                            name="bannerText"
                            label="Banner content (required)"
                            isRequired
                            handleOnChange={handleBannerTextChange}
                            value={bannerToCreateOrEdit.content}
                            size='small'
                            errorMessages={["Enter the banner content."]}
                            isValid={isvalidBannerText}
                        />
                        <div className="grid form-control">
                            <div className="item">
                                <CalendarDateInput
                                    id="startDateInput"
                                    label="Banner start date (required)"
                                    selected={bannerToCreateOrEdit.startOn}
                                    onChange={handleStartDateOnChange}
                                    maxYearOffset={5}
                                    emptyError={!isvalidStartDate}
                                />
                                {isvalidStartDate === false ?
                                <div className="form-control__error-messages" data-testid="errorMessages" aria-hidden="false" aria-live="polite" role="alert"><p className="form-control__error-message"><span className="form-control__error-icon" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill-rule="currentColor" aria-hidden="true" role="presentation" className="icon"><path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm0 1.8a8.2 8.2 0 1 0 0 16.4 8.2 8.2 0 0 0 0-16.4ZM12 15a1.1 1.1 0 1 1 0 2.2 1.1 1.1 0 0 1 0-2.2Zm0-8.15a.9.9 0 0 1 .893.787l.007.113v4.5a.9.9 0 0 1-1.793.113l-.007-.113v-4.5a.9.9 0 0 1 .9-.9Z" fill="currentColor" fill-rule="nonzero"></path></svg></span><span>Enter a banner start date.</span></p></div>
                                :
                                <></>
                                }
                            </div>
                            <div className="item">
                                <CalendarDateInput
                                    id="endDateInput"
                                    label="Banner end date (required)"
                                    selected={bannerToCreateOrEdit.endOn}
                                    onChange={handleEndDateOnChange}
                                    maxYearOffset={5}
                                    emptyError={!isvalidEndDate || !isSmallEndDate}
                                />
                                {isvalidEndDate === false ?
                                <div className="form-control__error-messages" data-testid="errorMessages" aria-hidden="false" aria-live="polite" role="alert"><p className="form-control__error-message"><span className="form-control__error-icon" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill-rule="currentColor" aria-hidden="true" role="presentation" className="icon"><path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm0 1.8a8.2 8.2 0 1 0 0 16.4 8.2 8.2 0 0 0 0-16.4ZM12 15a1.1 1.1 0 1 1 0 2.2 1.1 1.1 0 0 1 0-2.2Zm0-8.15a.9.9 0 0 1 .893.787l.007.113v4.5a.9.9 0 0 1-1.793.113l-.007-.113v-4.5a.9.9 0 0 1 .9-.9Z" fill="currentColor" fill-rule="nonzero"></path></svg></span><span>Enter a banner end date.</span></p></div>
                                : !isSmallEndDate ?
                                <div className="form-control__error-messages" data-testid="errorMessages" aria-hidden="false" aria-live="polite" role="alert"><p className="form-control__error-message"><span className="form-control__error-icon" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill-rule="currentColor" aria-hidden="true" role="presentation" className="icon"><path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm0 1.8a8.2 8.2 0 1 0 0 16.4 8.2 8.2 0 0 0 0-16.4ZM12 15a1.1 1.1 0 1 1 0 2.2 1.1 1.1 0 0 1 0-2.2Zm0-8.15a.9.9 0 0 1 .893.787l.007.113v4.5a.9.9 0 0 1-1.793.113l-.007-.113v-4.5a.9.9 0 0 1 .9-.9Z" fill="currentColor" fill-rule="nonzero"></path></svg></span><span>Enter a banner end date later than the start date.</span></p></div>
                                :
                                <></>
                                }
                            </div>
                        </div>

                        {isPreviewing && errorMessagesSummary.length === 0 &&
                            <NotificationBanner
                                classes="banner-item form-control"
                                ctaHref={bannerToCreateOrEdit.linkUrl}
                                ctaLabel={bannerToCreateOrEdit.linkTitle}
                                description={bannerToCreateOrEdit.content}
                                title={bannerToCreateOrEdit.title}
                                variant="informative"
                            />
                        }
                        <div className="grid">
                            {!isPreviewing && <Button
                                text="Preview banner"
                                variant="primary"
                                clickMethod={onBannerPreview}
                            />}

                            {isPreviewing && errorMessagesSummary.length === 0 &&
                                <Button
                                    text="Publish banner"
                                    variant="primary"
                                    clickMethod={onBannerPublish}
                                />
                            }
                            <Button
                                text="Cancel"
                                variant="secondary"
                                clickMethod={onClickCancelCreate}
                            />
                        </div>
                    </div>
                </Stack>
            }

            <ModalsContainer noGap>
                <CustomModal
                    okType
                    ModalText={`Your banner has been published and will be live until ${bannerToCreateOrEdit?.endOn?.toLocaleDateString()}.`}
                    ModalTitle="Banner submitted"
                    ModalIsOpen={isPublished}
                    CloseModal={onClickCancelCreate}
                />
                <CustomModal
                    yesOrNoType
                    ModalIsOpen={isDeleting} 
                    CloseModal={() => setIsDeleting(false)} 
                    noText="No, I do not want to delete it"
                    yesText="Yes, I want to delete it"
                    yesClickMethod={() => onConfirmDelete()}
                    ModalText="Are you sure you want to delete this banner? The information will no longer be available."
                    ModalTitle="Deleting banner"
                />
            </ModalsContainer>
        </div>

    );
};

export default ClientBannerManagement;
