import requests from "./apiRequest";

export interface TransactionReport {
    includeChecks: boolean
    includeDocuments: boolean
}

const TransactiondataDetail = {
    details: async (ID: number) => {
        try {
            let result = await requests.get(`/transactions/${ID}`);
            return result;
        } catch (error) {
        console.log('error from transactions api integration: ', error);
        }
    },
    generateReport: async (ID: number, body: TransactionReport) => {
        await requests.post(`/transactions/${ID}/report`, body);
    },
    addReview: async (ID: number) => {
        try {
            await requests.postParams(`transactions/${ID}/review`);
        } catch (error) {
            console.log('error from transaction add review api integration: ', error);
        }
    },
    deleteReview: async (ID: number) => {
        try {
            await requests.delete(`transactions/${ID}/review`);
        } catch (error) {
            console.log('error from transaction delete review api integration: ', error);
        }
    }
}
export default TransactiondataDetail;
