/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef } from "react";
// @ts-ignore
import { Link, Tabs, TabList, Tab, TabPanel, Button } from '@ux/balance-react';
import { useRecoilState } from "recoil";
import { ShowCientModal } from "../atom";
import ClientHeader from '../components/ClientHeader';
import ModalsContainer from "../components/ModalsContainer";
import ClientSelectionModal from "../components/ClientSelectionModal";
import { useTitle } from "../utils/use-title";
import { useFocusOnElemnt } from '../utils/use-focus-on-element';


const email = 'clientservicesgroup@regions.com';

function HelpAndSupport() {
    useTitle("Help and support");
    const [clientModallIsOpen, setClientModallIsOpen] = useRecoilState(ShowCientModal);
    const initialTextRef = useRef<HTMLHeadingElement | null>(null);
    useFocusOnElemnt(initialTextRef);

    useEffect(() => {
    }, [clientModallIsOpen]);

    const tabs = [
        { id: 'tab1', label: 'Contact us' },
        { id: 'tab2', label: 'FAQs' },
        { id: 'tab3', label: 'Help cards' }
    ];

    const cards = [
        { id: 'card1', label: 'Navigation tools', path: '/PDFs/LockboxIQ Help Card - Summary and Navigation Tools.pdf' },
        { id: 'card2', label: ' Viewing transactions (Batch Summary, Batch Detail, Transaction Detail)', path: '/PDFs/LockboxIQ Help Card - Viewing Transactions.pdf' },
        { id: 'card3', label: 'Search and reports', path: '/PDFs/LockboxIQ Help Card - Search and Reports.pdf' },
        { id: 'card3', label: 'Alerts', path: '/PDFs/LockboxIQ Help Card - Creating Alerts Help Card.pdf' },
        { id: 'card3', label: 'Banner management', path: '/PDFs/LockboxIQ Help Card - Banner Management.pdf' },
        { id: 'card3', label: 'User management', path: '/PDFs/LockboxIQ Help Card - User Management.pdf' },
        { id: 'card3', label: 'Remitters', path: '/PDFs/LockboxIQ Help Card - Managing Remitter Information.pdf' },
        { id: 'card3', label: 'Correspondence', path: '/PDFs/LockboxIQ Help Card - Correspondence.pdf' },
        { id: 'card3', label: 'Exception management', path: '/PDFs/' },
    ];

    const openPDF = (file: string) => {
        window.open(process.env.PUBLIC_URL + file, '_blank')
    }

    return (
        <main>
            <ClientHeader helpAndSupport={true} showClientName={false} showBreadcrumbs={false} />
            <span ref={initialTextRef} tabIndex={-1} className='sr-only' aria-hidden={true}>Help and support</span>
            <div className='tabs-container exception-tabs'>
                <Tabs spreadTabs={false}>
                    <TabList>
                        <Tab>
                            Contact us
                        </Tab>
                        <Tab>
                            FAQs
                        </Tab>
                        <Tab>
                            Help cards
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <div className='tab-panel-inner-contents'>
                            <div className="search-transactions-container">
                                <h4 className='help-title'>Regions Client Services</h4>
                                <div>
                                    <p className='help-texts '>1-800-787-3905, option 6</p>
                                    <Link
                                        classes="help-link"
                                        href={`mailto:${email}`}
                                        text={email}
                                        size='xlarge'
                                    />
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className='tab-panel-inner-contents'>
                            <div>
                                <h4 className='help-title'>Frequently asked questions</h4>
                                <div>
                                    <p className='faq-title'>How long can I view images in LockboxIQ?</p>
                                    <p className='faq-text'>Images are available up to 180 days, but up to 7 years for a subscription fee. Images can be viewed in 90-day increments.</p>
                                </div>
                                <div>
                                    <p className='faq-title'>In what file formats can I download my payments?</p>
                                    <ul>
                                        <li className='faq-li-text'>Transaction data can be downloaded to a PDF or CSV file from LockboxIQ at no charge.</li>
                                        <li className='faq-li-text'>Images can be downloaded to a PDF or zip file from LockboxIQ at no charge.</li>
                                        <li className='faq-li-text'>Additionally, a file transmission can be set up to send data and images directly to your company for easy integration and reconcilement. This service requires further set up and testing and is available for a subscription fee.</li>      
                                    </ul>
                                        
                                </div>
                                <div>
                                    <p className='faq-title'>Can I receive images of the envelopes?</p>
                                    <p className='faq-text'>Yes, Regions Lockbox processing can scan the payment envelope and provide the image in the transaction detail within LockboxIQ.</p>
                                </div>
                                <div>
                                    <p className='faq-title'>Who can I call if I have questions?</p>
                                    <p className='faq-text'>Contact Regions Treasury Client Services at 1-800-787-3905, option 6 or email <Link
                                        classes="faq-helplink"
                                        href={`mailto:${email}`}
                                        text={email}
                                        size='xlarge'
                                    />  (7:00 a.m. to 6:00 p.m. Central Time).</p>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className='tab-panel-inner-contents'>
                            <div>
                                <h4 className='help-title'>Help cards (PDFs)</h4>
                                <div>
                                    {cards.map((card, index) => (
                                        <div>
                                            <Button
                                                clickMethod={() => { openPDF(card.path) }}
                                                text={card.label}
                                                variant="text-primary"
                                                classes="help-card-link"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </div>

            <ModalsContainer noGap>
                <ClientSelectionModal ModalIsOpen={clientModallIsOpen} CloseModal={() => { setClientModallIsOpen(false) }} />
            </ModalsContainer>
        </main>
    );
};

export default HelpAndSupport;