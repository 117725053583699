import requests from "./apiRequest";

const CorpCodes = {
  list: async () => {
    try {
      let result = await requests.get('/corp-codes');
      return result;
    } catch (error) {
      console.log('error from corp codes api integration: ', error);
    }
  }
}

export default CorpCodes;