function SearchSection(props: any) {

    return (
        <div className="search-transactions-container">
            <p className={!props.transactionSection ? 'search-title search-title__non-transaction-detail' :'search-title'}>{props.title}</p>
            <div className={props.transactionSection ? 'search-fields-container display-block' : 'search-fields-container'}>
                {props.children}
            </div>
            <div className='card-divider'></div>
        </div>
    );
};

export default SearchSection;
