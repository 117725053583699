/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, FormEvent } from 'react';

//@ts-ignore
import { Button } from '@ux/balance-react';
import { useRecoilState } from 'recoil';
import { NavToggle } from '../../atom';

import ButtonContainer from '../ButtonContainer';

function SearchNavigationTabs(props: any) {

    const [toggle, setToggle] = useRecoilState(NavToggle);
    const tabButtonRef = useRef<(HTMLButtonElement | null)[]>([]);

    const toggleTab = (index: number) => {
        setToggle(index);
    }

    useEffect(() => {
        if (tabButtonRef.current[toggle - 1]) {
            tabButtonRef.current[toggle - 1]?.focus();
        }
    }, [toggle]);

    return (
        <div className='tabs-container'>
            <div role='tablist' className='tabs-header'>
                <button role='tab' className={toggle === 1 ? "custom-tabs active-tabs" : "custom-tabs"} onClick={() => { toggleTab(1) }} ref={(el) => (tabButtonRef.current[0] = el)} >
                    {props.Tab1}
                </button>
                <button role='tab' className={toggle === 2 ? "custom-tabs active-tabs" : "custom-tabs"} onClick={() => { toggleTab(2) }} ref={(el) => (tabButtonRef.current[1] = el)} >
                    {props.Tab2}
                </button>
                {props.Tab3 ?
                    <button role='tab' className={toggle === 3 ? "custom-tabs active-tabs" : "custom-tabs"} onClick={() => { toggleTab(3) }} ref={(el) => (tabButtonRef.current[2] = el)} >
                        {props.Tab3}
                    </button>
                    :
                    <></>
                }
                {props.Tab4 ?
                    <button role='tab' className={toggle === 4 ? "custom-tabs active-tabs" : "custom-tabs"} onClick={() => { toggleTab(4) }} ref={(el) => (tabButtonRef.current[3] = el)} >
                        {props.Tab4}
                    </button>
                    :
                    <></>
                }
            </div>
            <div role='tabpanel' className='tabs-content'>
                <div className={toggle === 1 ? "content active-content" : "content"}>
                    <form onSubmit={(e: FormEvent<HTMLFormElement>) => {
                        e.preventDefault();
                        props.HandleViewSearch();
                    }} id='search-and-reports-form'>
                        <div>

                            {props.Content1}

                        </div>
                        {(toggle === 1 && props.bottomControl1) ?
                            <ButtonContainer noTopGap>
                                <Button
                                    classes="search-and-reports-button"
                                    size="medium"
                                    text="View search results"
                                    type="submit"
                                />
                                <Button
                                    clickMethod={() => { toggleTab(2) }}
                                    size="medium"
                                    text="Customize search results"
                                    variant="secondary"
                                    classes="left-24-gap-filters"
                                />
                            </ButtonContainer>
                            : <></>
                        }
                    </form>
                </div>
                <div className={toggle === 2 ? "content active-content" : "content"}>
                    <div>
                        {props.Content2}
                    </div>
                    {(toggle === 2 && props.bottomControl2) ?
                        <ButtonContainer>
                            <Button
                                clickMethod={() => { toggleTab(3) }}
                                size="medium"
                                text="View search results"
                            />
                            <Button
                                clickMethod={() => { toggleTab(1) }}
                                size="medium"
                                text="Back"
                                variant="secondary"
                                classes="left-24-gap-filters"
                            />
                        </ButtonContainer>
                        : <></>
                    }
                </div>
                <div className={toggle === 3 ? "content active-content" : "content"}>
                    <div>{props.Content3}</div>
                </div>
                <div className={toggle === 4 ? "content active-content" : "content"}>
                    <div>{props.Content4}</div>
                </div>
            </div>

        </div>);
}

export default SearchNavigationTabs;
