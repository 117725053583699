import requests from "./apiRequest";
export interface DailyExceptionSummaryRes  {
    user: string
    status: string
    transactionCount: number
    transactionCountFormatted: string
}
export interface DailyDecisionSummaryRes {
    totalExceptionsCount: number
    totalExceptionsCountFormatted: string
    outstandingExceptionsCount: number
    outstandingExceptionsCountFormatted: string
    completedExceptionsCount: number
    completedExceptionsCountFormatted: string
    returnedExceptionsCount: number
    returnedExceptionsCountFormatted: string
    escalatedExceptionsCount: number
    escalatedExceptionsCountFormatted: string
    rejectedExceptionsCount: number
    rejectedExceptionsCountFormatted: string
    exceptionSummaries: DailyExceptionSummaryRes []
}
export interface DailySummaryMirror {
    date: string
    totalExceptions: number
    outstanding: number
    completed: number
    returned: number
    escalated: number   
    rejected: number
}

export interface DailyExceptionSummaryReportRequest {
    processingDate: string
}


export const dailyExceptionSummaryData = async (date: string): Promise<DailyDecisionSummaryRes> => {
    let result = await requests.getParams('summaries/exception-daily-decision-summary', { processingDate: date });
    return result;
}

export const generateDailyExceptionSummaryReport = async (body: DailyExceptionSummaryReportRequest) => {
    await requests.post(`/summaries/exception-daily-decision-summary/report?processingDate=${body.processingDate}`, body)
} 
