/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
// @ts-ignore
import { Stack } from '@ux/balance-react';
import { useFocusOnElemnt } from '../utils/use-focus-on-element';

function ExceptionMirror(props: any) {
    const initialTextRef = useRef<HTMLHeadingElement | null>(null);

    useEffect(() => {
        if (props.exceptionTransactionDetail) {
            props.onHeadingFocus(initialTextRef);
        }
    }, [props.onHeadingFocus]);

    useFocusOnElemnt(initialTextRef);
    return (
        <div className='mirror-main-container'>
            <Stack>
                <div className='mirror-highlights-container'>
                    {
                        (props.dailySummaryReport) ?
                            <h1 ref={initialTextRef} tabIndex={-1} className='text-style-heading-4 heading-exception-daily-summary heading-focus-text'>Daily decision summary for {props.date}<span className='pipe'>|</span>Exception management</h1>
                            : (props.dailyDecisionDetail) ?
                                <h1 ref={initialTextRef} tabIndex={-1} className='text-style-heading-4 heading-exception-daily-summary heading-focus-text'>Daily decision detail for {props.date}<span className='pipe'>|</span>Exception management</h1>
                                : (props.exceptionBatchSummary) ?
                                    <h1 ref={initialTextRef} tabIndex={-1} className='text-style-heading-4 heading-exception-daily-summary heading-focus-text'>Exception batch summary for {props.date}<span className='pipe'>|</span>Box ID {props.id}</h1>
                                    : (props.exceptionBatchDetail) ?
                                        <h1 ref={initialTextRef} tabIndex={-1} className='text-style-heading-4 exceptions-mainheading heading-focus-text'>{props.allBatches ? `All exception batches` : `Exception batch ${props.batchId} detail`} for {props.date}<span className='pipe'>|</span>Box ID {props.id}{props.showCorpCode ? <><span className='pipe'>|</span>{props.allCorpCodes ? `All Corp Codes` : `Corp Code ${props.corpCode}`}</> : <></>}</h1>
                                        : (props.exceptionTransactionDetail) ?
                                            <h1 ref={initialTextRef} tabIndex={-1} className='text-style-heading-4 heading-exception-daily-summary heading-focus-text'>Exception transaction detail for: {props.name}<span className='pipe'>|</span>Box ID {props.id}<span className='pipe'>|</span>Sequence {props.transactionId}</h1>
                                            :
                                            <></>
                    }
                </div>
            </Stack>
            <Stack>
                <div className='mirror-highlights-container'>
                    <div className='mirror-details-container'>
                        <div>
                            {
                                (props.dailySummaryReport) ?
                                    <div className='exceptions-subheading'><p><span>Total exceptions: </span><strong>{props.totalExceptions}</strong></p><p> <span>Outstanding: </span><strong>{props.outstanding}</strong></p><p> <span>Completed: </span><strong>{props.completed}</strong></p><p> <span>Returned:</span> <strong>{props.returned}</strong></p><p> <span>Escalated: </span><strong>{props.escalated}</strong></p><p> <span>Rejected: </span><strong>{props.rejected}</strong></p></div>
                                    : (props.exceptionBatchSummary) ?
                                        <div className='exceptions-subheading'><p><span>Outstanding exceptions: </span><strong>{props.outstanding}</strong></p><p> <span>Completed: </span><strong>{props.completed}</strong></p><p> <span>Returned:</span> <strong>{props.returned}</strong></p><p> <span>Escalated: </span><strong>{props.escalated}</strong></p><p> <span>Rejected: </span><strong>{props.rejected}</strong></p></div>
                                        : (props.exceptionBatchDetail) ?
                                            <div className='exceptions-subheading'><p><span>Batch number: </span>{props.allBatches ? `All` : `${props.batchNumber}`}</p><p><span>Total exceptions: </span><strong>{props.total}</strong></p><p><span>Outstanding exceptions: </span><strong>{props.outstanding}</strong></p><p> <span>Completed: </span><strong>{props.completed}</strong></p><p> <span>Returned:</span> <strong>{props.returned}</strong></p><p> <span>Escalated: </span><strong>{props.escalated}</strong></p><p> <span>Rejected: </span><strong>{props.rejected}</strong></p></div>
                                            : (props.exceptionTransactionDetail) ?
                                                <div className='exceptions-subheading'><p><span>Batch number: </span><strong>{props.batchNumber}</strong></p><p><span>Deposit date: </span><strong>{props.date}</strong></p><p><span>Total exceptions: </span><strong>{props.totalExceptions}</strong></p><p><span>Outstanding exceptions: </span><strong>{props.outstanding}</strong></p><p> <span>Completed: </span><strong>{props.completed}</strong></p><p> <span>Returned:</span> <strong>{props.returned}</strong></p><p> <span>Escalated: </span><strong>{props.escalated}</strong></p><p> <span>Rejected: </span><strong>{props.rejected}</strong></p></div>
                                                :
                                                <></>
                            }
                        </div>
                    </div>
                </div>
            </Stack>
        </div>
    )
}
export default ExceptionMirror;
