import requests from "./apiRequest";
export interface BatchItemsRes {
    exceptionBatchId: number
    exceptionBatchNumber: string
    totalExceptionsCount: number
    totalExceptionsCountFormatted: string
    outstandingExceptionsCount: number
    outstandingExceptionsCountFormatted: string
    status: string
    users: string[]
}
export interface BatchSummaryRes {
    processingDate: string
    lockboxCode: string
    totalExceptionsCount: number
    totalExceptionsCountFormatted: string
    outstandingExceptionsCount: number
    outstandingExceptionsCountFormatted: string
    completedExceptionsCount: number
    completedExceptionsCountFormatted: string
    returnedExceptionsCount: number
    returnedExceptionsCountFormatted: string
    escalatedExceptionsCount: number
    escalatedExceptionsCountFormatted: string
    rejectedExceptionsCount: number
    rejectedExceptionsCountFormatted: string
    batchItems: BatchItemsRes[]
}
export interface BatchSummaryMirror {
    date: string
    id: string
    outstanding: number
    completed: number
    returned: number
    escalated: number
    rejected: number
}

export interface ExceptionBatchSummaryReportRequest {
    fileType: "Pdf" | "Csv"
    processingDate: string
    lockboxCode: string
}

export const exceptionBatchSummaryData = async (date: string, code: string): Promise<BatchSummaryRes> => {
    let result = await requests.getParams('summaries/exception-batch-summary', { processingDate: date, lockboxCode: code });
    return result;
}

export const generateExceptionBatchSummaryReport = async (body: ExceptionBatchSummaryReportRequest) => {
    await requests.post(`/summaries/exception-batch-summary/report?processingDate=${body.processingDate}&lockboxcode=${body.lockboxCode}`, body);
}
