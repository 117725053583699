import axios, { AxiosError, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import { oktaAuth } from '../App';
import { default as getConfiguration } from '../config';

const ORG_ID_CLAIM_NAME = "OrgId";
const ORG_ID_HEADER_NAME = "X-Org-Id";

const config = getConfiguration();
axios.defaults.baseURL = config.apiUrl;

axiosRetry(axios, { retries: 3 });

const getOrgId = () => {
    let authState = oktaAuth.authStateManager.getAuthState();
    if (authState && authState.idToken) {
        let claim = authState.idToken?.claims[ORG_ID_CLAIM_NAME];
        let claimValue = claim?.toString();
        if (claimValue) {
            sessionStorage.setItem('lockbox-org-id', claimValue);
            return claimValue;
        }
        else {
            let orgId = sessionStorage.getItem('lockbox-org-id');
            return orgId;
        }
    }

    return null;
};

const responseBody = (response: AxiosResponse) => {
    //console.log(response.data)
    return response.data;
}

axios.interceptors.request.use(async axiosConfig => {
    const isAuthenticated = await oktaAuth?.isAuthenticated();
    const orgId = getOrgId();

    if (isAuthenticated) {
        axiosConfig.headers.Authorization = "Bearer " + oktaAuth.getAccessToken();

        // Users can be assigned to multiple clients in OnePass.
        // We need to pass the Org ID in the id token to ensure the right account is selected.
        axiosConfig.headers.set(ORG_ID_HEADER_NAME, orgId);
    }

    return axiosConfig;
}, error => Promise.reject(error));

axios.interceptors.response.use(response => {
    return response;
}, (error: AxiosError) => {
    if(error.response) {
        const errorData = (error.response.data);
        const status = error.response.status;
        switch (status) {
            case 400: console.log('bad request: ', errorData);
            break;
            case 401: console.log('unauthorized request');
            break;
            default: console.log('Unexpected error: ', errorData);
        }
        return Promise.reject(errorData);
    } else {
        return Promise.reject(error);
    }
});

const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    getParams: (url: string, body: {}) => axios.get(url, {
        params: body
    }).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    postParams: (url: string) => axios.post(url).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    patch: (url: string, body: {}) => axios.patch(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody)
}

export default requests;
