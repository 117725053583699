import React from 'react';

function ModalsContainer(props: any) {

    return (
        <div className={props.noGap ? '' :props.bottomGap ? 'download-report-container-bottom-gap ' :props.alerts ? 'alerts-modals' : 'download-report-container'}>
            {props.children}
        </div>
    );
}

export default ModalsContainer;