interface ContentProps {
    title: string
    info: string
    type?: string
}

function ExceptionSetupContentInfo({title, info, type}: ContentProps) {

    return (
        <div className={type === 'other' ? 'top-30-gap' : ''}>
            <p className={type === 'other' ? 'top-30-gap section-title' : 'section-title'}>{title}</p>
            {type === 'other' ? <p className='info-text__18 text-gray-90 strong-text'>{info}</p> :  <p className='info-text__20 text-gray-90'>{info}</p>}
        </div>
    );
};

export default ExceptionSetupContentInfo;
