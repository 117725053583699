// @ts-ignore
import { Button, Modal } from '@ux/balance-react';

function AdditionalOptionsSelection(props: any) {
    return (
        <div>
            <Modal
                isOpen={props.ModalIsOpen}
                ariaLabel="Additional options slection Modal"
                closeMethod={props.CloseModal}
                isModalTitleStatic
                modalCTAs={<><Button clickMethod={props.selectionClickMethod} dataTestId="modal_selection_button" text='Add selections' variant="primary" size='small' /><Button clickMethod={props.CloseModal} dataTestId="modal_close_button" text='Cancel' variant="secondary" size='small' /></>}
                modalTitle=""
            >
                <h5>Add additional {props.columns ? 'columns' : 'transaction detail(s)'}</h5>
                <div
                    className="text-style-body-2"
                    style={{
                        marginTop: '1rem',
                        maxWidth: '100%'
                    }}
                >
                    {props.children}
                </div>
            </Modal>
        </div>
    )
}

export default AdditionalOptionsSelection;