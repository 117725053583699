import requests from "./apiRequest";

export interface DailyDecisionDetailRes {
    transactionCode: string,
    transactionId: number,
    sequenceNumber?: number,
    lockboxCode: string,
    siteCode: string,
    exceptionBatchId: number,
    escalatedUser: string,
    assignedUser: string,
    lockedUser: string,
    user: string,
    lastUpdated: Date,
    checkAmount: number,
    checkAmountFormatted: string,
    routingNumber: string,
    accountNumber: string,
    serialNumber: string,
    status: string,
    documentAccountNumber: string,
    documentMiscellaneous1: string,
    documentMiscellaneous2: string,
    corpCode: string,
    documentAmount: number,
    documentAmountFormatted: string,
    comment: string,
    escalatedUserName?: string
}

export const dailyExceptionDetailData = async (date: string): Promise<DailyDecisionDetailRes []> => {
    let result = await requests.getParams('summaries/exception-daily-decision-detail-summary', { processingDate: date });
    return result;
}
