// @ts-ignore
import { Button } from '@ux/balance-react';
// @ts-ignore
function CustomPagination(props: any) {
  const ExceptionTransaction = props.ExceptionTransaction || false;
  return (
    <div className="pagination-container">
      <div className={props.enlargedImageShow ? 'hide-block': "go-to-text"}>{props.gotoTransaction ? 'Go to next transaction' : 'Go to next image'}</div>
      <div className="pagination-styles">
        <nav className="pagination">{props.children}</nav>
      </div>
      {ExceptionTransaction && (<div className={props.returnToExceptionShow ? 'hide-block' : "go-to-text pagination"}>
        {props.returnToExceptionSummary ? <Button
          clickMethod={props.onClickReturnToExceptionSummary}
          icon="IconChevronRight"
          size="medium"
          text="Return to exception summary"
          ariaLabel="Add payment detail"
          variant="text-primary"
          iconPosition="right"
          classes="cta cta--text-alternate cta--medium"
        /> : <></>}
      </div>)}
    </div>
  );
}

export default CustomPagination;
