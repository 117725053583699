import requests from "./apiRequest";

export interface Lockboxes {
    code: string
    type: string
    name: string
    startBatchNumber: number
    endBatchNumber: number
    siteCode: string
    siteName: string
    clientCode: string
    clientName: string
    isOnlineDecision: boolean
}

export interface ExceptionSummaryRes {
    lockboxCode: string
    lockboxName: string
    siteCode: string
    siteName: string
    corpCode: string
    corpCodeName: string
    totalVolume: number
    totalVolumeFormatted: string
    totalAmount: number
    totalAmountFormatted: string
    completedVolume: number
    completedVolumeFormatted: string
    completedAmount: number
    completedAmountFormatted: string
    escalatedVolume: number
    escalatedVolumeFormatted: string
    escalatedAmount: number
    escalatedAmountFormatted: string
    pendingVolume: number
    pendingVolumeFormatted: string
    pendingAmount: number
    pendingAmountFormatted: string
    returnedVolume: number
    returnedVolumeFormatted: string
    returnedAmount: number
    returnedAmountFormatted: string
    rejectedVolume: number
    rejectedVolumeFormatted: string
    rejectedAmount: number
    rejectedAmountFormatted: string
}
export interface ExceptionCorpCodeRes {
    lockboxCode: string
    lockboxName: string
    corpCode: string
    corpCodeName: string
    exceptions: number
    exceptionsTotal: number
    exceptionsTotalFormatted: string
}

export interface ExceptionSummaryReportRequest {
    fileType: "Pdf" | "Csv"
    processingDate: string
}

export const getLockboxesList = async (): Promise<Lockboxes[]> => {
    let lockboxesList = await requests.get("/lockboxes");
    return lockboxesList;
}

export const exceptionSummaryData = async (date: string): Promise<ExceptionSummaryRes[]> => {
    let result = await requests.getParams('summaries/exception-summary', {processingDate: date});
    return result;
}

export const exceptionsCorpCodeData = async (date: string): Promise<ExceptionCorpCodeRes[]> => {
    let result = await requests.getParams('summaries/corp-code-exception-summary', {processingDate: date});
    return result;
}

export const generateExceptionSummaryReport = async (body: ExceptionSummaryReportRequest) => {
    await requests.post("/summaries/exception-summary/report", body);
}
