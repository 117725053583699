import requests from "./apiRequest";
import axios from "axios";
import { IClients, IClients_Lockboxes, IClients_req_body, ILockboxes_body } from "../interfaces/IClientMaintenance";

export const getClients = async ( body: IClients_req_body): Promise<IClients[]> => {
    let clients = await requests.getParams("/clients", body);
  return clients;
}
export const getClient = async (id: number): Promise<IClients_Lockboxes> => {
  let client = await requests.get("/clients/" + id);
  return client;
}

export const addClient = async (client: IClients) => {
      await axios.post("/clients", client);
}
export const editClient = async (id: number, client: IClients) => {
      await axios.post("/client/" + id, client);
}
export const addLockboxes = async (id: number, body: ILockboxes_body) => {
  await axios.post("/clients/" + id + "/lockboxes/", body);
}