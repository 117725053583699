import { useEffect } from "react";
interface UseRepeatAnouncementProps {
    repeatLoadingAnnouncement: () => void
    loadingSearch: boolean
}

export function useRepeatAnnouncement({ repeatLoadingAnnouncement, loadingSearch }: UseRepeatAnouncementProps) {

    useEffect(() => {
        if (loadingSearch) {
            const intervalId = setInterval(() => {
                repeatLoadingAnnouncement();
            }, 5000);

            return () => {
                clearInterval(intervalId);
            }
        }
    }, [loadingSearch, repeatLoadingAnnouncement]);

}