import dateFormat from "dateformat";

type DisplayDateProps = {
    date?: Date
    format: 'paddedShortDate' | 'paddedShortDateTime' | 'fullDate'
    gmt?: boolean
};

function DateDisplay({ date, format, gmt }: DisplayDateProps) {
    if (date === undefined) {
        return <span></span>
    }

    switch (format) {
        case 'paddedShortDate':
            return <span>{dateFormat(date, 'paddedShortDate', !!gmt)}</span>
        case 'paddedShortDateTime':
            return <span>{dateFormat(date, 'mm/dd/yyyy hh:MM:ss TT Z')}</span>
        case 'fullDate':
            return <span>{dateFormat(date, 'dddd, mmmm d, yyyy h:MM:ss TT Z')}</span>
    }
}

export default DateDisplay;
