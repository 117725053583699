export type Environment = 'local' | 'sandbox' | 'lockbox-dev' | 'lockbox-test' | 'dr' | 'production'

export const getEnvironmentFromHostname = (hostname: string): Environment => {
    if (hostname.includes('localhost')) {
        return 'local'
    }

    const parts = hostname.split(/[.]/)
    const lowerEnvs: Environment[] = ['sandbox', 'lockbox-dev', 'lockbox-test']
    const domains = ['rgnscloud-dev', 'regionstest', 'regions']
    const initialValue: { environment: Environment | null; domain: string | null } = { environment: null, domain: null };
    const { environment, domain } = parts.reduce((result, nextPart) => {
        if (result.environment === null && lowerEnvs.includes(nextPart as Environment)) {
            result.environment = nextPart as Environment
        }

        if (domains.includes(nextPart)) {
            result.domain = nextPart;
        }

        return result;
    }, initialValue);

    if (environment !== null) {
        return environment;
    } else if (hostname === 'd2qqg658b830wk.cloudfront.net') {
        return 'sandbox';
    } else if (hostname === 'd1y4tt4itdf6un.cloudfront.net') {
        return 'lockbox-dev';
    } else if (hostname === 'd2nd4irv93tf2.cloudfront.net') {
        return 'lockbox-test';
    } else if (domain === 'regions') {
        return 'production';
    } else {
        throw new Error('Environment not detected: ' + hostname);
    }
};
