/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
// @ts-ignore
import { Stack, Popover } from '@ux/balance-react';
import DateDisplay from './DateDisplay';
import { useFocusOnElemnt } from '../utils/use-focus-on-element';

function BatchMirror(props: any) {
    const initialTextRef = useRef<HTMLHeadingElement | null >(null);

    useEffect(() => {
        if(props.transaction_details) {
            props.onHeadingFocus(initialTextRef);
        }
    },[props.onHeadingFocus]);

    useFocusOnElemnt(initialTextRef);
    return (
        <div className='mirror-main-container'>
            <Stack>
                <div className='mirror-highlights-container'>
                    {(props.batch_details) ?
                        <h4 ref={initialTextRef} tabIndex={-1} className='text-style-heading-4 gap-focus heading-focus-text'>Batch {props.batch_ID} detail for {props.date}<span className='pipe'>|</span>Box ID {props.lockbox_code}</h4>
                        :
                        (props.transaction_details) ?
                            <h4 ref={initialTextRef} tabIndex={-1} className='text-style-heading-4 heading-transaction heading-focus-text'>Transaction detail for: {props.lockbox_name}<span className='pipe'>{props.lockbox_name ? '|' : ''}</span>Box ID {props.lockbox_code}<span className='pipe'>{props.lockbox_code ? '|' : ''}</span>Sequence {props.batchItemSequenceNumber}</h4>
                            :
                            <h4 ref={initialTextRef} tabIndex={-1} className='text-style-heading-4 gap-focus heading-focus-text'>Batch summary for: {props.lockbox_name}<span className='pipe'>{props.lockbox_name ? '|' : ''}</span>Box ID {props.lockbox_code}</h4>
                    }
                    {(props.transaction_details) ?
                        <></>
                        :
                        <div className='info-popover-container'>
                            <p className='text-style-body-3'>
                                <span>Transactions: </span><strong className='text-style-body-3-strong'>{props.transaction}</strong></p>
                                <Popover icon="IconInfoCircle" size="small">Transactions equals the total number of documents and associated payments.</Popover>
                        </div>
                    }
                </div>
            </Stack>
            <Stack>
                <div className='mirror-highlights-container'>
                    <div className='mirror-details-container'>
                        <div>
                            {props.transaction_details ?
                                <div className='transaction-details text-style-body-3'><p><span>Site: </span><strong>{props.site}</strong></p><p> <span>Deposit date: </span><strong><DateDisplay date={props.depositDate} format="paddedShortDate" gmt /></strong></p><p> <span>Status: </span><strong>{props.status}</strong></p><p> <span>Batch number:</span> <strong>{props.batchNumber}</strong></p></div>
                                :
                                <p className='text-style-body-3'>Total number of batches for <span>{(props.batch_details) ? <span>{props.date} </span> : <span>box {props.boxNumber}</span>}</span>: <strong>{props.batchCount}</strong></p>
                            }
                        </div>
                        <div>
                            {(props.batch_details || props.transaction_details) ?
                                <></>
                                :
                                <p className='text-style-body-3'><span>Site: </span><strong>{props.site}</strong></p>
                            }

                        </div>
                        <div>
                            {(props.batch_details || props.transaction_details) ?
                                <></>
                                :
                                <p className='text-style-body-3'><span>Status: </span><strong>{props.status}</strong></p>
                            }
                        </div>
                    </div>
                    <div>
                        {(props.transaction_details) ?
                            <></>
                            :
                            <p className='text-style-body-3'><span>{(props.batch_details) ? <span>Batch</span> : <span>Deposit</span>} total: </span><strong>{props.total}</strong></p>
                        }
                    </div>
                </div>
            </Stack>
        </div>
    )
}
export default BatchMirror;
