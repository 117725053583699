export function useDifferenceDate(startDate: Date, endDate: Date) {
    if (startDate && endDate) {
        const differenceMilliseconds = Math.abs((endDate.getTime() - startDate.getTime()));
        const differenceDays = differenceMilliseconds / (1000 * 60 * 60 * 24);
        return differenceDays > 90;
    }

    return false;
}

export function isDateWithinRange(startDate: Date, endDate: Date, numberOfDays = 90): Boolean {
    if (startDate && endDate) {
        const differenceMilliseconds = Math.abs((endDate.getTime() - startDate.getTime()));
        const differenceDays = differenceMilliseconds / (1000 * 60 * 60 * 24);
        return differenceDays <= numberOfDays;
    }

    return false;
}

export function ifEndNull(startDate: Date, numberOfDays = 90): Date {
    if (startDate) {
        const newDate = new Date(startDate);
        newDate.setDate(startDate.getDate() + numberOfDays);
        return newDate;
    } else {
        return new Date();
    }
}

export function ifStartNull(endDate: Date, numberOfDays = 90): Date {
    if (endDate) {
        const newDate = new Date(endDate);
        newDate.setDate(endDate.getDate() - numberOfDays);
        return newDate;
    } else {
        return new Date();
    }
}