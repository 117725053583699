function CardData(props: any) {
  return (
    <div key={props.key} className={(props.additional) ? 'additional-card' : ''}>
      <p className='data-title'>{props.title}</p>
      {(props.type === 'currency') ?
        <p className='data-text'>${props.text}</p>
        :
        <p className='data-text'>{props.text}</p>
      }
    </div>
  )
}

export default CardData;