/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
// @ts-ignore
import { Button, Select, TextInput, Tabs, TabList, Tab, TabPanel } from '@ux/balance-react';
import { useRecoilState, useRecoilValue } from "recoil";
import { exception_setup_unsaved_changes, exception_setup_lockboxId } from '../atom';
import Lockbox from '../API_Integrations/Lockbox';
import { ILockbox, ILockbox_req_body } from '../interfaces/iLockBox';
import { ILockbox_options } from '../interfaces/ICorrespondence';
import { FieldTextInput, getExceptionSetup, CustomDropdownField, CorpCodeField, CreateExceptionSetupTextInput, CreateExceptionSetupCustomDropdown, CreateExceptionSetupCorpCode, createExceptionSetup, DefaultAcountFieldTextInput, DefaultAmountFieldTextInput, Select_account_list, Select_amount_list, Cutoff_time_options, TextInputListForAccountAmount } from '../API_Integrations/exceptionSetup';
import ClientHeader from '../components/ClientHeader';
import Loader from '../components/Loader';
import ModalsContainer from '../components/ModalsContainer';
import CustomModal from '../components/CustomModal';
import Table from '../components/Table';
import ExceptionSetupContentInfo from '../components/ExceptionSetupContentInfo';
import { useTitle } from '../utils/use-title';
import { useFocusOnElemnt } from "../utils/use-focus-on-element";

interface SelectLockbox {
    lockboxCode: string
    cutoffTime: string
}

interface FieldCustomWithTable {
    selectVal: string
    labelVal: string
    lengthVal: string
    customFields: CustomDropdownField[]
}

interface FieldCorpCodesWithTable {
    selectVal: string
    labelVal: string
    lengthVal: string
    corpCodesField: CorpCodeField[]
}


function ExceptionSetup() {
    useTitle("Exception setup");
    const [loading, setLoading] = useState(false);
    const [lockboxinfo, setLockboxInfo] = useState<SelectLockbox>({ lockboxCode: '', cutoffTime: '12:00:00' });
    const [lockboxToBeSeleceted, setLockboxToBeSeleceted] = useState('');
    const [lockbox_options, setLockbox_options] = useState<ILockbox_options[]>([]);
    const [showLockboxIdValidationModal, setShowLockboxIdValidationModal] = useState(false);
    const defaultfAcountFieldTextInput: FieldTextInput[] = DefaultAcountFieldTextInput;
    const defaultfAmountFieldTextInput: FieldTextInput[] = DefaultAmountFieldTextInput;
    const [fieldsTextInput, setFieldsTextInput] = useState<{ account: FieldTextInput[], amount: FieldTextInput[] }>({ account: defaultfAcountFieldTextInput, amount: defaultfAmountFieldTextInput });
    const [deletedIdsTextInput, setDeletedIdsTextInput] = useState<{ account: number[], amount: number[] }>({ account: [], amount: [] });
    const [select_account_list_textInput, setSelect_account_list_textInput] = useState<TextInputListForAccountAmount[]>(Select_account_list);
    const [select_amount_list_textInput, setSelect_amount_list_textInput] = useState<TextInputListForAccountAmount[]>(Select_amount_list);
    const [defaultCustomDropdownField, setDefaultCustomDropdownField] = useState<CustomDropdownField>({ id: 0, fieldName: '', fieldDescription: '' });
    const [fieldsCustomDropdown, setFieldsCustomDropdown] = useState<FieldCustomWithTable>({ selectVal: '', labelVal: '', lengthVal: '', customFields: [] });
    const [defaultCorpCodeField, setDefaultCorpCodeField] = useState<CorpCodeField>({ id: 0, corpCode: '', corpCodeLabel: '' });
    const [fieldsCorpCode, setFieldsCorpCode] = useState<FieldCorpCodesWithTable>({ selectVal: '', labelVal: '', lengthVal: '', corpCodesField: [] });
    const [modalTextInputCustomField, setModalTextInputCustomField] = useState({ account: false, amount: false });
    const [modalCustomDropdownLabel, setModalCustomDropdownLabel] = useState(false);
    const [modalCustomDropdownAccountSelection, setModalCustomDropdownAccountSelection] = useState(false);
    const [modalCorpCodeLabel, setModalCorpCodeLabel] = useState(false);
    const [modalCorpCodeAccountSelection, setModalCorpCodeAccountSelection] = useState(false);
    const [modalAddCustomDropdown, setModalAddCustomDropdown] = useState({ idField: 0, valField: '' });
    const [modalAddCorpCode, setModalAddCorpCode] = useState({ idField: 0, valField: '' });
    const [modalDeleteCustomDropdown, setModalDeleteCustomDropdown] = useState({ idField: 0, valField: '' });
    const [modalDeleteCorpCode, setModalDeleteCorpCode] = useState({ idField: 0, valField: '' });
    const [modalSelectLockbox, setModalSelectLockbox] = useState(false);
    const [isValidLength, setIsValidLength] = useState<{ textInput: { account: { [key: number]: boolean }, amount: { [key: number]: boolean } }, customDropDown: boolean, corpCodes: boolean }>({ textInput: { account: {}, amount: {} }, customDropDown: true, corpCodes: true });
    const [isValidCustomDropdown, setIsValidCustomDropdown] = useState({ fieldEmpty: false, descriptionEmpty: false, bothFields: false, alphaNumeric: false });
    const [loadingExceptions, setLoadingExceptions] = useState(false);
    const [modalExceptionSetupSaved, setModalExceptionSetupSaved] = useState(false);
    const [isValidCorpCode, setIsValidCorpCode] = useState({ fieldEmpty: false, descriptionEmpty: false, bothFields: false, alphaNumeric: false });
    const [saveErrorModalOpen, setSaveErrorModalOpen] = useState(false);
    const [selectedLockboxId, setSelectedLockboxId] = useRecoilState(exception_setup_lockboxId);
    const [unsavedChanges, setUnsavedChanges] = useRecoilState(exception_setup_unsaved_changes);
    const initialTextRef = useRef<HTMLHeadingElement | null>(null);
    useFocusOnElemnt(initialTextRef);

    const lockbox_list = [
        {
            isDisabled: true,
            label: 'Select',
            value: ''
        },
    ];
    const select_account_list = Select_account_list;

    const cutoff_time_options = Cutoff_time_options;

    const columnDataCustomDropdown = [
        { headerId: 'fieldName', headerLabel: 'Custom field ' },
        { headerId: 'fieldDescription', headerLabel: 'Custom description ' },
        { headerId: 'Action', headerLabel: 'Action ', rowCellTableRowComponentsArrayIndex: 0, type: 'component', disableSorting: true },
    ];

    const columnDataCorpCodes = [
        { headerId: 'corpCode', headerLabel: 'Corp Code ' },
        { headerId: 'corpCodeLabel', headerLabel: 'Corp Code label ' },
        { headerId: 'Action', headerLabel: 'Action ', rowCellTableRowComponentsArrayIndex: 0, type: 'component', disableSorting: true },
    ];

    const alphaNumericRegExp = /^([a-zA-Z0-9_-]){1,8}$/;

    useEffect(() => {
        const fetchData = async () => {
            const result = await getLockboxData({
                lockboxNumber: '',
                lockboxName: ''
            });
            getLockboxOptions(result);
        }
        fetchData();
    }, []);

    useEffect(() => {
    }, [fieldsTextInput]);

    const getLockboxData = async (ReqBody: ILockbox_req_body) => {
        setLoading(true);
        let result = await Lockbox.listClientLockbox(ReqBody)
        if (result !== undefined) {
            return result;
        } else {
            console.log('item not found')
        }
    }

    const getLockboxOptions = (options: ILockbox[]) => {
        let LockboxOptions = options.map(lockbox => ({ label: lockbox.code, value: lockbox.code }));
        if (selectedLockboxId !== '' && LockboxOptions.length > 1) {
            const selected_lockbox = selectedLockboxId;
            setLockboxInfo({ ...lockboxinfo, lockboxCode: selected_lockbox });
            getExceptionSetupAPI(selected_lockbox);
        }
        if (LockboxOptions.length === 1) {
            setLoadingExceptions(true);
            setLockbox_options([...LockboxOptions]);
            setLockboxInfo({ ...lockboxinfo, lockboxCode: LockboxOptions[0].value });
            const lockboxCode = LockboxOptions[0].value;
            setSelectedLockboxId(lockboxCode);
            getExceptionSetupAPI(lockboxCode);
        } else {
            setLockbox_options([...lockbox_list, ...LockboxOptions]);
        }
        setLoading(false);
        return LockboxOptions;
    }
    const getExceptionSetupAPI = async (lockboxCode: string) => {
        const LockboxCode = lockboxCode;
        try {
            setLoadingExceptions(true);
            await getExceptionSetup(LockboxCode).then(res => {
                setLockboxInfo({ lockboxCode: res.lockboxCode, cutoffTime: res.cutoffTime !== undefined ? res.cutoffTime : '12:00:00' })
                let defaultfAcountFieldTextInput: FieldTextInput[] = DefaultAcountFieldTextInput;
                if (res.accountFieldName !== undefined && res.accountFieldSelection !== undefined) {
                    defaultfAcountFieldTextInput = [{
                        id: 1,
                        selectVal: String(res.accountFieldSelection),
                        inputVal: res.accountFieldName!,
                        lengthVal: String(res.accountFieldLength)
                    }];
                }
                const additionalAccountFields = Array.isArray(res.customAccountFields) && res.customAccountFields.length > 0 ? res.customAccountFields?.map((account, i) => ({
                    id: i + 2,
                    selectVal: String(account.fieldSelection),
                    inputVal: account.fieldName!,
                    lengthVal: String(account.fieldLength)
                })) : [];

                if (res.customAccountFields && res.customAccountFields.length === 0) {
                    const updatedAccoutSelections = select_account_list_textInput.map(item => {
                        if (item.value === '1') {
                            return { ...item, isDisabled: true }
                        } else {
                            return { ...item, isDisabled: false }
                        }
                    });
                    setSelect_account_list_textInput(updatedAccoutSelections);
                }

                if (res.accountFieldSelection !== undefined || (res.customAccountFields && res.customAccountFields.length > 0)) {
                    const selectionsToCheck = [...(res.accountFieldSelection ? [res.accountFieldSelection] : []),
                    ...(res.customAccountFields ? res.customAccountFields.map(field => field.fieldSelection) : [])
                    ];
                    const updatedAccoutSelections = select_account_list_textInput.map(item => {
                        if (selectionsToCheck.includes(Number(item.value))) {
                            return { ...item, isDisabled: true }
                        }
                        return item;
                    });

                    setSelect_account_list_textInput(updatedAccoutSelections);
                }
                const AccountFields = [...defaultfAcountFieldTextInput, ...additionalAccountFields];
                let defaultfAmountFieldTextInput: FieldTextInput[] = DefaultAmountFieldTextInput;
                if (res.amountFieldName !== undefined && res.amountFieldSelection !== undefined) {
                    defaultfAmountFieldTextInput = [{
                        id: 1,
                        selectVal: String(res.amountFieldSelection),
                        inputVal: res.amountFieldName!,
                        lengthVal: String(res.amountFieldLength)
                    }];
                }
                const additionalAmountFields = Array.isArray(res.customAmountFields) && res.customAmountFields.length > 0 ? res.customAmountFields?.map((amount, i) => ({
                    id: i + 2,
                    selectVal: String(amount.fieldSelection),
                    inputVal: amount.fieldName!,
                    lengthVal: String(amount.fieldLength)
                })) : [];
                if (res.customAmountFields && res.customAmountFields.length === 0) {
                    const updatedAmoutSelections = select_amount_list_textInput.map(item => {
                        if (item.value === '1') {
                            return { ...item, isDisabled: true }
                        } else {
                            return { ...item, isDisabled: false }
                        }
                    });
                    setSelect_amount_list_textInput(updatedAmoutSelections);
                }
                if (res.amountFieldSelection !== undefined || (res.customAmountFields && res.customAmountFields.length > 0)) {
                    const selectionsToCheck = [...(res.amountFieldSelection ? [res.amountFieldSelection] : []),
                    ...(res.customAmountFields ? res.customAmountFields.map(field => field.fieldSelection) : [])
                    ];
                    const updatedAmoutSelections = select_amount_list_textInput.map(item => {
                        if (selectionsToCheck.includes(Number(item.value))) {
                            return { ...item, isDisabled: true }
                        }
                        return item;
                    });

                    setSelect_amount_list_textInput(updatedAmoutSelections);
                }
                const AmountFields = [...defaultfAmountFieldTextInput, ...additionalAmountFields];
                const customTextInput: { account: FieldTextInput[], amount: FieldTextInput[] } = { account: AccountFields, amount: AmountFields }

                let validLengthAccount: { [key: number]: boolean } = {};
                let validLengthAmount: { [key: number]: boolean } = {};
                AccountFields.forEach((account) => {
                    validLengthAccount[account.id] = account.lengthVal === undefined || !isNaN(Number(account.lengthVal))
                });
                AmountFields.forEach((amount) => {
                    validLengthAmount[amount.id] = amount.lengthVal === undefined || !isNaN(Number(amount.lengthVal))
                });

                setFieldsTextInput(customTextInput);

                const storedCustomTableItems = sessionStorage.getItem('CustomTableItems');
                let parsedDataCustomTableItems: CustomDropdownField[] = [];
                if (storedCustomTableItems) {
                    parsedDataCustomTableItems = JSON.parse(storedCustomTableItems);
                }
                const storedCorpCodeTableItems = sessionStorage.getItem('CorpCodeTableItems');
                let parsedDataCorpcodeTableItems: CorpCodeField[] = [];
                if (storedCorpCodeTableItems) {
                    parsedDataCorpcodeTableItems = JSON.parse(storedCorpCodeTableItems);
                }
                const ResCustomDropdown = Array.isArray(res.customDropdownFields) && res.customDropdownFields.length > 0 ? res.customDropdownFields?.map((field) => ({
                    id: field.id,
                    fieldName: field.fieldName,
                    fieldDescription: field.fieldDescription
                })) : [];
                let combinedCustomDropdownFields;
                if (parsedDataCustomTableItems.length === 0) {
                    combinedCustomDropdownFields = ResCustomDropdown;
                } else if (ResCustomDropdown.length === 0) {
                    combinedCustomDropdownFields = parsedDataCustomTableItems;
                } else {
                    combinedCustomDropdownFields = [...parsedDataCustomTableItems, ...ResCustomDropdown.filter(resItem =>
                        !parsedDataCustomTableItems.some(parsedItem => parsedItem.id === resItem.id)
                    )]
                }
                let customDropdown = { selectVal: res.customDropdownAccountField !== undefined ? String(res.customDropdownAccountField) : '', labelVal: res.customDropdownLabel !== undefined ? res.customDropdownLabel : '', lengthVal: res.customDropdownLength !== undefined ? String(res.customDropdownLength) : '', customFields: combinedCustomDropdownFields }
                setFieldsCustomDropdown(customDropdown);

                const ResCorpCode = Array.isArray(res.corpCodes) && res.corpCodes.length > 0 ? res.corpCodes?.map((field) => ({
                    id: field.id,
                    corpCode: field.corpCode,
                    corpCodeLabel: field.corpCodeLabel
                })) : [];
                let combinedCorpCodesFields;
                if (parsedDataCorpcodeTableItems.length === 0) {
                    combinedCorpCodesFields = ResCorpCode;
                } else if (ResCorpCode.length === 0) {
                    combinedCorpCodesFields = parsedDataCorpcodeTableItems;
                } else {
                    combinedCorpCodesFields = [...parsedDataCorpcodeTableItems, ...ResCorpCode.filter(resItem =>
                        !parsedDataCorpcodeTableItems.some(parsedItem => parsedItem.id === resItem.id)
                    )]
                }
                let customcorpcode = { selectVal: res.corpCodeField !== undefined ? String(res.corpCodeField) : '', labelVal: res.corpCodeLabel !== undefined ? res.corpCodeLabel : '', lengthVal: res.corpCodeLength !== undefined ? String(res.corpCodeLength) : '', corpCodesField: combinedCorpCodesFields }
                setFieldsCorpCode(customcorpcode);
                setIsValidLength({ textInput: { account: validLengthAccount, amount: validLengthAmount }, customDropDown: res.customDropdownLength === undefined ? true : !isNaN(res.customDropdownLength), corpCodes: res.corpCodeLength === undefined ? true : !isNaN(res.corpCodeLength) })
            });
        } catch (error) {
            console.log('error lockbox API', error);
            setSaveErrorModalOpen(true);
        } finally {
            setLoadingExceptions(false)
        }
    }

    const handleOnChangeSelectLockboxId = (event: Event) => {
        setLockboxToBeSeleceted((event.target as HTMLInputElement).value);
        if (lockboxinfo.lockboxCode !== '' && (unsavedChanges.corp_code || unsavedChanges.custom_dropdown)) {
            setModalSelectLockbox(true);
        } else {
            const lockboxCode = (event.target as HTMLInputElement).value;
            setLockboxInfo({ ...lockboxinfo, lockboxCode: (event.target as HTMLInputElement).value });
            setSelectedLockboxId(lockboxCode);
            getExceptionSetupAPI(lockboxCode);
        }
    }

    const ConfirmSelectLockboxId = () => {
        setModalSelectLockbox(false);
        const lockboxCode = lockboxToBeSeleceted;

        setSelectedLockboxId(lockboxCode);
        setLockboxInfo({ ...lockboxinfo, lockboxCode: lockboxToBeSeleceted });
        setUnsavedChanges({custom_dropdown: false, corp_code: false});
        sessionStorage.removeItem('CustomTableItems');
        sessionStorage.removeItem('CorpCodeTableItems');
        getExceptionSetupAPI(lockboxCode);
    }

    const HandleRefresh = () => {
        getExceptionSetupAPI(lockboxinfo.lockboxCode);
        setModalExceptionSetupSaved(false);
    }

    const handleOnChangeCutoffTime = (event: Event) => { setLockboxInfo({ ...lockboxinfo, cutoffTime: (event.target as HTMLInputElement).value }) }

    const HandleSaveTextInput = async () => {
        if (!lockboxinfo.lockboxCode) {
            setShowLockboxIdValidationModal(true);
            return;
        }

        const emptyCustomAccountField = fieldsTextInput.account.some(field => field.inputVal.trim() === '' || field.selectVal === '');
        if (emptyCustomAccountField) {
            setModalTextInputCustomField({ account: true, amount: false });
            return;
        }
        const emptyCustomAmountField = fieldsTextInput.amount.some(field => field.inputVal.trim() === '' || field.selectVal === '');
        if (emptyCustomAmountField) {
            setModalTextInputCustomField({ account: false, amount: true });
            return;
        }

        fieldsTextInput.account.forEach(field => {
            const valid = Number(field.lengthVal) >= 1 && Number(field.lengthVal) <= 40 && !isNaN(Number(field.lengthVal)) && field.lengthVal.trim() !== '';
            if (!valid) {

                setIsValidLength(prevState => ({
                    ...prevState, textInput: { account: { ...prevState.textInput.account, [field.id]: false }, amount: { ...prevState.textInput.amount } }
                }));
            }
        });
        const accountInValidLength = fieldsTextInput.account.some((field) => {
            return field.lengthVal === undefined || Number(field.lengthVal) < 1 || Number(field.lengthVal) > 40 || isNaN(Number(field.lengthVal)) || field.lengthVal.trim() === '';
        })
        fieldsTextInput.amount.forEach(field => {
            const valid = Number(field.lengthVal) >= 1 && Number(field.lengthVal) <= 40 && !isNaN(Number(field.lengthVal)) && field.lengthVal.trim() !== '';
            if (!valid) {

                setIsValidLength(prevState => ({
                    ...prevState, textInput: { account: { ...prevState.textInput.account }, amount: { ...prevState.textInput.amount, [field.id]: false } }
                }));
            }
        });
        const amountInValidLength = fieldsTextInput.amount.some((field) => {

            return field.lengthVal === undefined || Number(field.lengthVal) < 1 || Number(field.lengthVal) > 40 || isNaN(Number(field.lengthVal)) || field.lengthVal.trim() === '';
        });
        if (accountInValidLength || amountInValidLength) {
            return;
        } else {
            let validLengthAccount: { [key: number]: boolean } = {};
            let validLengthAmount: { [key: number]: boolean } = {};
            fieldsTextInput.account.forEach((account) => {
                validLengthAccount[account.id] = true;
            });
            fieldsTextInput.amount.forEach((amount) => {
                validLengthAmount[amount.id] = true;
            });

            setIsValidLength(prevState => (
                { ...prevState, textInput: { account: validLengthAccount, amount: validLengthAmount } }
            ));
        }

        const reqBody: CreateExceptionSetupTextInput = {
            lockboxCode: lockboxinfo.lockboxCode,
            cutoffTime: lockboxinfo.cutoffTime,
            accountFieldName: fieldsTextInput.account[0].inputVal,
            accountFieldSelection: Number(fieldsTextInput.account[0].selectVal),
            accountFieldLength: Number(fieldsTextInput.account[0].lengthVal),
            customAccountFields: fieldsTextInput.account.slice(1).map(account => ({ fieldName: account.inputVal, fieldSelection: Number(account.selectVal), fieldLength: Number(account.lengthVal) })),
            amountFieldName: fieldsTextInput.amount[0].inputVal,
            amountFieldSelection: Number(fieldsTextInput.amount[0].selectVal),
            amountFieldLength: Number(fieldsTextInput.amount[0].lengthVal),
            customAmountFields: fieldsTextInput.amount.slice(1).map(amount => ({ fieldName: amount.inputVal, fieldSelection: Number(amount.selectVal), fieldLength: Number(amount.lengthVal) })),
        }

        try {
            setLoadingExceptions(true);
            await createExceptionSetup.textInput(reqBody);
            setModalExceptionSetupSaved(true);

        } catch (error) {
            console.log('error lockbox API', error);
            setSaveErrorModalOpen(true);
        } finally {
            setLoadingExceptions(false);
        }

    }

    const HandleSaveCustomDropdown = async () => {
        if (!lockboxinfo.lockboxCode) {
            setShowLockboxIdValidationModal(true);
            return;
        }

        const emptyCustomField = fieldsCustomDropdown.labelVal.trim() === '';
        if (emptyCustomField) {
            setModalCustomDropdownLabel(true);
            return;
        }

        const emptySelectionField = fieldsCustomDropdown.selectVal === '';
        if (emptySelectionField) {
            setModalCustomDropdownAccountSelection(true);
            return;
        }

        const validLength = Number(fieldsCustomDropdown.lengthVal) >= 1 && Number(fieldsCustomDropdown.lengthVal) <= 40 && !isNaN(Number(fieldsCustomDropdown.lengthVal)) && fieldsCustomDropdown.lengthVal.trim() !== '';
        if (!validLength) {
            setIsValidLength(prevState => (
                { ...prevState, customDropDown: false }
            ));
            return;
        } else {
            setIsValidLength(prevState => (
                { ...prevState, customDropDown: true }
            ));
        }

        const reqBody: CreateExceptionSetupCustomDropdown = {
            lockboxCode: lockboxinfo.lockboxCode,
            cutoffTime: lockboxinfo.cutoffTime,
            customDropdownLabel: fieldsCustomDropdown.labelVal,
            customDropdownAccountField: Number(fieldsCustomDropdown.selectVal),
            customDropdownLength: Number(fieldsCustomDropdown.lengthVal),
            customDropdownFields: fieldsCustomDropdown.customFields?.map(field => ({ fieldName: field.fieldName, fieldDescription: field.fieldDescription }))
        }

        try {
            setLoadingExceptions(true);
            await createExceptionSetup.customDropdown(reqBody);
            sessionStorage.removeItem('CustomTableItems');
            setModalExceptionSetupSaved(true);
        } catch (error) {
            console.log('error lockbox API', error)
            setSaveErrorModalOpen(true);
        } finally {
            setLoadingExceptions(false);
        }
    }

    const HandleSaveCorpCodes = async () => {
        if (!lockboxinfo.lockboxCode) {
            setShowLockboxIdValidationModal(true);
            return;
        }

        const emptyCorpCodeLabel = fieldsCorpCode.labelVal.trim() === '';
        if (emptyCorpCodeLabel) {
            setModalCorpCodeLabel(true);
            return;
        }

        const validLength = Number(fieldsCorpCode.lengthVal) >= 1 && Number(fieldsCorpCode.lengthVal) <= 40 && !isNaN(Number(fieldsCorpCode.lengthVal)) && fieldsCorpCode.lengthVal.trim() !== '';
        if (!validLength) {
            setIsValidLength(prevState => (
                { ...prevState, corpCodes: false }
            ));
            return;
        } else {
            setIsValidLength(prevState => (
                { ...prevState, corpCodes: true }
            ));
        }


        const emptySelectionField = fieldsCorpCode.selectVal === '';
        if (emptySelectionField) {
            setModalCorpCodeAccountSelection(true);
            return;
        }

        const reqBody: CreateExceptionSetupCorpCode = {
            lockboxCode: lockboxinfo.lockboxCode,
            cutoffTime: lockboxinfo.cutoffTime,
            corpCodeLabel: fieldsCorpCode.labelVal,
            corpCodeField: Number(fieldsCorpCode.selectVal),
            corpCodeLength: Number(fieldsCorpCode.lengthVal),
            corpCodes: fieldsCorpCode.corpCodesField?.map(field => ({ corpCode: field.corpCode, corpCodeLabel: field.corpCodeLabel }))
        }

        try {
            setLoadingExceptions(true);
            await createExceptionSetup.corpCodes(reqBody);
            sessionStorage.removeItem('CorpCodeTableItems');
            setModalExceptionSetupSaved(true);
        } catch (error) {
            console.log('error lockbox API', error)
            setSaveErrorModalOpen(true);
        } finally {
            setLoadingExceptions(false);
        }
    }

    const HandleOnChangeTextInputAccountLabelVal = (event: Event, id: number, list: 'account' | 'amount') => {
        const currentFields = fieldsTextInput[list];
        const updatedVal = currentFields.map(field => {
            if (field.id === id) {
                return { ...field, inputVal: (event.target as HTMLInputElement).value }
            }
            return field;
        });
        setFieldsTextInput(prevState => ({
            ...prevState, [list]: updatedVal
        }));
    }

    const HandleOnChangeTextInputSelectVal = (event: Event, id: number, list: 'account' | 'amount') => {
        const currentFields = fieldsTextInput[list];
        const updatedVal = currentFields.map(field => {
            if (field.id === id) {
                return { ...field, selectVal: (event.target as HTMLInputElement).value }
            }
            return field;
        });
        setFieldsTextInput(prevState => ({
            ...prevState, [list]: updatedVal
        }));
        const type = list;
        if (type === 'account') {
            const account_list = select_account_list_textInput;
            const allSelections = updatedVal.map((field) => field.selectVal);
            const updatedSelections = account_list.map((item) => ({ ...item, isDisabled: allSelections.includes(item.value) }))
            setSelect_account_list_textInput(updatedSelections);
        } else if (type === 'amount') {
            const amount_list = select_amount_list_textInput;
            const allSelections = updatedVal.map((field) => field.selectVal);
            const updatedSelections = amount_list.map((item) => ({ ...item, isDisabled: allSelections.includes(item.value) }))
            setSelect_amount_list_textInput(updatedSelections);
        }
    }

    const HandleOnChangeTextInputLengthVal = (event: Event, id: number, list: 'account' | 'amount') => {
        const currentFields = fieldsTextInput[list];
        const updatedVal = currentFields.map(field => {
            if (field.id === id) {
                return { ...field, lengthVal: (event.target as HTMLInputElement).value }
            }
            return field;
        });
        setFieldsTextInput(prevState => ({
            ...prevState, [list]: updatedVal
        }));
    }

    const onClickDeleteTextInputField = (id: number, list: 'account' | 'amount') => {
        const currentFields = fieldsTextInput[list];
        if (currentFields.length === 1) {
            return;
        }
        const indexDeleted = deletedIdsTextInput[list].indexOf(id);
        if (indexDeleted === -1) {
            const sortedDeletedIds = [...deletedIdsTextInput[list], id].sort((a, b) => a - b);
            setDeletedIdsTextInput(prevState => ({
                ...prevState,
                [list]: sortedDeletedIds
            }));
        }

        const updatedVal = currentFields.filter(field => field.id !== id);
        setFieldsTextInput(prevState => ({
            ...prevState, [list]: updatedVal
        }));

        const deletedField = currentFields.filter(field => field.id === id);
        const type = list;
        if (deletedField.length > 0) {
            if (type === 'account') {
                const account_list = select_account_list_textInput;
                const allSelections = updatedVal.map((field) => field.selectVal);
                const updatedSelections = account_list.map((item) => ({ ...item, isDisabled: allSelections.includes(item.value) }));
                setSelect_account_list_textInput(updatedSelections);

            } else if (type === 'amount') {
                const amount_list = select_amount_list_textInput;
                const allSelections = updatedVal.map((field) => field.selectVal);
                const updatedSelections = amount_list.map((item) => ({ ...item, isDisabled: allSelections.includes(item.value) }));
                setSelect_amount_list_textInput(updatedSelections);
            }
        }
    }

    const onClickAddTextInputField = (list: 'account' | 'amount') => {
        const currentFields = fieldsTextInput[list];
        const availableDeletedIds = deletedIdsTextInput[list];

        if (availableDeletedIds.length > 0) {
            const newFieldId = availableDeletedIds[0];
            const newTextInputField: FieldTextInput = { id: newFieldId, inputVal: '', selectVal: '', lengthVal: '' }
            setFieldsTextInput(prevState => ({
                ...prevState, [list]: [...currentFields, newTextInputField]
            }));
            setDeletedIdsTextInput(prevState => ({
                ...prevState,
                [list]: prevState[list].filter(id => id !== newFieldId)
            }));
        } else {
            const nextId = currentFields.length === 0 ? 1 : Math.max(...currentFields.map(field => field.id)) + 1;
            const newTextInputField: FieldTextInput = { id: nextId, inputVal: '', selectVal: '', lengthVal: '' }
            setFieldsTextInput(prevState => ({
                ...prevState, [list]: [...currentFields, newTextInputField]
            }));
        }

    }

    const HandleOnChangeLabelVal = (event: Event, list: 'custom-dropdown' | 'corp-code') => {
        if (list === 'custom-dropdown') {
            setFieldsCustomDropdown(prevState => ({
                ...prevState, labelVal: (event.target as HTMLInputElement).value
            }));
        } else {
            setFieldsCorpCode(prevState => ({
                ...prevState, labelVal: (event.target as HTMLInputElement).value
            }));
        }
    }

    const HandleOnChangeSelectAccountVal = (event: Event, list: 'custom-dropdown' | 'corp-code') => {
        if (list === 'custom-dropdown') {
            setFieldsCustomDropdown(prevState => ({
                ...prevState, selectVal: (event.target as HTMLInputElement).value
            }));
        } else {
            setFieldsCorpCode(prevState => ({
                ...prevState, selectVal: (event.target as HTMLInputElement).value
            }));
        }

    }

    const HandleOnChangeLengthVal = (event: Event, list: 'custom-dropdown' | 'corp-code') => {
        if (list === 'custom-dropdown') {
            setFieldsCustomDropdown(prevState => ({
                ...prevState, lengthVal: (event.target as HTMLInputElement).value
            }));
        } else {
            setFieldsCorpCode(prevState => ({
                ...prevState, lengthVal: (event.target as HTMLInputElement).value
            }));
        }
    }

    const HandleOnChangeCustomFieldVal = (event: Event, list: 'custom-dropdown' | 'corp-code') => {
        if (list === 'custom-dropdown') {
            setDefaultCustomDropdownField(prevState => ({
                ...prevState, fieldName: (event.target as HTMLInputElement).value
            }));
        } else {
            setDefaultCorpCodeField(prevState => ({
                ...prevState, corpCode: (event.target as HTMLInputElement).value
            }));
        }

    }

    const HandleOnChangeCustomDescriptionVal = (event: Event, list: 'custom-dropdown' | 'corp-code') => {
        if (list === 'custom-dropdown') {
            setDefaultCustomDropdownField(prevState => ({
                ...prevState, fieldDescription: (event.target as HTMLInputElement).value
            }));
        } else {
            setDefaultCorpCodeField(prevState => ({
                ...prevState, corpCodeLabel: (event.target as HTMLInputElement).value
            }));
        }
    }

    const onClickDeleteCustomDropdownField = (id: number, fieldVal: string) => {
        setModalDeleteCustomDropdown({ idField: id, valField: fieldVal });
    }

    const onClickConfirmDeleteCustomDropdownField = () => {
        const id = modalDeleteCustomDropdown.idField;
        const updatedFields = fieldsCustomDropdown.customFields.filter(field => field.id !== id);
        setFieldsCustomDropdown(prevState => ({
            ...prevState, customFields: updatedFields
        }));
        setModalDeleteCustomDropdown({ idField: 0, valField: '' });
        setUnsavedChanges(prevState => ({
            ...prevState, custom_dropdown: true
        }));
        sessionStorage.setItem('CustomTableItems', JSON.stringify(updatedFields));
    }

    const onClickAddCustomDropdownField = () => {
        const currentFields = fieldsCustomDropdown.customFields;
        const nextId = parseInt(uuidv4().replace(/-/g, ''), 16);
        if (defaultCustomDropdownField.fieldName.trim() === '' && defaultCustomDropdownField.fieldDescription.trim() === '') {
            setIsValidCustomDropdown({ fieldEmpty: false, descriptionEmpty: false, bothFields: true, alphaNumeric: false });
            return;
        }
        if (defaultCustomDropdownField.fieldName.trim() === '' || defaultCustomDropdownField.fieldDescription.trim() === '') {
            setIsValidCustomDropdown({ fieldEmpty: defaultCustomDropdownField.fieldName.trim() === '', descriptionEmpty: defaultCustomDropdownField.fieldDescription.trim() === '', bothFields: false, alphaNumeric: false });
            return;
        }
        if (!alphaNumericRegExp.test(defaultCustomDropdownField.fieldName)) {
            setIsValidCustomDropdown({ fieldEmpty: false, descriptionEmpty: false, bothFields: false, alphaNumeric: true });
            return;
        }

        const newCustomDropdownField: CustomDropdownField = { id: nextId, fieldName: defaultCustomDropdownField.fieldName, fieldDescription: defaultCustomDropdownField.fieldDescription }
        setFieldsCustomDropdown(prevState => ({
            ...prevState, customFields: [newCustomDropdownField, ...currentFields]
        }));
        setModalAddCustomDropdown({ idField: 1, valField: defaultCustomDropdownField.fieldName });
        setUnsavedChanges(prevState => ({
            ...prevState, custom_dropdown: true
        }));
        sessionStorage.setItem('CustomTableItems', JSON.stringify([...currentFields, newCustomDropdownField]));

    }

    const onClickDeleteCorpCodesField = (id: number, fieldVal: string) => {
        setModalDeleteCorpCode({ idField: id, valField: fieldVal });
    }

    const onClickConfirmDeleteCorpCodesField = () => {
        const id = modalDeleteCorpCode.idField;
        const updatedFields = fieldsCorpCode.corpCodesField.filter(field => field.id !== id);
        setFieldsCorpCode(prevState => ({
            ...prevState, corpCodesField: updatedFields
        }));
        setModalDeleteCorpCode({ idField: 0, valField: '' });
        setUnsavedChanges(prevState => ({
            ...prevState, corp_code: true
        }));
        sessionStorage.setItem('CorpCodeTableItems', JSON.stringify(updatedFields));
    }

    const onClickAddCorpCodesField = () => {
        const currentFields = fieldsCorpCode.corpCodesField;
        const nextId = parseInt(uuidv4().replace(/-/g, ''), 16);
        if (defaultCorpCodeField.corpCode.trim() === '' && defaultCorpCodeField.corpCodeLabel.trim() === '') {
            setIsValidCorpCode({ fieldEmpty: false, descriptionEmpty: false, bothFields: true, alphaNumeric: false });
            return;
        }
        if (defaultCorpCodeField.corpCode.trim() === '' || defaultCorpCodeField.corpCodeLabel.trim() === '') {
            setIsValidCorpCode({ fieldEmpty: defaultCorpCodeField.corpCode.trim() === '', descriptionEmpty: defaultCorpCodeField.corpCodeLabel.trim() === '', bothFields: false, alphaNumeric: false });
            return;
        }
        if (!alphaNumericRegExp.test(defaultCorpCodeField.corpCode)) {
            setIsValidCorpCode({ fieldEmpty: false, descriptionEmpty: false, bothFields: false, alphaNumeric: true });
            return;
        }

        const newCorpCodeField: CorpCodeField = { id: nextId, corpCode: defaultCorpCodeField.corpCode, corpCodeLabel: defaultCorpCodeField.corpCodeLabel }
        setFieldsCorpCode(prevState => ({
            ...prevState, corpCodesField: [newCorpCodeField, ...currentFields]
        }));
        setModalAddCorpCode({ idField: 1, valField: defaultCorpCodeField.corpCode });
        setUnsavedChanges(prevState => ({
            ...prevState, corp_code: true
        }));
        sessionStorage.setItem('CorpCodeTableItems', JSON.stringify([...currentFields, newCorpCodeField]));

    }


    return (
        <main>
            <ClientHeader showClientName={true} showBreadcrumbs={false} />
            <h1 ref={initialTextRef} tabIndex={-1} className='exception-setup-title heading-focus-text'>Exception setup</h1>
            {!loading ?
                <div className='exception-setup-gap'>
                    <div className='bottom-gap-10'>
                        <p className="section-title">Lockbox information</p>
                        <p className="info-text__20">Changing the Lockbox ID will change the information being presented.</p>
                    </div>
                    <div className="lockbox-search-control">
                        <Select
                            handleOnChange={handleOnChangeSelectLockboxId}
                            value={lockboxinfo.lockboxCode}
                            inputId='select-lockbox-id'
                            label='Select your Lockbox ID (required)'
                            name='select-lockbox-id'
                            options={lockbox_options}
                            size='small'
                            classes='width-322 top-gap-8'
                        />
                        <Select
                            handleOnChange={handleOnChangeCutoffTime}
                            value={lockboxinfo.cutoffTime}
                            inputId='select-cutoff-time'
                            label="Cutoff time for this Lockbox"
                            name='select-cutoff-time'
                            labelPopoverContent="24 hour (no half hours, example 13 for 1:00 PM). Only applies to Lockbox selected in dropdown menu."
                            labelPopoverIcon="IconHelpCircle"
                            options={cutoff_time_options}
                            size='small'
                            classes='width-242 left-24-gap-filters'
                        />
                    </div>
                    <div className="light-divider "></div>
                    {!loadingExceptions ?
                        <div className='exception-tabs'>
                            <Tabs spreadTabs={false}>
                                <TabList>
                                    <Tab>
                                        Text input
                                    </Tab>
                                    <Tab>
                                        Custom dropdown
                                    </Tab>
                                    <Tab>
                                        Corp Codes
                                    </Tab>
                                </TabList>
                                <TabPanel>
                                    <div className='tab-panel-inner-contents'>
                                        <ExceptionSetupContentInfo title='Custom field labels for account numbers' info='You can add up to 8 custom account fields.' />
                                        <div>
                                            {fieldsTextInput.account.sort(({ id: a }, { id: b }) => a - b).map(fieldTextInput => (
                                                <div key={`text-input-account-field-${fieldTextInput.id}`} className='exception-setup-section'>
                                                    <div className='tabs-content-input-labels'>
                                                        <TextInput
                                                            label={fieldTextInput.id === 1 ? 'Account field' : `Custom account field ${fieldTextInput.id - 1}`}
                                                            initialValue={fieldTextInput.inputVal}
                                                            size='small'
                                                            handleOnChange={(e: Event) => HandleOnChangeTextInputAccountLabelVal(e, fieldTextInput.id, 'account')}
                                                            type="text"
                                                            maxLength={40}
                                                            labelPopoverContent="Account field can be up to 40 characters."
                                                            labelPopoverIcon="IconHelpCircle"
                                                            classes='width-412'
                                                        />
                                                    </div>
                                                    <div className='left-24-gap-filters top-gap-8 tabs-content-input-labels'>
                                                        <Select
                                                            handleOnChange={(e: Event) => HandleOnChangeTextInputSelectVal(e, fieldTextInput.id, 'account')}
                                                            inputId={`select-textInput-account-${fieldTextInput.id}`}
                                                            label='Select account fields 1-10'
                                                            options={select_account_list_textInput}
                                                            size='small'
                                                            classes='width-322'
                                                            value={fieldTextInput.selectVal}
                                                            name='select-account-fields-textInput'
                                                        />
                                                    </div>
                                                    <div className='left-24-gap-filters top-gap-8 tabs-content-input-labels'>
                                                        <TextInput
                                                            label="Length"
                                                            initialValue={fieldTextInput.lengthVal}
                                                            size='small'
                                                            handleOnChange={(e: Event) => HandleOnChangeTextInputLengthVal(e, fieldTextInput.id, 'account')}
                                                            type="text"
                                                            classes='width-226'
                                                            errorMessages={["Enter a number from 1 to 40."]}
                                                            isValid={isValidLength.textInput.account[fieldTextInput.id]}
                                                        />
                                                    </div>
                                                    {fieldTextInput.id !== 1 && <div className='top-gap-32 left-24-gap-filters top-gap-8'>
                                                        <Button
                                                            clickMethod={() => onClickDeleteTextInputField(fieldTextInput.id, 'account')}
                                                            icon="IconTrash"
                                                            size="medium"
                                                            text="Delete"
                                                            ariaLabel={`Delete custom account field ${fieldTextInput.id - 1} ${fieldTextInput.inputVal}`}
                                                            variant="secondary"
                                                        /></div>}
                                                </div>
                                            ))}
                                            {fieldsTextInput.account.filter(field => field.id !== 1).length <= 8 && <div className='top-gap-32'>
                                                <Button
                                                    clickMethod={() => onClickAddTextInputField('account')}
                                                    text='Add additional account field'
                                                    icon="IconPlus"
                                                    variant="secondary"
                                                    size="small"
                                                /></div>}
                                        </div>
                                        <div className="light-divider "></div>
                                        <ExceptionSetupContentInfo title='Custom field labels for amounts' info='You can add up to 8 custom amount fields.' />
                                        <div>
                                            {fieldsTextInput.amount.sort(({ id: a }, { id: b }) => a - b).map(fieldTextInput => (
                                                <div key={`text-input-account-field-${fieldTextInput.id}`} className='exception-setup-section'>
                                                    <div className='tabs-content-input-labels'>
                                                        <TextInput
                                                            label={fieldTextInput.id === 1 ? 'Amount field' : `Custom amount field ${fieldTextInput.id - 1}`}
                                                            initialValue={fieldTextInput.inputVal}
                                                            size='small'
                                                            handleOnChange={(e: Event) => HandleOnChangeTextInputAccountLabelVal(e, fieldTextInput.id, 'amount')}
                                                            type="text"
                                                            maxLength={40}
                                                            labelPopoverContent="Amount field can be up to 40 characters."
                                                            labelPopoverIcon="IconHelpCircle"
                                                            classes='width-412'
                                                        />
                                                    </div>
                                                    <div className='left-24-gap-filters top-gap-8 tabs-content-input-labels'>
                                                        <Select
                                                            handleOnChange={(e: Event) => HandleOnChangeTextInputSelectVal(e, fieldTextInput.id, 'amount')}
                                                            inputId={`select-textInput-amount-${fieldTextInput.id}`}
                                                            label='Select amount fields 1-10'
                                                            options={select_amount_list_textInput}
                                                            size='small'
                                                            classes='width-322'
                                                            value={fieldTextInput.selectVal}
                                                            name='select-amount-fields-textInput'
                                                        />
                                                    </div>
                                                    <div className='left-24-gap-filters top-gap-8 tabs-content-input-labels'>
                                                        <TextInput
                                                            label="Length"
                                                            initialValue={fieldTextInput.lengthVal}
                                                            size='small'
                                                            handleOnChange={(e: Event) => HandleOnChangeTextInputLengthVal(e, fieldTextInput.id, 'amount')}
                                                            type="text"
                                                            classes='width-226'
                                                            errorMessages={["Enter a number from 1 to 40."]}
                                                            isValid={isValidLength.textInput.amount[fieldTextInput.id]}
                                                        />
                                                    </div>
                                                    {fieldTextInput.id !== 1 && <div className='top-gap-32 left-24-gap-filters top-gap-8'>
                                                        <Button
                                                            clickMethod={() => onClickDeleteTextInputField(fieldTextInput.id, 'amount')}
                                                            icon="IconTrash"
                                                            size="medium"
                                                            text="Delete"
                                                            ariaLabel={`Delete custom amount field ${fieldTextInput.id - 1} ${fieldTextInput.inputVal}`}
                                                            variant="secondary"
                                                        /></div>}
                                                </div>
                                            ))}
                                            {fieldsTextInput.amount.filter(field => field.id !== 1).length <= 8 && <div className='top-gap-32'>
                                                <Button
                                                    clickMethod={() => onClickAddTextInputField('amount')}
                                                    text='Add additional amount field'
                                                    icon="IconPlus"
                                                    variant="secondary"
                                                    size="small"
                                                /></div>}
                                        </div>

                                        <div className="light-divider "></div>

                                        <Button
                                            clickMethod={HandleSaveTextInput}
                                            text='Save text input'
                                            ariaLabel={`Save text input changes for Lockbox ${lockboxinfo.lockboxCode}`}
                                        />
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className='tab-panel-inner-contents'>
                                        <div>
                                            <div className='exception-setup-section'>
                                                <div className='tabs-content-input-labels'>
                                                    <TextInput
                                                        label='Label for custom dropdown'
                                                        initialValue={fieldsCustomDropdown.labelVal}
                                                        size='small'
                                                        handleOnChange={(e: Event) => HandleOnChangeLabelVal(e, 'custom-dropdown')}
                                                        type="text"
                                                        maxLength={40}
                                                        labelPopoverContent='Label for custom dropdown can be up to 40 characters.'
                                                        labelPopoverIcon="IconHelpCircle"
                                                        classes='width-412'
                                                    />
                                                </div>
                                                <div className='left-24-gap-filters top-gap-8 tabs-content-input-labels'>
                                                    <Select
                                                        handleOnChange={(e: Event) => HandleOnChangeSelectAccountVal(e, 'custom-dropdown')}
                                                        inputId={`select-customDropdown-account`}
                                                        label='Select account fields 1-10'
                                                        options={select_account_list}
                                                        size='small'
                                                        classes='width-322'
                                                        value={fieldsCustomDropdown.selectVal}
                                                        name='select-acount-fields-customDropdown'
                                                    />
                                                </div>
                                                <div className='left-24-gap-filters top-gap-8 tabs-content-input-labels'>
                                                    <TextInput
                                                        label="Length"
                                                        initialValue={fieldsCustomDropdown.lengthVal}
                                                        size='small'
                                                        handleOnChange={(e: Event) => HandleOnChangeLengthVal(e, 'custom-dropdown')}
                                                        type="text"
                                                        classes='width-226'
                                                        errorMessages={["Enter a number from 1 to 40."]}
                                                        isValid={isValidLength.customDropDown}
                                                    />
                                                </div>
                                            </div>
                                            <ExceptionSetupContentInfo type='other' title='Manage custom dropdown' info='Add custom selections here' />
                                            <div className='exception-setup-section'>
                                                <div className='tabs-content-input-labels top-gap-8'>
                                                    <TextInput
                                                        label='Custom field'
                                                        initialValue={defaultCustomDropdownField.fieldName}
                                                        size='small'
                                                        handleOnChange={(e: Event) => HandleOnChangeCustomFieldVal(e, 'custom-dropdown')}
                                                        type="text"
                                                        classes='width-124'
                                                    />
                                                </div>
                                                <div className='left-24-gap-filters tabs-content-input-labels'>
                                                    <TextInput
                                                        handleOnChange={(e: Event) => HandleOnChangeCustomDescriptionVal(e, 'custom-dropdown')}
                                                        inputId={`select-customDropdown-account`}
                                                        label='Custom description'
                                                        size='small'
                                                        classes='width-478'
                                                        value={defaultCustomDropdownField.fieldDescription}
                                                        maxLength={40}
                                                        labelPopoverContent="Custom description can be up to 40 characters."
                                                        labelPopoverIcon="IconHelpCircle"
                                                    />
                                                </div>
                                                <div className='top-gap-32 left-24-gap-filters'>
                                                    <Button
                                                        clickMethod={() => onClickAddCustomDropdownField()}
                                                        text='Add custom selection'
                                                        icon="IconPlus"
                                                        size="medium"
                                                        classes="top-gap-8"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {fieldsCustomDropdown.customFields.length > 0 && <div className='exception-setup-content-table'>
                                            <p className='selection-list-title'>This is your list of custom selections.</p>
                                            <Table
                                                caption="This is your list of custom selections"
                                                columnData={columnDataCustomDropdown}
                                                data={fieldsCustomDropdown.customFields}
                                                tableRowComponents={[
                                                    (props: any) => (

                                                        <div className='table-action-buttons'>
                                                            <Button
                                                                clickMethod={() => onClickDeleteCustomDropdownField(props.row.original.id, props.row.original.fieldName)}
                                                                icon="IconTrash"
                                                                size="small"
                                                                text="Delete"
                                                                ariaLabel={`Delete custom field ${props.row.original.fieldName} ${props.row.original.fieldDescription}`}
                                                                variant="text-primary"
                                                            />
                                                        </div>
                                                    ),
                                                ]}
                                            />
                                        </div>}
                                        <div className="light-divider "></div>
                                        <Button
                                            clickMethod={HandleSaveCustomDropdown}
                                            text='Save custom dropdown'
                                            ariaLabel={`Save custom dropdown changes for Lockbox ${lockboxinfo.lockboxCode}`}
                                        />

                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className='tab-panel-inner-contents'>
                                        <div>
                                            <div className='exception-setup-section'>
                                                <div className='tabs-content-input-labels'>
                                                    <TextInput
                                                        label='Corp Code label'
                                                        initialValue={fieldsCorpCode.labelVal}
                                                        size='small'
                                                        handleOnChange={(e: Event) => HandleOnChangeLabelVal(e, 'corp-code')}
                                                        type="text"
                                                        maxLength={40}
                                                        labelPopoverContent='Corp Code label can be up to 40 characters.'
                                                        labelPopoverIcon="IconHelpCircle"
                                                        classes='width-412'
                                                    />
                                                </div>
                                                <div className='left-24-gap-filters top-gap-8 tabs-content-input-labels'>
                                                    <Select
                                                        handleOnChange={(e: Event) => HandleOnChangeSelectAccountVal(e, 'corp-code')}
                                                        inputId={`select-customDropdown-account`}
                                                        label='Select account fields 1-10'
                                                        options={select_account_list}
                                                        size='small'
                                                        classes='width-322'
                                                        value={fieldsCorpCode.selectVal}
                                                        name='select-acount-field-corpCodes'
                                                    />
                                                </div>
                                                <div className='left-24-gap-filters top-gap-8 tabs-content-input-labels'>
                                                    <TextInput
                                                        label="Length"
                                                        initialValue={fieldsCorpCode.lengthVal}
                                                        size='small'
                                                        handleOnChange={(e: Event) => HandleOnChangeLengthVal(e, 'corp-code')}
                                                        type="text"
                                                        classes='width-226'
                                                        errorMessages={["Enter a number from 1 to 40."]}
                                                        isValid={isValidLength.corpCodes}
                                                    />
                                                </div>
                                            </div>
                                            <ExceptionSetupContentInfo type='other' title='Manage Corp Codes' info='Add a Corp Code here' />
                                            <div className='exception-setup-section'>
                                                <div className='tabs-content-input-labels top-gap-8'>
                                                    <TextInput
                                                        label='Corp Code'
                                                        initialValue={defaultCorpCodeField.corpCode}
                                                        size='small'
                                                        handleOnChange={(e: Event) => HandleOnChangeCustomFieldVal(e, 'corp-code')}
                                                        type="text"
                                                        classes='width-124'
                                                    />
                                                </div>
                                                <div className='left-24-gap-filters tabs-content-input-labels'>
                                                    <TextInput
                                                        handleOnChange={(e: Event) => HandleOnChangeCustomDescriptionVal(e, 'corp-code')}
                                                        inputId={`select-corpCode-account`}
                                                        label='Corp Code label'
                                                        size='small'
                                                        classes='width-478'
                                                        maxLength={40}
                                                        labelPopoverContent="Corp Code label can be up to 40 characters."
                                                        labelPopoverIcon="IconHelpCircle"
                                                        value={defaultCorpCodeField.corpCodeLabel}
                                                    />
                                                </div>
                                                <div className='top-gap-32 left-24-gap-filters'>
                                                    <Button
                                                        clickMethod={() => onClickAddCorpCodesField()}
                                                        text='Add Corp Code'
                                                        icon="IconPlus"
                                                        size="medium"
                                                        classes="top-gap-8"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {fieldsCorpCode.corpCodesField.length > 0 && <div className='exception-setup-content-table'>
                                            <p className='selection-list-title'>This is your list of Corp Codes.</p>
                                            <Table
                                                caption="This is your list of Corp Codes"
                                                columnData={columnDataCorpCodes}
                                                data={fieldsCorpCode.corpCodesField}
                                                tableRowComponents={[
                                                    (props: any) => (

                                                        <div className='table-action-buttons'>
                                                            <Button
                                                                clickMethod={() => onClickDeleteCorpCodesField(props.row.original.id, props.row.original.corpCode)}
                                                                icon="IconTrash"
                                                                size="small"
                                                                text="Delete"
                                                                variant="text-primary"
                                                                ariaLabel={`Delete Corp Code ${props.row.original.corpCode} ${props.row.original.corpCodeLabel}`}
                                                            />
                                                        </div>
                                                    ),
                                                ]}
                                            />
                                        </div>}
                                        <div className="light-divider "></div>
                                        <Button
                                            clickMethod={HandleSaveCorpCodes}
                                            text='Save Corp Codes'
                                            ariaLabel={`Save Corp Codes changes for Lockbox ${lockboxinfo.lockboxCode}`}
                                        />
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                        :
                        <Loader />
                    }
                    <ModalsContainer>
                        <CustomModal
                            okType
                            ModalText='Please select a Lockbox ID to manage exception setup.'
                            ModalTitle='Lockbox ID missing'
                            ModalIsOpen={showLockboxIdValidationModal}
                            CloseModal={() => { setShowLockboxIdValidationModal(false) }}
                        />
                        <CustomModal
                            yesOrNoType
                            ModalIsOpen={modalDeleteCustomDropdown.idField}
                            CloseModal={() => { setModalDeleteCustomDropdown({ idField: 0, valField: '' }) }}
                            noText='Cancel' yesText='Yes, remove custom dropdown field'
                            yesClickMethod={onClickConfirmDeleteCustomDropdownField}
                            ModalText='Are you sure you want to remove this custom dropdown selection?'
                            ModalTitle={`Custom dropdown selection ${modalDeleteCustomDropdown.valField} will be removed`}
                        />
                        <CustomModal
                            yesOrNoType
                            ModalIsOpen={modalDeleteCorpCode.idField}
                            CloseModal={() => { setModalDeleteCorpCode({ idField: 0, valField: '' }) }}
                            noText='Cancel' yesText='Yes, remove Corp Code'
                            yesClickMethod={onClickConfirmDeleteCorpCodesField}
                            ModalText='Are you sure you want to remove this Corp Code?'
                            ModalTitle={`Corp Code ${modalDeleteCorpCode.valField} will be removed`}
                        />
                        <CustomModal
                            okType
                            ModalText={`You just added custom dropdown selection ${modalAddCustomDropdown.valField}.`}
                            ModalTitle='Custom dropdown selection added'
                            ModalIsOpen={modalAddCustomDropdown.idField}
                            CloseModal={() => { setModalAddCustomDropdown({ idField: 0, valField: '' }) }}
                        />
                        <CustomModal
                            okType
                            ModalText={`You just added Corp Code ${modalAddCorpCode.valField}.`}
                            ModalTitle='Corp Code added'
                            ModalIsOpen={modalAddCorpCode.idField}
                            CloseModal={() => { setModalAddCorpCode({ idField: 0, valField: '' }) }}
                        />
                        <CustomModal
                            okType
                            ModalText={isValidCustomDropdown.alphaNumeric ? 'Enter a custom dropdown selection up to 8 alphanumeric characters.'
                                : isValidCustomDropdown.bothFields ? 'Please add custom field and description to continue.'
                                    : isValidCustomDropdown.fieldEmpty ? 'Please add custom field information to continue.'
                                        : isValidCustomDropdown.descriptionEmpty ? 'Please add custom description information to continue.'
                                            : ''}
                            ModalTitle={isValidCustomDropdown.alphaNumeric ? 'Custom dropdown selection invalid'
                                : isValidCustomDropdown.bothFields ? 'Custom dropdown missing'
                                    : isValidCustomDropdown.fieldEmpty ? 'Custom field missing'
                                        : isValidCustomDropdown.descriptionEmpty ? 'Custom description missing'
                                            : ''}
                            ModalIsOpen={isValidCustomDropdown.alphaNumeric || isValidCustomDropdown.bothFields || isValidCustomDropdown.descriptionEmpty || isValidCustomDropdown.fieldEmpty}
                            CloseModal={() => { setIsValidCustomDropdown({ fieldEmpty: false, descriptionEmpty: false, bothFields: false, alphaNumeric: false }) }}
                        />
                        <CustomModal
                            okType
                            ModalText={isValidCorpCode.alphaNumeric ? 'Enter a Corp Code up to 8 alphanumeric characters.'
                                : isValidCorpCode.bothFields ? 'Please add Corp Code and label information to continue.'
                                    : isValidCorpCode.fieldEmpty ? 'Please add Corp Code information to continue.'
                                        : isValidCorpCode.descriptionEmpty ? 'Please add Corp code label information to continue.'
                                            : ''}
                            ModalTitle={isValidCorpCode.alphaNumeric ? 'Corp Code invalid'
                                : isValidCorpCode.bothFields ? 'Corp Code missing'
                                    : isValidCorpCode.fieldEmpty ? 'Corp Code missing'
                                        : isValidCorpCode.descriptionEmpty ? 'Corp Code label missing'
                                            : ''}
                            ModalIsOpen={isValidCorpCode.alphaNumeric || isValidCorpCode.bothFields || isValidCorpCode.descriptionEmpty || isValidCorpCode.fieldEmpty}
                            CloseModal={() => { setIsValidCorpCode({ fieldEmpty: false, descriptionEmpty: false, bothFields: false, alphaNumeric: false }) }}
                        />
                        <CustomModal
                            okType
                            ModalText={modalTextInputCustomField.account ? 'Please add all required custom account field information to continue. ' : modalTextInputCustomField.amount ? 'Please add all required custom amount field information to continue.' : ''}
                            ModalTitle='Custom field information missing'
                            ModalIsOpen={modalTextInputCustomField.account || modalTextInputCustomField.amount}
                            CloseModal={() => { setModalTextInputCustomField({ account: false, amount: false }) }}
                        />
                        <CustomModal
                            okType
                            ModalText={modalCustomDropdownLabel ? `Please add custom dropdown label information to continue.` : modalCorpCodeLabel ? `Please add Corp Code label information to continue.` : ``}
                            ModalTitle={modalCustomDropdownLabel ? `Custom dropdown label missing` : modalCorpCodeLabel ? `Corp Code label missing` : ``}
                            ModalIsOpen={modalCustomDropdownLabel || modalCorpCodeLabel}
                            CloseModal={() => { setModalCustomDropdownLabel(false); setModalCorpCodeLabel(false); }}
                        />
                        <CustomModal
                            okType
                            ModalText={modalCustomDropdownAccountSelection || modalCorpCodeAccountSelection ? `Please select a account field information to continue.` : ``}
                            ModalTitle={modalCustomDropdownAccountSelection || modalCorpCodeAccountSelection ? `Account field selection is missing` : ``}
                            ModalIsOpen={modalCustomDropdownAccountSelection || modalCorpCodeAccountSelection}
                            CloseModal={() => { setModalCustomDropdownAccountSelection(false); setModalCorpCodeAccountSelection(false); }}
                        />
                        <CustomModal
                            okRefreshType
                            Refresh={() => { HandleRefresh(); }}
                            ModalText="Your exception setup changes have been saved."
                            ModalTitle="Exception setup changes saved"
                            ModalIsOpen={modalExceptionSetupSaved}
                            CloseModal={() => {
                                HandleRefresh();
                            }}
                        />
                        <CustomModal
                            yesOrNoType
                            ModalIsOpen={modalSelectLockbox}
                            CloseModal={() => { setModalSelectLockbox(false) }}
                            noText='Cancel'
                            yesText='Yes, go to new Lockbox'
                            yesClickMethod={ConfirmSelectLockboxId}
                            ModalText="Are you sure you want to select another Lockbox? Exception setup changes you made will not be saved."
                            ModalTitle="Exception setup changes not saved"
                        />
                        <CustomModal
                            okType
                            ModalText="This could be because the system returned an unexpected error or is temporarily unavailable to process your request. Please try again later."
                            ModalTitle="Unable to save changes"
                            ModalIsOpen={saveErrorModalOpen}
                            CloseModal={() => {
                                setSaveErrorModalOpen(false)
                            }}
                        />
                    </ModalsContainer>
                </div>
                :
                <Loader />}

        </main>
    )
}

export default ExceptionSetup;