import { toRelativeUrl } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { Loader } from '@ux/balance-react';
import { useEffect } from "react";
import { Outlet } from "react-router-dom-v6";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import LockboxHeader from "./LockboxHeader";

export const RequiredAuth: React.FC = () => {
    const { oktaAuth, authState } = useOktaAuth();

    useEffect(() => {
        if (!authState) {
            return;
        }

        const fetchToken = async () => {
            if (!authState?.isAuthenticated) {
                const originalUri = toRelativeUrl(window.location.href, window.location.origin);
                oktaAuth.setOriginalUri(originalUri);
                await oktaAuth.signInWithRedirect();
            }
        }

        fetchToken().catch(console.error);
    }, [oktaAuth, authState, authState?.isAuthenticated]);

    if (!authState || !authState?.isAuthenticated) {
        return (<Loader isLoading={true} />);
    }

    return (
        <RecoilRoot>
            <RecoilNexus />
            <LockboxHeader />
            <Outlet />
        </RecoilRoot>
    );
}
