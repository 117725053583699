/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useRef, FormEvent } from "react";
import { NavLink, useNavigate } from 'react-router-dom-v6';
import { Loader, Checkbox, Button, TextInput, ErrorSummary } from '@ux/balance-react';
import { useRecoilState, useRecoilValue } from "recoil";
import { ShowCientModal, ClientSelected, ClientNameBanker, Client__Selected_Code, userState } from "../atom";
import { getClients, getClient, addClient, addLockboxes } from "../API_Integrations/ClientMaintenance";
import { addClientEmulation } from '../API_Integrations/ClientLockboxSearch';
import { IClients, IClients_req_body, ILockboxes } from "../interfaces/IClientMaintenance";
import { ILockbox, ILockbox_req_body } from "../interfaces/iLockBox";
import Lockbox from "../API_Integrations/Lockbox";
import ClientHeader from "../components/ClientHeader";
import ModalsContainer from "../components/ModalsContainer";
import ClientSelectionModal from "../components/ClientSelectionModal";
import Table from "../components/Table";
import CustomModal from "../components/CustomModal";
import { useTitle } from "../utils/use-title";
import { useFocusOnElemnt } from "../utils/use-focus-on-element";
import { useRepeatAnnouncement } from "../utils/use-repeat-live-announcement";

interface ErrorMessage { ariaLabel: string, text: string, inputId: string }
interface errorState { clientName: string, clientId: string}

const ClientMaintenance = () => {
    useTitle("Client maintenance");
    enum ClientState { SearchAddClients, SearchResults, SelectLockbox };
    const [manageState, setManageState] = useState(ClientState.SearchResults);
    const [loading, setLoding] = useState(false);
    const [clientSearch, setClientSearch] = useState({ clientCode: '', clientName: '' })
    const [clientAdd, setClientAdd] = useState({ code: '', name: '' })
    const [data_clients, setData_clients] = useState<IClients[]>([]);
    const userType = useRecoilValue(userState);
    const [modalIsOpen, setModalIsOpen] = useRecoilState(ShowCientModal);
    const [client_selected, setClient_selected] = useRecoilState(ClientSelected);
    const [client_name, setClient_name] = useRecoilState(ClientNameBanker);
    const [client_code, setClient_code] = useRecoilState(Client__Selected_Code);
    const [modalIsOpenAddClient, setModalIsOpenAddClient] = useState(false);
    const [showSaveClientModal, setShowSaveClientModal] = useState(false);
    const [selectedLockboxes, setSelectedLockboxes] = useState<string[]>([]);
    const [checkedAllLockboxes, setCheckedAllLockboxes] = useState(false);
    const [lockbox_options, setLockbox_options] = useState<ILockboxes[]>([]);
    const [lockbox_codes, setLockbox_codes] = useState<string[]>([]);
    const [showSaveLockboxesModal, setShowSaveLockboxesModal] = useState(false);
    const [showRequiredLockboxModal, setShowRequiredLockboxModal] = useState(false);
    const [errorMessagetitle, setErrorMessagetitle] = useState('');
    const [errorMessagetext, setErrorMessagetext] = useState('');
    const [errorMessagesSummary, setErrorMessagesSummary] = useState<ErrorMessage[]>([]);
    const [isvalidClientName, setIsvalidClientName] = useState(true);
    const [isvalidClientId, setIsvalidClientId] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [liveAnnouncementText, setLiveAnnouncementText] = useState("");
    const navigate = useNavigate();
    const initialTextRef = useRef<HTMLHeadingElement | null >(null);
    const errorSummaryRef = useRef<HTMLElement | null>(null);

    useFocusOnElemnt(initialTextRef);

    useEffect(() => {
        setManageState(ClientState.SearchAddClients);
        setCheckedAllLockboxes(false);
        setClient_selected(false);
        setClient_name('');
        getLockboxData({
            lockboxNumber: '',
            lockboxName: ''
        }).then((result) => {
            setLockbox_options(result);
            getLockboxCodes(result);
        });
    }, []);

    useEffect(() => {
        console.log('modalOpen ', modalIsOpen)
    }, [modalIsOpen]);

    useEffect(() => {
        console.log(selectedLockboxes)
    }, [selectedLockboxes]);

    const getLockboxData = async (ReqBody: ILockbox_req_body) => {
        let result = await Lockbox.listLockbox(ReqBody)
        if (result !== undefined) {
            return result;
        } else {
            console.log('item not found')
        }
    }
    const getLockboxCodes = (lockboxesData: ILockbox[]) => {
        const lockboxesCodes = lockboxesData.map(el => el.code)
        setLockbox_codes(lockboxesCodes);
    }



    const HandleOnChangeClientCodeSearch = (event: Event) => { setClientSearch({ ...clientSearch, clientCode: (event.target as HTMLInputElement).value }) }
    const HandleOnChangeClientNameSearch = (event: Event) => { setClientSearch({ ...clientSearch, clientName: (event.target as HTMLInputElement).value }) }
    const HandleOnChangeClientCodeAdd = (event: Event) => { setClientAdd({ ...clientAdd, code: (event.target as HTMLInputElement).value }) }
    const HandleOnChangeClientNameAdd = (event: Event) => { setClientAdd({ ...clientAdd, name: (event.target as HTMLInputElement).value }) }

    let announcement  = "Loading, please wait.";
    const repeatLoadingAnnouncement = () => {
        setLiveAnnouncementText(announcement);
        announcement += '.';
    };

    useRepeatAnnouncement({repeatLoadingAnnouncement, loadingSearch});

    const handleClientSearch = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoadingSearch(true);
        setLiveAnnouncementText("Loading, please wait.");
        await getClientsData({ lockboxCode: '', lockboxName: '', clientCode: clientSearch.clientCode, clientName: clientSearch.clientName });
    }

    const setErrorSummaryFields = () => {
        const errors: ErrorMessage[] = [];

        if (clientAdd.code.trim() === '') {
            errors.push({ ariaLabel: "Client ID is required", text: "Enter client ID", inputId: 'clientId' });
            setIsvalidClientId(false);
        } else {
            setIsvalidClientId(true);
        }

        if (clientAdd.name.trim() === "") {
            errors.push({ ariaLabel: "Client name is required", text: "Enter client name", inputId: 'clientName' });
            setIsvalidClientName(false);
        } else {
            setIsvalidClientName(true);
        }
       
        setErrorMessagesSummary(errors);
        return errors;
      };

    const handleClientAdd = (event: Event) => {
        event.preventDefault();
        let catchError = setErrorSummaryFields();
        
        if (catchError.length === 0) {
            setLoding(true);
            addClient(clientAdd).then(() => {
                setErrorMessagetext('Your new client has been added.');
                setErrorMessagetitle('Client added');
                setShowSaveClientModal(true);
                setClientAdd({ code: '', name: '' });
                setLoding(false);
            }).catch(error => {
                const errorMessages = [];
                for (const key in error.errors) {
                    if (error.errors.hasOwnProperty(key)) {
                        errorMessages.push(error.errors[key][0]);
                    }
                }
                const ErrorMessagesTexts = errorMessages.join('');
                setErrorMessagetitle(error.title);
                setErrorMessagetext(ErrorMessagesTexts);
                setShowSaveClientModal(true);
                setLoding(false);
            });
        }
       
    }


    const handleConfirmAdd = () => {
        setModalIsOpenAddClient(false)
        setLoding(true);
        addClient({ code: clientSearch.clientCode, name: clientSearch.clientName }).then(() => {
            setErrorMessagetext('Your new client has been added.');
            setErrorMessagetitle('Client added');
            setShowSaveClientModal(true);
            setLoding(false);
        }).catch(error => {
            const errorMessages = [];
            for (const key in error.errors) {
                if (error.errors.hasOwnProperty(key)) {
                    errorMessages.push(error.errors[key][0]);
                }
            }
            const ErrorMessagesTexts = errorMessages.join('');
            setErrorMessagetitle(error.title);
            setErrorMessagetext(ErrorMessagesTexts);
            setShowSaveClientModal(true);
            setLoding(false);
        });
    }

    const getClientsData = async (ReqBody: IClients_req_body) => {
        try {
            let result = await getClients(ReqBody);
            if (result !== undefined) {
                if (result && result.length > 1) {
                    setLiveAnnouncementText(`${result.length} results found.`);
                } else if (result && result.length === 1) {
                    setLiveAnnouncementText(`${result.length} result found.`);
                } else {
                    setLiveAnnouncementText("No results were found.");
                }
                if (result.length > 0) {
                    setData_clients(result);
                    setManageState(ClientState.SearchResults)
                } else {
                    setModalIsOpenAddClient(true)
                }
            } else {
                console.log('items not found');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingSearch(false);
        }
    }

    const handleSelectRowLocations = (props: any, index: number) => {
        setSelectedLockboxes(prvSelectedLockboxes => {
            if (prvSelectedLockboxes.includes(props.code)) {
                return prvSelectedLockboxes.filter(code => code !== props.code)
            } else {
                return [...prvSelectedLockboxes, props.code]
            }
        });
    }

    const handleSelectAllLockboxes = (e: Event) => {
        setCheckedAllLockboxes(!checkedAllLockboxes);
        const checked = (e.target as HTMLInputElement).checked;
        if (checked) {
            setSelectedLockboxes(lockbox_codes);
        } else {
            setSelectedLockboxes([]);
        }
    }

    const handleClientSelection = (row: any) => {
        setLoding(true);
        addClientEmulation(row.code).then(() => {
            setClient_selected(true);
            setClient_name(row.name);
            setClient_code(row.code);
            getClient(row.code).then((result) => {
                console.log('result.code ', result.code);
                setSelectedLockboxes(result.lockboxCodes);
                const allLockboxesCheck = lockbox_codes.every(lockbox => result.lockboxCodes.includes(lockbox));
                setCheckedAllLockboxes(allLockboxesCheck);
                setManageState(ClientState.SelectLockbox);
                setLoding(false);
            });
        }).catch((error) => {
            console.log(error)
        });

    }

    const HandleSaveSelectedLockboxes = () => {
        setLoding(true);
        if(selectedLockboxes.length === 0) {
            setShowRequiredLockboxModal(true);
            setLoding(false);
        } else {
            addLockboxes(client_code, { "lockboxCodes": selectedLockboxes }).then(() => {
                setErrorMessagetitle('Lockbox(es) connected to client');
                setErrorMessagetext('Your changes have been saved. Do you want to go to User management next?');
                setShowSaveLockboxesModal(true);
                setLoding(false);
            }).catch((error) => {
                const errorMessages = [];
                for (const key in error.errors) {
                    if (error.errors.hasOwnProperty(key)) {
                        errorMessages.push(error.errors[key][0]);
                    }
                }
                const ErrorMessagesTexts = errorMessages.join('');
                setErrorMessagetitle(error.title);
                setErrorMessagetext(ErrorMessagesTexts);
                setShowSaveLockboxesModal(true);
                setLoding(false);
            });
        }
    }

    return (
        <main>
            <ClientHeader showClientName={true} showBreadcrumbs={false} />
            <h1 ref={initialTextRef} tabIndex={-1} className='heading-title heading-title-client heading-focus-text'>{manageState === ClientState.SearchAddClients ? 'Client maintenance' : manageState === ClientState.SearchResults ? 'Client search results' : 'Which Lockbox(es) do you want to connect to this client?'}</h1>
            <span aria-live='polite' className='announcement-text'>{liveAnnouncementText}</span>
            <ModalsContainer noGap>
                <ClientSelectionModal clientMaintenance ModalIsOpen={modalIsOpen} CloseModal={() => { setModalIsOpen(false) }} />
                <CustomModal yesOrNoType ModalIsOpen={modalIsOpenAddClient} CloseModal={() => { setModalIsOpenAddClient(false) }} noText='No, I do not want to add it' yesText='Yes, I want to add it' yesClickMethod={handleConfirmAdd} ModalText='Do you want to add this client?' ModalTitle='No results were found' />
                <CustomModal okType ModalText={errorMessagetext} ModalTitle={errorMessagetitle} ModalIsOpen={showSaveClientModal} CloseModal={() => { setShowSaveClientModal(false); setErrorMessagetitle(''); setErrorMessagetext(''); }} />
            </ModalsContainer>
            {(manageState === ClientState.SearchAddClients) ?
                <div className='loader-section'>
                    <Loader isLoading={loading} />
                    <p className="search-client-title">Search for a client</p>
                    <form className='lockbox-search-control' onSubmit={handleClientSearch}>

                        <TextInput
                            label="Enter client ID"
                            initialValue={clientSearch.clientCode}
                            size='small'
                            handleOnChange={HandleOnChangeClientCodeSearch}
                            type="text"
                            classes="width-208"
                        />

                        <TextInput
                            label="Enter client name"
                            initialValue={clientSearch.clientName}
                            size='small'
                            handleOnChange={HandleOnChangeClientNameSearch}
                            type="text"
                            classes="width-322 left-24-gap-filters"
                        />
                        <div className='lockbox-search-button'>
                            <Button
                                size="medium"
                                text="Search"
                                type="submit"
                                isLoading={loadingSearch}
                            />
                        </div>
                    </form>
                    <div className='card-divider'></div>
                    <p className="add-client-title">Add a new client</p>
                    {errorMessagesSummary.length !== 0 &&
                            <ErrorSummary
                                errors={errorMessagesSummary}
                                ref={errorSummaryRef}
                            />
                        }
                    <div className='lockbox-search-control bottom-gap-6'>
                        <TextInput
                            label="Enter client ID"
                            name="clientId"
                            initialValue={clientAdd.code}
                            size='small'
                            handleOnChange={HandleOnChangeClientCodeAdd}
                            type="text"
                            classes="width-208"
                            errorMessages={["Enter a client ID."]}
                            isValid={isvalidClientId}
                            inputId="clientId"
                        />

                        <TextInput
                            label="Enter client name"
                            name="clientName"
                            initialValue={clientAdd.name}
                            size='small'
                            handleOnChange={HandleOnChangeClientNameAdd}
                            type="text"
                            classes="width-322 left-24-gap-filters"
                            errorMessages={["Enter a client name."]}
                            isValid={isvalidClientName}
                            inputId="clientName"
                        />
                        <div className='lockbox-search-button'>
                            <Button
                                clickMethod={handleClientAdd}
                                size="medium"
                                text="Add"
                            />
                        </div>

                    </div>
                </div>
                : (manageState === ClientState.SearchResults) ?
                    <div>
                        <div className='search-lockboxes-table'>
                            <Table
                                ariaDescribedBy={null}
                                caption='Lockbox search table'
                                classes=""
                                columnData={
                                    [
                                        {
                                            headerId: 'code',
                                            headerLabel: 'Client ID',
                                            rowCellTableRowComponentsArrayIndex: 0,
                                            type: 'component'
                                        },
                                        {
                                            headerId: 'name',
                                            headerLabel: 'Client name '
                                        },
                                    ]
                                }
                                data={data_clients}
                                tableRowComponents={[
                                    (props: any) => (
                                        <NavLink
                                            to={`#`}
                                            onClick={() => handleClientSelection(props.row.original)}>
                                            {props.row.original.code}
                                        </NavLink>

                                    )
                                ]}
                            />
                        </div>

                    </div>
                    :
                    <div>
                        <div className=''>
                            <Table
                                caption="Client maintenance"
                                columnData={[
                                    { headerId: 'select', headerLabel: 'Select all', rowCellTableRowComponentsArrayIndex: 0, headerCellTableRowComponentsArrayIndex: 1, type: 'component', disableSorting: true },
                                    { headerId: 'code', headerLabel: 'Lockbox number' },
                                    { headerId: 'name', headerLabel: 'Lockbox name' },
                                    { headerId: 'siteName', headerLabel: 'Site' },
                                ]}
                                data={lockbox_options}
                                showSupplementalStatus
                                supplementalStatus={`${selectedLockboxes.length} of ${lockbox_options.length} ${lockbox_options.length > 1 ? 'rows' : 'row'} selected for download`}
                                isLined
                                isSortable
                                tableRowComponents={[
                                    (props: any) => (
                                        <div className='center-select'>
                                            <Checkbox
                                                isChecked={selectedLockboxes.includes(props.row.original.code)}
                                                inputId={props.row.original.headerId}
                                                handleOnChange={() => handleSelectRowLocations(props.row.original, props.row.index)}
                                                label=""
                                                aria-labelledby="Select"
                                            />
                                        </div>
                                    ),
                                    (props: any) => (
                                        <div className='table-select-all'>
                                            <span>Select all</span>
                                            <Checkbox
                                                isChecked={checkedAllLockboxes}
                                                handleOnChange={(e: Event) => handleSelectAllLockboxes(e)}
                                                label=""
                                                aria-labelledby="Select all"
                                            />
                                        </div>
                                    )
                                ]}
                            />
                        </div>
                        <Button
                            classes="vertical-gap-24"
                            clickMethod={() => { HandleSaveSelectedLockboxes() }}
                            size="medium"
                            text="Save selected"
                        />
                        <div>
                            {userType.isAdmin ?
                                <CustomModal yesOrNoType ModalIsOpen={showSaveLockboxesModal} CloseModal={() => { setShowSaveLockboxesModal(false) }} noText='Close' yesText='Yes, take me to user management' yesClickMethod={() => { navigate("/users") }} ModalText='Your changes have been saved. Do you want to go to User management next?' ModalTitle='Lockbox(es) connected to client' />
                                :
                                <CustomModal okType ModalIsOpen={showSaveLockboxesModal} CloseModal={() => { setShowSaveLockboxesModal(false) }} ModalText='Your changes have been saved.' ModalTitle='Lockbox(es) connected to client' />
                            }
                        </div>
                        <div>
                            <CustomModal okType ModalIsOpen={showRequiredLockboxModal} CloseModal={() => { setShowRequiredLockboxModal(false) }} ModalText='Please select a Lockbox to connect to your client.' ModalTitle='Required information missing' />
                        </div>
                    </div>
            }
        </main>
    );
};

export default ClientMaintenance;
