function CardContainer(props: any) {

    return (
        <div>
            {props.relatedDocuments ?
                <div className='related-documents-container'>
                    {props.children}
                </div>
                :
                <div className='inner-container'>
                    {props.children}
                </div>
            }
        </div>
    );
}

export default CardContainer;