/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import dateformat from "dateformat";
import { useRecoilState, useRecoilValue } from 'recoil';
import { DailySummaryRes, exception_reportDate } from '../atom';
import { dailyExceptionSummaryData, DailySummaryMirror, generateDailyExceptionSummaryReport } from '../API_Integrations/exceptionDailySummary';
import ClientHeader from '../components/ClientHeader';
import Loader from '../components/Loader';
import ModalsContainer from '../components/ModalsContainer';
import CustomModal from '../components/CustomModal';
import Table from '../components/Table';
import { useFocusOnElemnt } from '../utils/use-focus-on-element';
import { useTitle } from '../utils/use-title';
import ExceptionMirror from '../components/ExceptionMirror';

function ExceptionDailySummaryReport() {
    useTitle("Exception daily summary report");

    const [loading, setLoading] = useState(false);
    const [dailySummaryMirror, setDailySummaryMirror] = useState<DailySummaryMirror>({ date: '', totalExceptions: 0, outstanding: 0, completed: 0, returned: 0, escalated: 0, rejected: 0 });
    const [data_daily_summary, setData_daily_summary] = useRecoilState(DailySummaryRes);
    const reportDate = useRecoilValue(exception_reportDate);
    const [formattedReportDate, setFormattedReportDate] = useState('');
    const [modalIsOpenDownload, setModalIsOpenDownload] = useState(false);
    const initialTextRef = useRef<HTMLHeadingElement | null>(null);

    useFocusOnElemnt(initialTextRef);

    useEffect(() => {
        const date = dateformat(reportDate, "yyyy-mm-dd");
        setFormattedReportDate(dateformat(reportDate, "mm/dd/yyyy"));
        getDailySummaryData(date);
    }, []);

    const getDailySummaryData = async (date: string) => {
        setLoading(true);
        let result = await dailyExceptionSummaryData(date);
        if (result !== undefined) {
            setDailySummaryMirror({ date: formattedReportDate, totalExceptions: result.totalExceptionsCount, outstanding: result.outstandingExceptionsCount, completed: result.completedExceptionsCount, returned: result.returnedExceptionsCount, escalated: result.escalatedExceptionsCount, rejected: result.rejectedExceptionsCount });
            setData_daily_summary(result.exceptionSummaries);
            setLoading(false);
        } else {
            console.log('items not found');
        }
        setLoading(false);
    }

    const handleDownloadReports = () => {
        const date = dateformat(reportDate, "yyyy-mm-dd");
        generateDailyExceptionSummaryReport({ processingDate: date });
        setModalIsOpenDownload(true);
    }


    return (
        <main>
            <ClientHeader showClientName={true} showExceptionsBreadcrumbs={true} />
            <div className='loader-section'>
                {!loading ?
                    <>
                        {(data_daily_summary && data_daily_summary.length > 0) ?
                            <>
                                <ExceptionMirror
                                    dailySummaryReport={true}
                                    date={formattedReportDate}
                                    totalExceptions={dailySummaryMirror.totalExceptions}
                                    outstanding={dailySummaryMirror.outstanding}
                                    completed={dailySummaryMirror.completed}
                                    returned={dailySummaryMirror.returned}
                                    escalated={dailySummaryMirror.escalated}
                                    rejected={dailySummaryMirror.rejected}
                                />
                                <ModalsContainer>
                                    <CustomModal
                                        downloadIconButton={true}
                                        showClose={true}
                                        ButtonTitle='Download report as CSV'
                                        ModalIsOpen={modalIsOpenDownload}
                                        OpenModal={handleDownloadReports}
                                        CloseModal={() => { setModalIsOpenDownload(false) }}
                                        ModalText='Your report has been requested and will be available in Reports.'
                                        ModalTitle='Request submitted'
                                    />
                                </ModalsContainer>
                                <div className='daily-summary-table'>
                                    <Table
                                        ariaDescribedBy={null}
                                        caption={`Daily decision summary table for ${formattedReportDate}`}
                                        classes=""
                                        columnData={
                                            [
                                                {
                                                    headerId: 'user',
                                                    headerLabel: 'User ID ',
                                                },
                                                {
                                                    headerId: 'status',
                                                    headerLabel: 'Status '
                                                },
                                                {
                                                    headerId: 'transactionCountFormatted',
                                                    headerLabel: 'Count '
                                                },
                                            ]
                                        }
                                        data={data_daily_summary}
                                    />
                                </div>
                            </>
                            :
                            <></>
                        }
                    </>
                    :
                    <Loader />
                }
            </div>
        </main>);
}

export default ExceptionDailySummaryReport;
