/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom-v6';
import dateformat from "dateformat";
// @ts-ignore
import { Button } from '@ux/balance-react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ExceptionBatchSummaryRes, exception_summary_processDate, exception_batch_ID, exception_lockbox_code, selected_corp_code } from '../atom';
import { exceptionBatchSummaryData, BatchSummaryMirror, BatchSummaryRes, generateExceptionBatchSummaryReport } from '../API_Integrations/exceptionBatchSummary';
import ClientHeader from '../components/ClientHeader';
import Loader from '../components/Loader';
import Table from '../components/Table';
import { useTitle } from '../utils/use-title';
import ExceptionMirror from '../components/ExceptionMirror';
import ModalsContainer from '../components/ModalsContainer';
import CustomModal from '../components/CustomModal';

function ExceptionBatchSummary() {
    useTitle("Exception batch summary");
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [batchSummaryMirror, setBatchSummaryMirror] = useState<BatchSummaryMirror>({ date: '', id: '', outstanding: 0, completed: 0, returned: 0, escalated: 0, rejected: 0 });
    const exception_lockboxCode = useRecoilValue(exception_lockbox_code);
    const setSelected_corpCode = useSetRecoilState(selected_corp_code);
    const [exception_batchID, setException_batchID] = useRecoilState(exception_batch_ID);
    const [data_batch_summary, setdata_batch_summary] = useRecoilState(ExceptionBatchSummaryRes);
    const processDate = useRecoilValue(exception_summary_processDate);
    const [formattedProcessDate, setFormattedProcessDate] = useState('');
    const [modalIsOpenDownload, setModalIsOpenDownload] = useState(false);
    const [modalIsOpenViewReport, setModalIsOpenViewReport] = useState(false);

    useEffect(() => {
        const date = dateformat(processDate, "yyyy-mm-dd");
        setFormattedProcessDate(dateformat(processDate, "mm/dd/yyyy"));
        getBatchSummaryData(date, exception_lockboxCode);
        setSelected_corpCode('All');
    }, []);

    const getBatchSummaryData = async (dateReport: string, lockboxCode: string) => {
        console.log(dateReport)
        setLoading(true);
        let result = await exceptionBatchSummaryData(dateReport, lockboxCode);
        if (result !== undefined) {
            setBatchSummaryMirror({ date: formattedProcessDate, id: result.lockboxCode, outstanding: result.outstandingExceptionsCount, completed: result.completedExceptionsCount, returned: result.returnedExceptionsCount, escalated: result.escalatedExceptionsCount, rejected: result.rejectedExceptionsCount});
            setdata_batch_summary(result.batchItems);
            setLoading(false);
        } else {
            console.log('items not found');
        }
        setLoading(false);
    }

    const handleBatchItemSelections = (row: any) => {
        setException_batchID(row.exceptionBatchId);
        navigate(`/exception-batch-detail/${row.exceptionBatchId}`);
    }

    const handleCSV = () => {
        generateExceptionBatchSummaryReport({ processingDate: dateformat(processDate, "yyyy-mm-dd"), fileType: 'Csv', lockboxCode: exception_lockboxCode })
        setModalIsOpenViewReport(true);
        setModalIsOpenDownload(true);
    }

    return (
        <main>
            <ClientHeader showClientName={true} showExceptionsBreadcrumbs={true} />
            <div className='loader-section'>
                {!loading ?
                    <>
                        {(data_batch_summary && data_batch_summary.length > 0) ?
                            <>
                                <ExceptionMirror
                                    exceptionBatchSummary={true}
                                    date={formattedProcessDate}
                                    id={batchSummaryMirror.id}
                                    outstanding={batchSummaryMirror.outstanding}
                                    completed={batchSummaryMirror.completed}
                                    returned={batchSummaryMirror.returned}
                                    escalated={batchSummaryMirror.escalated}
                                    rejected={batchSummaryMirror.rejected}
                                />
                                <ModalsContainer>
                                    <CustomModal
                                        downloadIconButton={true}
                                        showClose={true}
                                        ButtonTitle='Download report as CSV'
                                        ModalIsOpen={modalIsOpenDownload}
                                        OpenModal={handleCSV}
                                        CloseModal={() => { setModalIsOpenDownload(false) }}
                                        ModalText='Your report has been requested and will be available in Reports.'
                                        ModalTitle='Request submitted'
                                    />
                                </ModalsContainer>
                                <div className='daily-summary-table'>
                                    <Table
                                        ariaDescribedBy={null}
                                        caption={`Exception batch summary table for ${exception_batchID} for ${formattedProcessDate}`}
                                        classes=""
                                        columnData={
                                            [
                                                {
                                                    rowCellTableRowComponentsArrayIndex: 0,
                                                    headerId: 'exceptionBatchNumber',
                                                    type: 'component',
                                                    headerLabel: 'Batch number ',
                                                },
                                                {
                                                    headerId: 'totalExceptionsCountFormatted',
                                                    headerLabel: 'Total items in batch '
                                                },
                                                {
                                                    headerId: 'outstandingExceptionsCountFormatted',
                                                    headerLabel: 'Outstanding exceptions '
                                                },
                                                {
                                                    rowCellTableRowComponentsArrayIndex: 1,
                                                    headerId: 'users',
                                                    type: 'component',
                                                    headerLabel: 'Current user(s) in batch '
                                                },
                                                {
                                                    headerId: 'status',
                                                    headerLabel: 'Status '
                                                },
                                            ]
                                        }
                                        data={data_batch_summary}
                                        tableRowComponents={[
                                            (props: any) => (
                                                <Button
                                                    variant="text-primary"
                                                    clickMethod={() => handleBatchItemSelections(props.row.original)}
                                                    text={props.row.original.exceptionBatchNumber}
                                                    classes="handle-lockbox-selection underlined-links"
                                                    dataTestId={props.row.original.exceptionBatchId}
                                                />
                                                // <p>
                                                //     <a onClick={() => handleExceptionBoxSelection(props.row.original)} className="table-row-links" href={`/exception-batch-summary/${props.row.original.lockboxCode}`}>{props.row.original.lockboxCode}</a>
                                                // </p>
                                            ),
                                            (props: any) => (
                                                <div>
                                                    {props.row.original.users.length === 0 ?
                                                        <span></span>
                                                        :
                                                        <div>
                                                            {props.row.original.users.map((user: string, index: number) => (
                                                                <span key={index}>
                                                                    {user}
                                                                    {index !== props.row.original.users.length - 1 ? '; ' : null}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    }
                                                </div>
                                            ),
                                        ]}
                                    />
                                </div>
                            </>
                            :
                            <></>
                        }
                    </>
                    :
                    <Loader />
                }
            </div>
        </main>
    )
}

export default ExceptionBatchSummary;
