import requests from "./apiRequest";

export interface ActiveBanner {
    id: number,
    title: string,
    content: string,
    linkTitle?: string,
    linkUrl?: string
}

export interface Banner {
    id: number,
    title: string,
    content: string,
    linkTitle?: string,
    linkUrl?: string,
    sites: BannerSite[],
    created_on: Date,
    startOn?: Date,
    endOn?: Date
}

export interface CreateEditBanner {
    title: string,
    content: string,
    linkTitle?: string,
    linkUrl?: string,
    startOn?: Date,
    endOn?: Date,
    siteCodes: string[]
}

export interface BannerSite {
    siteCode: string,
    siteName: string
}

export const getBanners = async (): Promise<Banner[]> => {
    let banners = await requests.get('/banners');
    return banners;
};

export const getClientBanners = async (): Promise<Banner[]> => {
    let banners = await requests.get('/banners');
    return banners;
}

export const getActiveBanners = async (): Promise<ActiveBanner[]> => {
    let banners = await requests.get('/banners/active');
    return banners;
};

export const createBanner = async (banner: CreateEditBanner): Promise<Banner> => {
    const { data } = await requests.post("/banners", banner);
    return data;
}

export const editBanner = async (bannerId: number, banner: CreateEditBanner): Promise<Banner> => {
    const { data } = await requests.put("/banners/" + bannerId, banner);
    return data;
}

export const deleteBanner = async (id: number) => {
    await requests.delete("/banners/" + id);
}
