import React from 'react';

function ButtonContainer(props: any) {

    return (
        <div className={props.remitter ? 'remitter-button-container' : 'button-container'}>
            <div className={props.noTopGap ? '' : 'top-gap-24'}>
            {props.children}
            </div>
        </div>
    );
}

export default ButtonContainer;