import requests from "./apiRequest";

import { IReportingSummary_req_body, ReportingSummaryReportRequest } from "../interfaces/IReportingSummary";

const ReportingSummary = {
    details: async (body: IReportingSummary_req_body) => {
        try {
            let result = await requests.getParams('summaries/reporting-summary', body);
            return result;
        } catch (error) {
            console.log('error from lockbox api integration: ', error);
        }
    },

    generateReport: async (body: ReportingSummaryReportRequest) => {
        let result = await requests.post("summaries/reporting-summary/report", body);
        return result;
    }
}
export default ReportingSummary;
