import { LoginCallback } from "@okta/okta-react";
import { Loader } from '@ux/balance-react';
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom-v6";
import App from "../App";
import { RequiredAuth } from "../components/RequiredAuth";
import BankerLockboxSearch from "../pages/BankerLockboxSearch";
import BankerLockboxSummary from "../pages/BankerLockboxSummary";
import BannerManagement from "../pages/BannerManagement";
import BatchDetail from "../pages/BatchDetail";
import BatchSummary from "../pages/BatchSummary";
import ClientBannerManagement from "../pages/ClientBannerManagement";
import Correspondence from "../pages/Correspondence";
import DashboardSummary from "../pages/DashboardSummary";
import Remitters from "../pages/Remitters";
import SearchAndReports from "../pages/SearchAndReports";
import TransactionDetail from "../pages/TransactionDetail";
import UserManagement from "../pages/UserManagement";
import ClientMaintenance from "../pages/ClientMaintenance";
import Alerts from "../pages/Alerts";
import HelpAndSupport from "../pages/HelpAndSupport";
import ExceptionBatchSummary from "../pages/ExceptionBatchSummary";
import ExceptionTransactionDetail from "../pages/ExceptionTransactionDetail";
import ExceptionDailySummaryReport from "../pages/ExceptionDailySummaryReport";
import ExceptionDailyDecisionReport from "../pages/ExceptionDailyDecisionDetailReport";
import ExceptionBatchDetail from "../pages/ExceptionBatchDetail";
import ExceptionSetup from "../pages/ExceptionSetup";

const routes = createRoutesFromElements(
    <Route path="/" element={<App />}>
        <Route path="/login/callback" element={<LoginCallback loadingElement={<Loader isLoading={true} />} />}></Route>
        <Route path='' element={<RequiredAuth />}>
            <Route path="/banker-lockbox-search" element={<BankerLockboxSearch />} />
            <Route path="/banker-lockbox-summary/:id" element={<BankerLockboxSummary />} />
            <Route path="/banners" element={<BannerManagement />} />
            <Route path="/batch-summary/:lockboxCode" element={<BatchSummary />} />
            <Route path="/batch-detail/:id" element={<BatchDetail />} />
            <Route path="/correspondence" element={<Correspondence />} />
            <Route path="/client-banners" element={<ClientBannerManagement />} />
            <Route path="/dashboard-summary" element={<DashboardSummary />} />
            <Route path="/remitters" element={<Remitters />} />
            <Route path="/search-and-reports" element={<SearchAndReports />} />
            <Route path="/transaction-detail/:id" element={<TransactionDetail />} />
            <Route path="/users" element={<UserManagement />} />
            <Route path="/client-maintenance" element={<ClientMaintenance />} />
            <Route path="/alerts" element={<Alerts/>} />
            <Route path="/help-and-support" element={<HelpAndSupport/>} />
            <Route path="/exception-batch-summary/:lockboxCode" element={<ExceptionBatchSummary />} />
            <Route path="/exception-batch-detail/:id" element={<ExceptionBatchDetail />} />
            <Route path="/exception-transaction-detail/:id" element={<ExceptionTransactionDetail />} />
            <Route path="/exception-daily-summary" element={<ExceptionDailySummaryReport />} />
            <Route path="/exception-daily-decision" element={<ExceptionDailyDecisionReport />} />
            <Route path="/exception-setup" element={<ExceptionSetup />} />
        </Route>
        <Route path="*" element={<div>Page not found</div>} />
    </Route>
);

export const router = createBrowserRouter(routes);
