import dateformat from "dateformat";
import { atom } from 'recoil';
import { ActiveBanner, Banner } from "./API_Integrations/banners";

//Interfaces
import { Client } from "./API_Integrations/clients";
import Lockbox from "./API_Integrations/Lockbox";
import { Site } from "./API_Integrations/sites";
import { User } from './API_Integrations/users';
import { IBatchSummary_req_body, IBatch_Summary, IBatch_Summary_Mirror } from './interfaces/IBatchSummary';
import { IBatch_Detail_Mirror, IBatch_Detail_Res } from './interfaces/IBatch_Detail';
import { ILockboxCorrespondence, ITransaction_Detail_Correspondence } from "./interfaces/ICorrespondence";
import { ILockbox } from './interfaces/iLockBox';
import { ILockboxesTransactionSearch } from "./interfaces/ILockboxesTransactionsSearch";
import { ILockboxSummaryData } from "./interfaces/ILockboxSummary";
import { IRemitters } from "./interfaces/IRemitters";
import { IReportingSummary_req_body, IReporting_Summary } from './interfaces/IReportingSummary';
import { ITransaction_Detail_DetailData, ITransaction_Detail_Items, ITransaction_Detail_Mirror } from './interfaces/ITransactionDetail';
import { InitialTransactionsEnum, InitialColumnsEnum, OperationOptionEnums } from './interfaces/ILockboxesTransactionsSearch';
import { IUser } from './interfaces/IUser';
import { DailyExceptionSummaryRes  } from "./API_Integrations/exceptionDailySummary";
import { BatchItemsRes } from "./API_Integrations/exceptionBatchSummary";
import { DailyDecisionDetailRes } from './API_Integrations/exceptionDailyDetail';
import { ExceptionBatchesRes } from "./API_Integrations/exceptionBatchDetail";
import { IFields } from "./API_Integrations/exceptionTransactionDetail";

export const authToken = atom({
    key: 'authToken',
    default: null
})

export const activeBannersState = atom({
    key: 'activeBanners',
    default: [] as ActiveBanner[]
});

export const bannersState = atom({
    key: 'banners',
    default: [] as Banner[]
});

export const clientBannersState = atom({
    key: 'client-banners',
    default: [] as Banner[]
});

export const clientsState = atom({
    key: 'clients',
    default: [] as Client[]
});

export const lockboxesState = atom({
    key: 'lockboxes',
    default: Lockbox.listClientLockbox({
        lockboxNumber: '',
        lockboxName: ''
    })
})

export const sitesState = atom({
    key: 'sites',
    default: [] as Site[]
});

export const usersState = atom({
    key: 'users',
    default: [] as User[]
});

//CurrentUser API store
export const userState = atom({
    key: 'User',
    default: {} as IUser
})

//Header- Lockbox breadcrumbs
export const ShowBreadcrumbs = atom({
    key: 'ShowBreadcrumbs',
    default: false
})
export const Breadcrumbs_data = atom({
    key: 'Breadcrumbs_data',
    default: [] as []
})
export const BreadcrumbLoader = atom({
    key: 'BreadcrumbLoader',
    default: false
})
export const ParamsBreadcrumbs = atom({
    key: 'ParamsBreadcrumbs',
    default: '' as string | number
})
export const ClientSelected = atom({
    key: 'ClientSelected',
    default: false
})
export const ClientNameBanker = atom({
    key: 'ClientNameBanker',
    default: ''
})
export const ShowCientModal = atom({
    key: 'ShowCientModal',
    default: false
})
export const DynamicPageName = atom({
    key: 'DynamicPageName',
    default: ''
})
//Banker API store
export const LockboxSearchData = atom({
    key: 'LockboxSearchData',
    default: [] as Client[]
})
export const LockboxNumber = atom({
    key: 'LockboxNumber',
    default: ''
})
export const LockboxName = atom({
    key: 'LockboxName',
    default: ''
})
export const banker_summary_reqBody_startDate = atom({
    key: 'banker_summary_reqBody_startDate',
    default: (new Date())
})
export const banker_summary_reqBody_endDate = atom({
    key: 'banker_summary_reqBody_endDate',
    default: (new Date())
})
export const Client_code = atom({
    key: 'Client_code',
    default: ''
})
export const LockboxSummaryData = atom({
    key: 'LockboxSummaryData',
    default: [] as ILockboxSummaryData[]
})
//Reporting summary API store
export const ReportingSummaryData = atom({
    key: 'ReportingSummaryData',
    default: [] as IReporting_Summary[]
})

export const reporting_summary_req_body = atom({
    key: 'reporting_summary_req_body',
    default: {} as IReportingSummary_req_body
})

export const reporting_summary_reqBody_filters = atom({
    key: 'reporting_summary_reqBody_filters',
    default: ''
})
export const reporting_summary_reqBody_startDate = atom({
    key: 'reporting_summary_reqBody_startDate',
    default: dateformat(new Date(), "yyyy-mm-dd")
})
export const reporting_summary_reqBody_endDate = atom({
    key: 'reporting_summary_reqBody_endDate',
    default: dateformat(new Date(), "yyyy-mm-dd")
})

//Batch summary API store
export const BatchSummaryData = atom({
    key: 'BatchSummaryData',
    default: {} as IBatch_Summary
})

export const batch_summary_req_body = atom({
    key: 'batch_summary_req_body',
    default: {} as IBatchSummary_req_body
})
export const batch_summary_reqBody_pageNumber = atom({
    key: 'batch_summary_reqBody_pageNumber',
    default: 1
})
export const batch_summary_reqBody_pageSize = atom({
    key: 'batch_summary_reqBody_pageSize',
    default: 15
})
export const batch_summary_reqBody_filters = atom({
    key: 'batch_summary_reqBody_filters',
    default: ''
})
export const batch_summary_reqBody_lockbox = atom({
    key: 'batch_summary_reqBody_lockbox',
    default: ''
})
export const batch_summary_reqBody_startDate = atom({
    key: 'batch_summary_reqBody_startDate',
    default: new Date()
})
export const batch_summary_reqBody_endDate = atom({
    key: 'batch_summary_reqBody_endDate',
    default: new Date()
})
export const batch_summary_reqBody_payment = atom({
    key: 'batch_summary_reqBody_payment',
    default: 'Checks and Documents'
})

//Batch summary Mirror store
export const BatchSummaryMirror = atom({
    key: 'BatchSummaryMirror',
    default: {
        lockboxCode: '',
        lockboxName: '',
        siteName: '',
        totalDeposits: 0,
        totalBatches: 0,
        totalTransactions: 0,
        status: ''
    } as IBatch_Summary_Mirror
})

export const batch_number_detail = atom({
    key: 'batch_number_detail',
    default: 0
})

//Batch Detail Mirror store
export const BatchDetailMirror = atom({
    key: 'BatchDetailMirror',
    default: {
        depositDate: '',
        batchNumber: 0,
        transactions: 0,
        documents: 0,
        batchTotal: 0,
        lockboxCode: '',
        totalBatchesForProcessingDate: 0
    } as IBatch_Detail_Mirror
})

//batch detail store
export const batch_ID = atom({
    key: 'batch_ID',
    default: 0
})

export const BatchDetailRes = atom({
    key: 'BatchDetailRes',
    default: [] as IBatch_Detail_Res[]
})

export const BatchType = atom({
    key: 'BatchType',
    default: ''
})

export const RefreshBatchDetail = atom({
    key: 'RefreshBatchDetail',
    default: false
})

//transaction detail store
export const IsEnlarged = atom({
    key: 'IsEnlarged',
    default: false
})

export const transaction_ID = atom({
    key: 'transaction_ID',
    default: 0
})

export const correspondence_ID = atom({
    key: 'correspondence_ID',
    default: 0
})

export const LockboxType = atom({
    key: 'LockboxType',
    default: ''
})

export const TransactionDetailMirror = atom({
    key: 'TransactionDetailMirror',
    default: {} as ITransaction_Detail_Mirror
})

export const TransactionItemsDataRes = atom({
    key: 'TransactionItemsDataRes',
    default: [] as ITransaction_Detail_Items[]
})

export const TransactionCheckIndex = atom({
    key: 'TransactionCheckIndex',
    default: 0
})

export const TransactionCorrespondenceDataRes = atom({
    key: 'TransactionCorrespondenceDataRes',
    default: {} as ITransaction_Detail_Correspondence
})
export const TransactionDocumentIndex = atom({
    key: 'TransactionDocumentIndex',
    default: 0
})
export const TransactionAdditionalDetailsRes = atom({
    key: 'TransactionAdditionalDetailsRes',
    default: [] as ITransaction_Detail_DetailData[]
})
export const TransactionCurrentPage = atom({
    key: 'TransactionCurrentPage',
    default: 0
})
export const LastFocusedImageBtn= atom({
    key: 'LastFocusedImageBtn',
    default: {} as {[key: string]: 'icon' | 'image' | null}
})

//correspondence
export const correspondence_reqBody_from = atom({
    key: 'correspondence_reqBody_from',
    default: (new Date())
})
export const correspondence_reqBody_to = atom({
    key: 'correspondence_reqBody_to',
    default: (new Date())
})
export const LockboxCorrespondenceData = atom({
    key: 'LockboxCorrespondenceData',
    default: [] as ILockboxCorrespondence[]
})
export const LockboxCorrespondenceOptions = atom({
    key: 'LockboxCorrespondenceOptions',
    default: [] as ILockbox[]
})
//search and reports navigation tabs
export const NavToggle = atom({
    key: 'NavToggle',
    default: 1
});

export const TransactionDetailOptions = atom({
    key: 'TransactionDetailOptions',
    default: InitialTransactionsEnum
});

export const TransactionOptionsLength = atom({
    key: 'TransactionOptionsLength',
    default: 4
});

export const OperationOptions = atom({
    key: 'OperationOptions',
    default: OperationOptionEnums
});

export const SearchColumnData = atom({
    key: 'SearchColumnData',
    default: InitialColumnsEnum
});

export const LockboxTransactionSearchData = atom({
    key: 'LockboxTransactionSearchData',
    default: [] as ILockboxesTransactionSearch[]
});

//Remitters
export const RemitterDataOptions = atom({
    key: 'RemitterDataOptions',
    default: [
        
        {
            label: 'All remitter data',
            value: 'all',
            id: 1
        },
        {
            label: 'Account number',
            value: 'accountNumber',
            id: 2
        },
        {
            label: 'Routing number',
            value: 'routingNumber',
            id: 3
        },
        {
            label: 'Lockbox ID',
            value: 'lockboxCode',
            id: 4
        },
        {
            label: 'Remitter',
            value: 'name',
            id: 5
        },
    ]
});

export const RemittersData = atom({
    key: 'RemittersData',
    default: [] as IRemitters[]
});

export const Remitter_Lockbox_ID = atom({
    key: 'Remitter_Lockbox_ID',
    default: 0
})

//Client maintenance
export const Client__Selected_Code = atom({
    key: 'Client__Selected_Code',
    default: 0
})

//Exception mangement
export const ExceptionSubscribe = atom({
    key: 'ExceptionSubscribe',
    default: { type: '', subscribed: false }
})

export const ExceptionSubscribeClient = atom({
    key: 'ExceptionSubscribeClient',
    default: { type: '', subscribed: false }
})

export const CurrentEnvironment = atom({
    key: 'CurrentEnvironment',
    default: ''
})

export const Exception_Banker_CurrentTab = atom({
    key: 'Exception_Banker_CurrentTab',
    default: 'REPORTINGSUMMARY'
})

export const Exception_Client_CurrentTab = atom({
    key: 'Exception_Client_CurrentTab',
    default: 'REPORTINGSUMMARY'
})

export const exception_summary_processDate = atom({
    key: 'exception_summary_processDate',
    default: new Date()
})

export const exception_reportDate = atom({
    key: 'exception_reportDate',
    default: new Date()
})

export const exception_lockbox_code = atom({
    key: 'exception_lockbox_code',
    default: ''
})

export const DailySummaryRes = atom({
    key: 'DailySummaryRes',
    default: [] as DailyExceptionSummaryRes []
})

export const ExceptionBatchSummaryRes = atom({
    key: 'ExceptionBatchSummaryRes',
    default: [] as BatchItemsRes[]
})

export const exception_batch_ID = atom({
    key: 'exception_batch_ID',
    default: 0
})

export const ExceptionBatchDetailRes = atom({
    key: 'ExceptionBatchDetailRes',
    default: {} as ExceptionBatchesRes
})

export const exception_transaction_ID = atom({
    key: 'exception_transaction_ID',
    default: 0
})
        
export const DailyExceptionDetailRes = atom({
    key: 'DailyExceptionDetailRes',
    default: [] as DailyDecisionDetailRes []
})

export const selected_corp_code = atom({
    key: 'selected_corp_code',
    default: 'All'
})

export const exception_setup_lockboxId = atom({
    key: 'exception_setup_lockboxId',
    default: ''
})

export const exception_setup_unsaved_changes = atom({
    key: 'exception_setup_unsaved_changes',
    default: {custom_dropdown: false, corp_code: false}
})

export const ExceptionTransactionImageIndex = atom({
    key: 'ExceptionTransactionImageIndex',
    default: 0
})

export const ExceptionLastFocusedImageBtn= atom({
    key: 'ExceptionLastFocusedImageBtn',
    default: {} as {[key: string]: 'icon' | 'image' | null}
})

export const ExceptionTransactionCurrentPage = atom({
    key: 'ExceptionTransactionCurrentPage',
    default: 0
})

export const ExceptionTransactionForcedPage = atom<undefined | number>({
    key: 'ExceptionTransactionForcedPage',
    default: undefined
})

export const AdditionalPaymentExceptionSetup = atom({
    key: 'AdditionalPaymentExceptionSetup',
    default: [] as IFields[]
})