import requests from "./apiRequest";
export interface AllExceptionBatchesRes {
    id: number
    batchNumber: string
    lockboxCode: string
    lockboxName: string
    processingDate: string
}
export interface ExceptionBatchDetailItemRes {
    id: number
    batchId: number
    batchNumber: string
    lockboxCode: string
    lockboxName: string
    corpCode: string
    corpCodeName: string
    traceNumber: string
    status: string
    amount: number
    amountFormatted: string
    lockedByUserId: number
    lockedByUserName: string
    exceptionReason?: string
    sequenceNumber: number
    escalatedUserName?: string
}

export interface Batches {
    batchId: number
    batchNumber: string
}

export interface ExceptionBatchesRes {

    processingDate: string
    corpCode: string
    corpCodeName: string
    totalExceptionsCount: number
    totalExceptionsCountFormatted: string
    outstandingExceptionsCount: number
    outstandingExceptionsCountFormatted: string
    completedExceptionsCount: number
    completedExceptionsCountFormatted: string
    returnedExceptionsCount: number
    returnedExceptionsCountFormatted: string
    escalatedExceptionsCount: number
    escalatedExceptionsCountFormatted: string
    rejectedExceptionsCount: number
    rejectedExceptionsCountFormatted: string
    batches: Batches[]
    batchItems: ExceptionBatchDetailItemRes[]
}

export interface BatchDetailMirror {
    batchId: number
    batchNumber: string
    total: number
    date: string
    id: number
    outstanding: number
    completed: number
    returned: number
    escalated: number
    rejected: number
    corpCode: string
}

export interface SelectBatchDetail {
    batchId: number
    batchNumber: string
    corpCode?: string
}

export interface SelectBatchDetailForLockbox {
    corpCode?: string
    processingDate?: string
    exceptionBatchId?: number
}

export interface ExceptionBatchDetailSummaryReportRequest {
    fileType: "Pdf" | "Csv"
    processingDate: string
    lockboxCode: string
    corpCode?: string
    exceptionBatchId?: number
}

export const allExceptionBatchesData = async (date: string): Promise<AllExceptionBatchesRes[]> => {
    let result = await requests.getParams('exception-batches/', { processingDate: date });
    return result;
}

export const exceptionBatchDetailData = async (lockboxCode: string, body: SelectBatchDetailForLockbox): Promise<ExceptionBatchesRes> => {
    let result = await requests.getParams(`lockboxes/${lockboxCode}/exception-batches`, body);
    return result;
}

export const generateExceptionBatchDetailReport = async (body: ExceptionBatchDetailSummaryReportRequest) => {
    await requests.post(`/lockboxes/${body.lockboxCode}/exception-batches/report?processingDate=${body.processingDate}&corpCode=${body.corpCode}&exceptionBatchId=${body.exceptionBatchId}`, body);
} 
