/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Checkbox, Loader, Select, TextInput, ErrorSummary } from '@ux/balance-react';
import { useEffect, useState, useRef, FormEvent } from "react";
import { NavLink } from 'react-router-dom-v6';
// @ts-ignore
import { IconEditPencil, IconTrash } from '@ux/balance-icons';
import { useRecoilState } from "recoil";
import Lockbox from "../API_Integrations/Lockbox";
import { createRemitter, createRemitterReport, deleteRemitter, editRemitter, getRemitter, getRemitters } from "../API_Integrations/Remitters";
import { BatchSummaryMirror, batch_summary_reqBody_lockbox, RemitterDataOptions, RemittersData, Remitter_Lockbox_ID, userState } from "../atom";
import ButtonContainer from '../components/ButtonContainer';
import ClientHeader from '../components/ClientHeader';
import CorpCodes from '../API_Integrations/CorpCodes';
import { ICorpCodes } from '../interfaces/ICorpCodes';
import CustomModal from '../components/CustomModal';
import DateDisplay from "../components/DateDisplay";
import ModalsContainer from '../components/ModalsContainer';
import Table from '../components/Table';
import { ILockbox_options } from "../interfaces/ICorrespondence";
import { ILockbox, ILockbox_req_body } from "../interfaces/iLockBox";
import { IRemittersCreateEdit, IRemitters_APIResponse } from '../interfaces/IRemitters';
import { useTitle } from "../utils/use-title";
import { useFocusOnElemnt } from '../utils/use-focus-on-element';
import { useRepeatAnnouncement } from '../utils/use-repeat-live-announcement';
interface ErrorMessage { ariaLabel: string, text: string, inputId: string }

const Remitters = () => {
    useTitle("Remitters");
    interface CorpCode {  label: string, value: string, isDisabled?: boolean }
    const [corpcode, setCorpcode] = useState<{value: string, options: CorpCode[]}>({value: 'select corp code', options: []});
    enum RemittersState { CurrentRemitters, SearchRemitters, EditRemitters, AddRemitters };
    const [edit_remitter_Id, setEdit_remitter_Id] = useRecoilState(Remitter_Lockbox_ID);
    const [remitters_data, setRemitters_data] = useRecoilState(RemittersData);
    const [lockbox_options, setLockbox_options] = useState<ILockbox_options[]>([]);
    const [remittersOptions, setRemittersOptions] = useRecoilState(RemitterDataOptions);
    const [lockbox, setLockbox] = useRecoilState(batch_summary_reqBody_lockbox);
    const [batchMirror, setBatchMirror] = useRecoilState(BatchSummaryMirror);
    const [loading, setLoding] = useState(false);
    const [search_start, setSearch_start] = useState(false);
    const [manageState, setManageState] = useState(RemittersState.CurrentRemitters);
    const [remitterCreateOrEdit, setRemitterCreateOrEdit] = useState<IRemittersCreateEdit>({ lockboxCode: '', routingNumber: '', accountNumber: '', name: '' });
    const [selectedRemitter, setSselectedRemitter] = useState('all');
    const [remitterDetail, setRemitterDetail] = useState('');
    const [selectedRows, setselectedRows] = useState<number[]>([]);
    const [checkedAllRows, setcheckedAllRows] = useState(false);
    const [modalIsOpenCSV, setModalIsOpenCSV] = useState(false);
    const [showSaveAddRemitterModal, setShowSaveAddRemitterModal] = useState(false);
    const [showSaveEditRemitterModal, setShowSaveEditRemitterModal] = useState(false);
    const [showSaveErrorModal, setShowSaveErrorModal] = useState(false);
    const [errorMessagetitle, setErrorMessagetitle] = useState('');
    const [errorMessagetext, setErrorMessagetext] = useState('');
    const [delete_User_Id, setDelete_User_Id] = useState(0);
    const [modalIsOpenDelete, setModalIsOpenDelete] = useState(false);
    const [showValidationLockboxID, setShowValidationLockboxID] = useState(false);
    const [errorMessagesSummary, seterrorMessagesSummary] = useState<ErrorMessage[]>([]);
    const [isvalidRoutingNo, setIsvalidRoutingNo] = useState(true);
    const [isvalidAccountNo, setIsvalidAccountNo] = useState(true);
    const [isvalidRemitterName, setIsvalidRemitterName] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [liveAnnouncementText, setLiveAnnouncementText] = useState("");
    const [lockboxNotSelected, setLockboxNotSelected] = useState(false);
    const errorSummaryRef = useRef<HTMLElement | null>(null);
    const initialTextRef = useRef<HTMLHeadingElement | null >(null);

    useFocusOnElemnt(initialTextRef);

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetchRemitterData();
            setRemitters_data(res);
            if (res.length === 0) {
                setManageState(RemittersState.SearchRemitters);
            }
            const result = await getLockboxData({
                lockboxNumber: '',
                lockboxName: ''
            });
            let corpCodeResult = await CorpCodes.list();
            if (corpCodeResult && corpCodeResult.length > 1) {
                let CorpCodeOptions = corpCodeResult.map((el: ICorpCodes) => ({
                    label: el.code,
                    value: el.code
                }));
                setCorpcode({ value: 'select corp code', options: [...[{ label: 'Select', value: 'select corp code', isDisabled: true, } ], ...CorpCodeOptions] });
            }
            getLockboxOptions(result);
            setLoding(false);
        }
        fetchData();
    }, []);

    useEffect(() => {
    }, [remitterCreateOrEdit]);

    const columnData = [
        { headerId: 'select', headerLabel: 'Select', rowCellTableRowComponentsArrayIndex: 0, headerCellTableRowComponentsArrayIndex: 1, type: 'component', disableSorting: true },
        { headerId: 'lockboxCode', headerLabel: 'Lockbox', rowCellTableRowComponentsArrayIndex: 3, type: 'component' },
        { headerId: 'routingNumber', headerLabel: 'Routing no.' },
        { headerId: 'accountNumber', headerLabel: 'Account no.' },
        { headerId: 'name', headerLabel: 'Remitter' },
        { headerId: 'modifiedOn', headerLabel: 'Last updated', rowCellTableRowComponentsArrayIndex: 4, type: 'component' },
        { headerId: 'modifiedBy', headerLabel: 'Last user' },
        { headerId: 'action', headerLabel: 'Edit or delete', rowCellTableRowComponentsArrayIndex: 2, type: 'component', disableSorting: true }
    ];

    const lockbox_list = [
        {
            isDisabled: true,
            label: 'Select',
            value: ''
        }
    ];
    const getLockboxData = async (ReqBody: ILockbox_req_body) => {
        let result = await Lockbox.listClientLockbox(ReqBody)
        if (result !== undefined) {
            return result;
        } else {
            console.log('item not found')
        }
    }
    const getLockboxOptions = (options: ILockbox[]) => {
        let LockboxOptions = options.map(lockbox => ({ label: lockbox.code, value: lockbox.code }));
        
        if (LockboxOptions.length === 1) {
            setLockbox_options([...LockboxOptions]);
            setRemitterCreateOrEdit({ ...remitterCreateOrEdit, lockboxCode: LockboxOptions[0].value });
        } else {
            setLockbox_options([...lockbox_list, ...LockboxOptions]);
        }
        return LockboxOptions;
    };
    const fetchRemitterData = async () => {
        return await getRemitters();
    }
    const fetchSingleRemitter = async (ID: number) => {
        return await getRemitter(ID);
    }

    const handleOnChangeSelectRemitter = (event: Event) => { setSselectedRemitter((event.target as HTMLInputElement).value) }
    const handleOnChangeRemitterDetail = (event: Event) => { setRemitterDetail((event.target as HTMLInputElement).value) }
    const handleOnChangeSelectLockbox = (event: Event) => { setRemitterCreateOrEdit({ ...remitterCreateOrEdit, lockboxCode: (event.target as HTMLInputElement).value }) }
    const handleOnChangeRoutingNo = (event: Event) => { setRemitterCreateOrEdit({ ...remitterCreateOrEdit, routingNumber: (event.target as HTMLInputElement).value }) }
    const handleOnChangeAccountNo = (event: Event) => { setRemitterCreateOrEdit({ ...remitterCreateOrEdit, accountNumber: (event.target as HTMLInputElement).value }) }
    const handleOnChangeRemitterName = (event: Event) => { setRemitterCreateOrEdit({ ...remitterCreateOrEdit, name: (event.target as HTMLInputElement).value }) }
    const HandleOnChangeSelectCorpCode = (event: Event) => {
        const corpCode = (event.target as HTMLInputElement).value;
        setCorpcode({ ...corpcode, value: corpCode });
    }

    const handleSelectRowLocations = (props: any, index: number) => {
        setselectedRows(prevSelectedRows => {
            if (prevSelectedRows.includes(props.id)) {
                return prevSelectedRows.filter(id => id !== props.id)
            } else {
                return [...prevSelectedRows, props.id]
            }
        });
    }

    const handleSelectAllRows = (e: Event) => {
        setcheckedAllRows(!checkedAllRows);
        const checked = (e.target as HTMLInputElement).checked;
        if (checked) {
            setselectedRows(remitters_data.map(option => option.id));

        } else {
            setselectedRows([]);
        }
    }
    const handleLockboxSelection = (row: any) => {
        setLockbox(row.lockboxCode);
        setBatchMirror({
            lockboxCode: row.lockboxCode,
            lockboxName: row.lockboxName || '',
            siteName: row.siteName || '',
            totalDeposits: row.totalDepositsFormatted,
            totalBatches: row.totalBatchesFormatted,
            totalTransactions: row.totalTransactionsFormatted,
            status: row.status
        });
    }

    const fetchSearchData = async (body: {filters: string}) => {
        return await getRemitters(body);
    }

    let announcement  = "Loading, please wait.";
    const repeatLoadingAnnouncement = () => {
        setLiveAnnouncementText(announcement);
        announcement += '.';
    };

    useRepeatAnnouncement({repeatLoadingAnnouncement, loadingSearch});

    const HandleSearchRemitter = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSearch_start(true);
        setLoadingSearch(true);
        setLiveAnnouncementText("Loading, please wait.");
        let formattedSearchBody: {filters: string} = {filters: ''};
        if(selectedRemitter === 'all') {
            formattedSearchBody = {filters: `(lockboxCode|name|accountNumber|routingNumber)@=*${remitterDetail}`};
        } else {
            formattedSearchBody = {filters: `(${selectedRemitter})@=*${remitterDetail}`};
        }
        try {
            const result = await fetchSearchData(formattedSearchBody);
            setRemitters_data(result);
            if (result && result.length > 1) {
                setLiveAnnouncementText(`${result.length} results found.`);
            } else if (result && result.length === 1) {
                setLiveAnnouncementText(`${result.length} result found.`);
            } else {
                setLiveAnnouncementText("No results found.");
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingSearch(false);
        }
    }

    const HandleGoBack = () => {
        if(remitters_data.length > 0) {
            setManageState(RemittersState.CurrentRemitters);
        } else {
            setManageState(RemittersState.SearchRemitters);
        }
        setRemitterCreateOrEdit({ lockboxCode: '', routingNumber: '', accountNumber: '', name: '' });
        setErrorMessagetext('');
        setErrorMessagetitle('');
        handleEmptyErrorMessages();
    }
    const HandleRefresh = () => {
        setLoding(true);
        setManageState(RemittersState.CurrentRemitters);
        setRemitterCreateOrEdit({ lockboxCode: '', routingNumber: '', accountNumber: '', name: '' });
        fetchRemitterData().then(res => {
            setRemitters_data(res);
            if (res.length === 0) {
                setManageState(RemittersState.SearchRemitters);
            }
            setLoding(false);
            setShowSaveAddRemitterModal(false);
            setShowSaveEditRemitterModal(false);
            setModalIsOpenDelete(false);
        });
    }

    const HandleCreateNewRemitter = async () => {
        setManageState(RemittersState.AddRemitters);
        if (lockbox_options.length !== 1) {
            setRemitterCreateOrEdit({ lockboxCode: '', routingNumber: '', accountNumber: '', name: '' });
        } else {
            setRemitterCreateOrEdit({ lockboxCode: lockbox_options[0].value, routingNumber: '', accountNumber: '', name: '' });
        }
        setErrorMessagetext('');
        setErrorMessagetitle('');
        setSearch_start(false);
        handleEmptyErrorMessages();
    }

    const HandleSaveCreateRemitter = async (event: Event) => {
        event.preventDefault();
        
        if (remitterCreateOrEdit.lockboxCode === '') {
            setShowValidationLockboxID(true);
            setLoding(false);
        } else {
            let catchError = setErrorSummaryFields();
            if (catchError.length === 0) {
            setLoding(true);
            await createRemitter(remitterCreateOrEdit).then(() => {
                setLoding(false);
                setShowSaveAddRemitterModal(true);
                setManageState(RemittersState.CurrentRemitters);
            }).catch((error) => {
                const errorProperty = Object.keys(error.errors)[0];
                setErrorMessagetitle(error.title);
                setErrorMessagetext(error.errors[errorProperty]);
                setShowSaveErrorModal(true);
                setLoding(false);
            });
        }
    }
    }

    const HandleEditRemitter = (ID: number) => {
        setEdit_remitter_Id(ID);
        setLoding(true);
        setManageState(RemittersState.EditRemitters);
        setSearch_start(false);
        fetchSingleRemitter(ID).then(res => {
            const updatedRemitter: IRemittersCreateEdit = {
                lockboxCode: res.lockboxCode,
                routingNumber: res.routingNumber,
                accountNumber: res.accountNumber,
                name: res.name
            };
            setRemitterCreateOrEdit(updatedRemitter);
            setErrorMessagetext('');
            setErrorMessagetitle('');
            setLoding(false);
        });
        handleEmptyErrorMessages();
    }

    const HandleSaveEditRemitter = async (event: Event) => {
        event.preventDefault();
        let catchError = setErrorSummaryFields();

        if (catchError.length === 0) {
            setLoding(true);
            const updatedRemitter = { ...remitterCreateOrEdit };
            await editRemitter(edit_remitter_Id, updatedRemitter).then(() => {
                setLoding(false);
                setShowSaveEditRemitterModal(true);
                setManageState(RemittersState.CurrentRemitters);
            }).catch((error) => {
                const errorProperty = Object.keys(error.errors)[0];
                setErrorMessagetitle(error.title);
                setErrorMessagetext(error.errors[errorProperty]);
                setShowSaveErrorModal(true);
                setLoding(false);
            });
        }
    }

    const setErrorSummaryFields = () => {
        const errors: ErrorMessage[] = [];


        if (remitterCreateOrEdit.routingNumber.trim() === "") {
            errors.push({ ariaLabel: "Enter routing number (required)", text: "Enter routing number (required)", inputId: 'routingNumber' });
            setIsvalidRoutingNo(false);
        } else {
            setIsvalidRoutingNo(true);
        }

        if (remitterCreateOrEdit.accountNumber.trim() === '') {
            errors.push({ ariaLabel: "Enter account number (required)", text: "Enter account number (required)", inputId: 'accountNumber' });
            setIsvalidAccountNo(false);
        } else {
            setIsvalidAccountNo(true);
        }

         if (remitterCreateOrEdit.name.trim() === '') {
            errors.push({ ariaLabel: "Enter remitter name (required)", text: "Enter remitter name (required)", inputId: 'name' });
            setIsvalidRemitterName(false);
        } else {
            setIsvalidRemitterName(true);
        }

        seterrorMessagesSummary(errors);
        return errors;
    };

    const handleEmptyErrorMessages = () => {
        seterrorMessagesSummary([]);
        setIsvalidRoutingNo(true);
        setIsvalidRemitterName(true);
        setIsvalidAccountNo(true);
    };

    const HandleDeleteRemitter = async (ID: number) => {
        setSearch_start(false);
        setDelete_User_Id(ID);
        setModalIsOpenDelete(true);
        
    }
    const handleConfirmDelete = async () => {
        setLoding(true);
        await deleteRemitter(delete_User_Id).then(() => {
            HandleRefresh();
        });
    }
    //download CSV
    const handleDownload = () => {
        if (selectedRows.length > 0) {
            createRemitterReport({ selectedRemitters: selectedRows, fileType: "csv" });
            setModalIsOpenCSV(true);
        } else {
            setLockboxNotSelected(true);
        }
    }

    return (
        <main>
            <ClientHeader showClientName={true} showBreadcrumbs={false} />
            <h4 ref={initialTextRef} tabIndex={-1} className='heading-title heading-focus-text'>Remitters<span className='pipe'>|</span>{manageState === RemittersState.CurrentRemitters ? 'Current remitters' : manageState === RemittersState.EditRemitters ? 'Edit remitter' : manageState === RemittersState.SearchRemitters ? 'Search remitter' : 'Add a remitter'}</h4>
            <div>
                {manageState === RemittersState.AddRemitters || manageState === RemittersState.EditRemitters ?
                    <div>
                        <div className='go-back-buttons-container'>
                            <Button
                                classes="go-back"
                                clickMethod={() => { HandleGoBack() }}
                                text="Go back to current remitters"
                                variant="text-primary"
                                icon='IconChevronLeft'
                                iconPosition='left'
                            />
                        </div>
                        <div className='filter-main-continer'>
                            {corpcode.options && corpcode.options.length < 1 ?
                                <div className='filter-top-container'>

                                    <Select
                                        label="Select your Lockbox ID (required)"
                                        name="Select Lockbox ID"
                                        size='small'
                                        classes='width-450'
                                        options={lockbox_options}
                                        handleOnChange={handleOnChangeSelectLockbox}
                                        value={remitterCreateOrEdit.lockboxCode}
                                    />
                                </div>
                               :
                                <div className='filter-top-container'>
                                    <div className="download-dropdown-container">
                                        <div>
                                            <Select
                                                label="Select your Lockbox ID (required)"
                                                name="Select Lockbox ID"
                                                size='small'
                                                classes='width-322'
                                                options={lockbox_options}
                                                handleOnChange={handleOnChangeSelectLockbox}
                                                value={remitterCreateOrEdit.lockboxCode}
                                            />
                                        </div>
                                        <div className='left-24-gap-filters'>
                                            <Select
                                                label="Corp Code"
                                                name="Select Corp Code"
                                                size='small'
                                                classes='width-322'
                                                options={corpcode.options}
                                                handleOnChange={handleOnChangeSelectLockbox}
                                                value={corpcode.value}
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                            }
                            <div className='top-gap-24'>
                                <div className="download-dropdown-container">
                                    <div>
                                        <TextInput
                                            handleOnChange={handleOnChangeRoutingNo}
                                            initialValue={remitterCreateOrEdit.routingNumber}
                                            inputFieldButtonTestId="input-field-routing-number"
                                            label="Enter routing number (required)"
                                            type="text"
                                            classes='width-322'
                                            size='small'
                                            errorMessages={["Enter a routing number."]}
                                            isValid={isvalidRoutingNo}
                                            inputId="routingNumber"
                                        />
                                    </div>
                                    <div className='left-24-gap-filters'>
                                        <TextInput
                                            handleOnChange={handleOnChangeAccountNo}
                                            initialValue={remitterCreateOrEdit.accountNumber}
                                            inputFieldButtonTestId="input-field-account-number"
                                            label="Enter account number (required)"
                                            type="text"
                                            classes='width-322'
                                            size='small'
                                            errorMessages={["Enter an account number."]}
                                            isValid={isvalidAccountNo}
                                            inputId="accountNumber"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='top-gap-24'>
                                <TextInput
                                    handleOnChange={handleOnChangeRemitterName}
                                    initialValue={remitterCreateOrEdit.name}
                                    inputFieldButtonTestId="input-field-remitter-name"
                                    label="Enter remitter name (required)"
                                    type="text"
                                    classes='width-450'
                                    size='small'
                                    errorMessages={["Enter a remitter name."]}
                                    isValid={isvalidRemitterName}
                                    inputId="name"
                                />
                            </div>
                            <div>
                                <ButtonContainer remitter>
                                    {manageState === RemittersState.AddRemitters ?
                                        <Button
                                            clickMethod={(e: Event) => { HandleSaveCreateRemitter(e) }}
                                            size="medium"
                                            text="Add"
                                        />
                                        : manageState === RemittersState.EditRemitters ?
                                            <Button
                                                clickMethod={(e: Event) => { HandleSaveEditRemitter(e) }}
                                                size="medium"
                                                text="Update"
                                            />
                                            :
                                            <></>
                                    }
                                </ButtonContainer>
                            </div>
                        </div>
                        <ModalsContainer noGap>
                        <CustomModal
                            okRefreshType 
                            Refresh={() => {
                                    setShowSaveErrorModal(false);
                                    setErrorMessagetext('');
                                    setErrorMessagetitle('');
                                }}
                                ModalText={errorMessagetext}
                                ModalTitle={errorMessagetitle}
                                ModalIsOpen={showSaveErrorModal}
                                CloseModal={() => {
                                    setShowSaveErrorModal(false);
                                    setErrorMessagetext('');
                                    setErrorMessagetitle('');
                                }}
                        />
                            <CustomModal
                                okType
                                ModalText='Please select a Lockbox ID to start your search.'
                                ModalTitle='Required information missing'
                                ModalIsOpen={showValidationLockboxID}
                                CloseModal={() => { setShowValidationLockboxID(false) }} />
                        </ModalsContainer>
                    </div>
                    : manageState === RemittersState.SearchRemitters ?
                        <div>
                            <p>
                                <span className='zero-state'>You don’t have any remitters yet.</span>&nbsp;
                                <Button
                                    classes="go-back"
                                    clickMethod={() => { HandleCreateNewRemitter() }}
                                    text="Add a new remitter."
                                    variant="text-primary"
                                />
                            </p>
                        </div>
                    :
                    <form className="filter-main-container" onSubmit={HandleSearchRemitter}>
                        <div className="download-dropdown-container">
                            <Select
                                label="Select remitter data"
                                name="Select remitter data"
                                size='small'
                                classes='width-300'
                                options={remittersOptions}
                                handleOnChange={handleOnChangeSelectRemitter}
                                value={selectedRemitter}
                            />
                            <div className='left-24-gap-filters'>
                                <TextInput
                                    handleOnChange={handleOnChangeRemitterDetail}
                                    initialValue={remitterDetail}
                                    inputFieldButtonTestId="input-field-remitter-detail"
                                    label="Enter remitter detail"
                                    type="text"
                                    classes='width-450'
                                    size='small'
                                />
                            </div>
                            <ButtonContainer remitter>
                                <Button
                                    classes="left-24-gap-filters"
                                    size="medium"
                                    text="Search"
                                    type="submit"
                                    isLoading={loadingSearch}
                                />
                                <Button
                                    classes="left-24-gap-filters"
                                    clickMethod={() => { HandleCreateNewRemitter() }}
                                    text="Add new remitter"
                                    variant="secondary"
                                    size="medium"
                                />
                            </ButtonContainer>
                        </div>
                    </form>
                }
            </div>

            {manageState === RemittersState.CurrentRemitters ?
                <div className='loader-section'>
                    <Loader isLoading={loading} />
                    {remitters_data.length > 0 ?
                        <div>
                            <ModalsContainer bottomGap>
                                <CustomModal
                                    ButtonTitle='Download selected as CSV file'
                                    ModalIsOpen={modalIsOpenCSV} OpenModal={handleDownload}
                                    CloseModal={() => { setModalIsOpenCSV(false) }}
                                    ModalText='Your CSV file has been requested and will be available in Reports.'
                                    ModalTitle='Request submitted'
                                />
                            </ModalsContainer>
                            <span aria-live='polite' className='announcement-text'>{liveAnnouncementText}</span>
                            <Table
                                caption="Remitters"
                                columnData={columnData}
                                data={remitters_data}
                                showSupplementalStatus
                                supplementalStatus={`${selectedRows.length} of ${remitters_data.length} ${remitters_data.length > 1 ? 'rows' : 'row'} selected for download`}
                                tableRowComponents={[
                                    (props: any) => (
                                        <div className='center-select'>
                                            <Checkbox
                                                isChecked={selectedRows.includes(props.row.original.id)}
                                                inputId={props.row.original.headerId}
                                                handleOnChange={() => handleSelectRowLocations(props.row.original, props.row.index)}
                                                label=""
                                                aria-labelledby="Select"
                                            />
                                        </div>
                                    ),
                                    (props: any) => (
                                        <div className='table-select-all'>
                                            <span>Select all</span>
                                            <Checkbox
                                                isChecked={checkedAllRows}
                                                handleOnChange={(e: Event) => handleSelectAllRows(e)}
                                                label=""
                                                aria-labelledby="Select all"
                                            />
                                        </div>
                                    ),
                                    (props: any) => (
                                        <div>
                                            {props.row.original.status === "Deleted" ?
                                                <></>
                                                :
                                                <div className='report-actions'>
                                                    <span className='action-icon'><IconEditPencil style={{ color: '#007C8C' }} width="20" height="20" /></span>
                                                    <Button
                                                        clickMethod={() => { HandleEditRemitter(props.row.original.id) }}
                                                        text="Edit"
                                                        variant="text-primary"
                                                    /><span className='action-separator'> | </span>
                                                    <span className='action-icon'><IconTrash style={{ color: '#007C8C' }} width="18" height="18" /></span>
                                                    <Button
                                                        clickMethod={() => { HandleDeleteRemitter(props.row.original.id) }}
                                                        text="Delete"
                                                        variant="text-primary"
                                                    />
                                                </div>
                                            }
                                        </div>
                                    ),
                                    (props: any) => (
                                        <NavLink
                                            to={`/batch-summary/${props.row.original.lockboxCode}`}
                                            onClick={() => handleLockboxSelection(props.row.original)}>
                                            {props.row.original.lockboxCode}
                                        </NavLink>
                                    ),
                                    (props: any) => (
                                        <DateDisplay date={props.row.original.modifiedOn} format="paddedShortDateTime" gmt />
                                    )
                                ]}
                            />
                            
                        </div>
                        : (remitters_data.length === 0 && search_start) ?
                            <p className='zero-state'>No results found.</p>
                            :
                            <></>
                    }
                    <ModalsContainer noGap>
                        <CustomModal
                            yesOrNoType
                            ModalIsOpen={modalIsOpenDelete}
                            CloseModal={() => { setModalIsOpenDelete(false) }}
                            noText='No, I do not want to delete it'
                            yesText='Yes, I want to delete it'
                            yesClickMethod={handleConfirmDelete}
                            ModalText='Are you sure you want to delete this remitter? The information will no longer be available.'
                            ModalTitle='Deleting remitter' />
                        <CustomModal
                            okRefreshType
                            Refresh={() => { HandleRefresh(); }}
                            ModalText="Your new remitter has been added. You can edit or delete the information in Current remitters."
                            ModalTitle="Remitter added"
                            ModalIsOpen={showSaveAddRemitterModal}
                            CloseModal={() => {
                                setShowSaveAddRemitterModal(false);
                            }}
                        />
                        <CustomModal
                            okRefreshType
                            Refresh={() => { HandleRefresh(); }}
                            ModalText="Your remitter has been updated. You can edit or delete the information in Current remitters."
                            ModalTitle="Remitter updated"
                            ModalIsOpen={showSaveEditRemitterModal}
                            CloseModal={() => {
                                setShowSaveEditRemitterModal(false);
                            }}
                        />
                        <CustomModal
                            okType
                            ModalText="Please select one or more remitters before proceeding to download."
                            ModalTitle="No remitters selected"
                            ModalIsOpen={lockboxNotSelected}
                            CloseModal={() => {
                                setLockboxNotSelected(false);
                            }}
                        />
                    </ModalsContainer>
                </div>
                :
                <div className='loader-section'>
                    <Loader isLoading={loading} />
                    <div>


                    </div>
                </div>
            }
        </main>
    );
};

export default Remitters;
