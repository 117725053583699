import requests from "./apiRequest";
import axios from "axios";
import { IAlerts, IAlertsCreateEdit } from "../interfaces/IAlerts";

export const getAlerts = async (): Promise<IAlerts[]> => {
  let Alerts = await requests.get("/Alerts");
  return Alerts;
}
export const getAlert = async (id: number): Promise<IAlertsCreateEdit> => {
  let Alert = await requests.get("/Alerts/" + id);
  return Alert;
}

export const createAlert = async (Alert: IAlertsCreateEdit) => {
      await axios.post("/Alerts", Alert);
}
export const editAlert = async (id: number, Alert: IAlertsCreateEdit) => {
      await axios.post("/Alerts/" + id, Alert);
}

export const deleteAlert = async (id: number) => {
  await requests.delete("/Alerts/" + id);
}