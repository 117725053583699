/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-ignore
import { Button, Modal } from '@ux/balance-react';
import { useRecoilValue } from 'recoil';
import { DynamicPageName } from '../atom';

function ClientSelectionModal(props: any) {
    const dynamic_page_name = useRecoilValue(DynamicPageName);

    return (
        <div>
            <Modal
                isOpen={props.ModalIsOpen}
                ariaLabel="Client slection Modal"
                closeMethod={props.CloseModal}
                modalCTAs={<Button clickMethod={props.CloseModal} dataTestId="modal_close_button" text="Ok" variant="primary" size='small' />}
                modalTitle=""
            >
                <h5>Client information missing</h5>
                <p
                    className="text-style-body-2"
                    style={{
                        marginTop: '1rem',
                        maxWidth: '100%'
                    }}
                >
                    {props.clientMaintenance ? <span>Please add client information to continue.</span> : <span>Please enter a client to go to {dynamic_page_name}.</span> }
                </p>
            </Modal>
        </div>
    )
}

export default ClientSelectionModal;