/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Loader, TextInput } from '@ux/balance-react';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom-v6';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { addClientEmulation, searchClients } from '../API_Integrations/ClientLockboxSearch';
import { getLockboxesList } from '../API_Integrations/exceptionManagement';
import { ClientNameBanker, ClientSelected, Client_code, LockboxName, LockboxNumber, LockboxSearchData, ShowCientModal, Exception_Banker_CurrentTab, ExceptionSubscribe, CurrentEnvironment } from '../atom';
import ClientHeader from '../components/ClientHeader';
import ClientSelectionModal from '../components/ClientSelectionModal';
import ModalsContainer from '../components/ModalsContainer';
import Table from '../components/Table';
import { useFocusOnElemnt } from '../utils/use-focus-on-element';
import { useTitle } from '../utils/use-title';
import { useRepeatAnnouncement } from '../utils/use-repeat-live-announcement';
import { getEnvironmentFromHostname } from '../utils/config-utils';

function BankerLockboxSearch() {
    useTitle("Lockbox search banker");

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [search_start, setSearch_start] = useState(false);
    const [liveAnnouncementText, setLiveAnnouncementText] = useState("");
    const [lockboxNumber, setLockboxNumber] = useRecoilState(LockboxNumber);
    const [lockboxName, setLockboxName] = useRecoilState(LockboxName);
    const [data_lockbox, setData_lockbox] = useRecoilState(LockboxSearchData);
    const setClientCode = useSetRecoilState(Client_code);
    const setClientSelected = useSetRecoilState(ClientSelected);
    const setClientName = useSetRecoilState(ClientNameBanker);
    const [modalIsOpen, setModalIsOpen] = useRecoilState(ShowCientModal);
    const setBankerExceptionCurrentTab = useSetRecoilState(Exception_Banker_CurrentTab);
    const setSubscribeException = useSetRecoilState(ExceptionSubscribe);
    const setCurrentEnvironment = useSetRecoilState(CurrentEnvironment);
    const initialTextRef = useRef<HTMLHeadingElement | null>(null);

    useFocusOnElemnt(initialTextRef);

    const HandleOnChangeLockboxCode = (event: Event) => setLockboxNumber((event.target as HTMLInputElement).value)
    const HandleOnChangeLockboxName = (event: Event) => setLockboxName((event.target as HTMLInputElement).value)

    useEffect(() => {
    }, [lockboxNumber]);
    useEffect(() => {
    }, [lockboxName]);
    useEffect(() => {
    }, [data_lockbox]);
    useEffect(() => {
    }, [modalIsOpen]);
    useEffect(() => {
        setClientSelected(false);
        setClientName('');
        setLockboxNumber('');
        setLockboxName('');
        setData_lockbox([]);
        setBankerExceptionCurrentTab('REPORTINGSUMMARY');
    }, []);

    let announcement  = "Loading, please wait.";
    const repeatLoadingAnnouncement = () => {
        setLiveAnnouncementText(announcement);
        announcement += '.';
    };

    useRepeatAnnouncement({loadingSearch, repeatLoadingAnnouncement});

    const handleLockboxSearch = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoadingSearch(true);
        setLiveAnnouncementText("Loading, please wait.");
        setSearch_start(false);
        try {
            const result = await searchClients({
                lockboxCode: lockboxNumber,
                lockboxName: lockboxName
            });
            if (result !== undefined) {
                setData_lockbox(result);
                if (result && result.length > 1) {
                    setLiveAnnouncementText(`${result.length} results found.`);
                } else if (result && result.length === 1) {
                    setLiveAnnouncementText(`${result.length} result found.`);
                } else {
                    setLiveAnnouncementText("No results found.");
                }
            } else {
                console.log('item not found')
            }

        } catch (error) {
            console.log(error);
        } finally {
            setLoadingSearch(false);
            setSearch_start(true);
        }
    }

    const handleBankerSummarySelection = (row: any) => {
        setLoading(true);
        setClientCode(row.code);
        const environment = getEnvironmentFromHostname(window.location.hostname);
        setCurrentEnvironment(environment);
        addClientEmulation(row.code).then(() => {
            setClientSelected(true);
            setClientName(row.name);
            getLockboxesList().then((result) => {
                const hasExceptions = result.some((lockbox) => lockbox.type === "Retail" && lockbox.isOnlineDecision);
                
                if(hasExceptions) {
                    setSubscribeException({ type: "Retail", subscribed: true });
                }
            });
            navigate(`/banker-lockbox-summary/${row.code}`);
        }); 
    };

    return (
        <main>
            <ClientHeader bankerLockboxSearch={true} showBreadcrumbs={false} />
            <h4 ref={initialTextRef} tabIndex={-1} className='lockbox-search-title heading-focus-text'>Client search</h4>

            <form className='lockbox-search-control' onSubmit={handleLockboxSearch}>
                <TextInput
                    label="Lockbox ID"
                    initialValue={lockboxNumber}
                    size='small'
                    handleOnChange={HandleOnChangeLockboxCode}
                    type="text"
                    classes="lockbox-search-input-208"
                />

                <TextInput
                    label="Lockbox name"
                    initialValue={lockboxName}
                    size='small'
                    handleOnChange={HandleOnChangeLockboxName}
                    type="text"
                    classes="lockbox-search-input-322 left-24-gap-filters"
                />
                <div className='lockbox-search-button'>
                    <Button
                        type="submit"
                        size="medium"
                        text="Search"
                        isLoading={loadingSearch}
                    />
                </div>
            </form>
            <ModalsContainer>
                <div>
                    <ClientSelectionModal ModalIsOpen={modalIsOpen} CloseModal={() => { setModalIsOpen(false) }} />
                </div>
            </ModalsContainer>
            <div className='loader-section'>

                <Loader isLoading={loading} />
                <span aria-live='polite' className='announcement-text'>{liveAnnouncementText}</span>
                {(data_lockbox.length > 0) ?
                    <>
                        <div className='search-lockboxes-table'>
                            <Table
                                ariaDescribedBy={null}
                                caption='Lockbox search table'
                                classes=""
                                columnData={
                                    [
                                        {
                                            headerId: 'code',
                                            headerLabel: 'Client ID',
                                            rowCellTableRowComponentsArrayIndex: 0,
                                            type: 'component'
                                        },
                                        {
                                            headerId: 'name',
                                            headerLabel: 'Client name '
                                        },
                                    ]
                                }
                                data={data_lockbox}
                                tableRowComponents={[
                                    (props: any) => (
                                        <Button
                                            text={props.row.original.code}
                                            clickMethod={() => handleBankerSummarySelection(props.row.original)}
                                            variant="text-primary"
                                        />

                                    )
                                ]}
                            />
                        </div>
                    </>
                    :
                    <></>
                }
                <div>
                    {(data_lockbox.length === 0 && search_start) ?
                        <p className='zero-state'>No results found.</p>
                        :
                        <></>
                    }
                </div>
            </div>
        </main>);
}

export default BankerLockboxSearch;
