import { useRef } from 'react';
import DatePicker from 'react-datepicker';
// @ts-ignore
import { Popover } from '@ux/balance-react';

function CalendarDateInput(props: any) {
  const EmptyError = props.emptyError || false;
  let datePickerRef = useRef<DatePicker | null>(null);
  const calendarOpenedByuser = useRef(false);
  const openDatePicker = () => {
    calendarOpenedByuser.current = true;
    if(datePickerRef) { 
      datePickerRef.current?.setOpen(true);
    }
  }

  const handleCalendarOpen = () => {
    if (!calendarOpenedByuser) {
      return;
    }

    const monthSelectClass = document.querySelector('.react-datepicker__month-select');
    const yearSelectClass = document.querySelector('.react-datepicker__year-select');
    if (monthSelectClass) {
      monthSelectClass.setAttribute('aria-label', 'Select month');
    }

    if (yearSelectClass) {
      yearSelectClass.setAttribute('aria-label', 'Select year');
    }

  }
  const today = new Date();
  const minDate = new Date(today.getFullYear() - 7, today.getMonth(), today.getDay());
  const currentYear = today.getFullYear();
  let maxDate = new Date(currentYear, 11, 31);
  if(props.maxYearOffset !== undefined) {
    maxDate = new Date(currentYear + props.maxYearOffset, 11, 31);
  }

  return (
    <div className={props.startDateWithEndPopover ? "width-208 input-box" : 'width-208 input-box align-popover-end-date'} >
      <div className={props.leftGap ? "left-24-gap-filters" : ''}>
        <div className='calender-label'>
          <label htmlFor={props.id} className={EmptyError ? 'error-label' : ''}>
            <p className={props.startDateWithEndPopover ? "calendar-label align-popover-label-start-date" : "calendar-label"}>{props.label}</p>
          </label>
          {props.showPopover ? <div className='calender-label-popover'><Popover icon="IconInfoCircle" size="small">{props.popoverText}</Popover></div> : <></>}
        </div>
        <div className='calendar-date-picker'>
          <span onClick={openDatePicker} className='calender-icon' id={EmptyError ? props.id : null}>
            {!EmptyError ?
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M8.6 12.1C8.6 12.7075 8.10751 13.2 7.5 13.2C6.89249 13.2 6.4 12.7075 6.4 12.1C6.4 11.4924 6.89249 11 7.5 11C8.10751 11 8.6 11.4924 8.6 12.1Z" fill="#007C8C" />
                <path d="M12 13.2C12.6075 13.2 13.1 12.7075 13.1 12.1C13.1 11.4924 12.6075 11 12 11C11.3925 11 10.9 11.4924 10.9 12.1C10.9 12.7075 11.3925 13.2 12 13.2Z" fill="#007C8C" />
                <path d="M16.5 13.2C17.1075 13.2 17.6 12.7075 17.6 12.1C17.6 11.4924 17.1075 11 16.5 11C15.8925 11 15.4 11.4924 15.4 12.1C15.4 12.7075 15.8925 13.2 16.5 13.2Z" fill="#007C8C" />
                <path fillRule="evenodd" clipRule="evenodd" d="M7 2.19995C7.40784 2.19995 7.7444 2.50514 7.79377 2.8996L7.8 2.99995V3.99995H16.2V2.99995C16.2 2.55812 16.5582 2.19995 17 2.19995C17.4078 2.19995 17.7444 2.50514 17.7938 2.8996L17.8 2.99995V3.99995H18C20.2091 3.99995 22 5.70389 22 7.80581V18.1941C22 20.296 20.2091 22 18 22H6C3.79086 22 2 20.296 2 18.1941V7.80581C2 5.70389 3.79086 3.99995 6 3.99995H6.2V2.99995C6.2 2.55812 6.55817 2.19995 7 2.19995ZM18.15 7.99995C19.2822 7.99995 20.2 8.85875 20.2 9.91813V18.2818C20.2 19.3412 19.2822 20.2 18.15 20.2H5.85C4.71782 20.2 3.8 19.3412 3.8 18.2818V9.91813C3.8 8.85875 4.71782 7.99995 5.85 7.99995H18.15Z" fill="#007C8C" />
              </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M8.6 12.1C8.6 12.7075 8.10751 13.2 7.5 13.2C6.89249 13.2 6.4 12.7075 6.4 12.1C6.4 11.4924 6.89249 11 7.5 11C8.10751 11 8.6 11.4924 8.6 12.1Z" fill="#BE1E18" />
                <path d="M12 13.2C12.6075 13.2 13.1 12.7075 13.1 12.1C13.1 11.4924 12.6075 11 12 11C11.3925 11 10.9 11.4924 10.9 12.1C10.9 12.7075 11.3925 13.2 12 13.2Z" fill="#BE1E18" />
                <path d="M16.5 13.2C17.1075 13.2 17.6 12.7075 17.6 12.1C17.6 11.4924 17.1075 11 16.5 11C15.8925 11 15.4 11.4924 15.4 12.1C15.4 12.7075 15.8925 13.2 16.5 13.2Z" fill="#BE1E18" />
                <path fillRule="evenodd" clipRule="evenodd" d="M7 2.19995C7.40784 2.19995 7.7444 2.50514 7.79377 2.8996L7.8 2.99995V3.99995H16.2V2.99995C16.2 2.55812 16.5582 2.19995 17 2.19995C17.4078 2.19995 17.7444 2.50514 17.7938 2.8996L17.8 2.99995V3.99995H18C20.2091 3.99995 22 5.70389 22 7.80581V18.1941C22 20.296 20.2091 22 18 22H6C3.79086 22 2 20.296 2 18.1941V7.80581C2 5.70389 3.79086 3.99995 6 3.99995H6.2V2.99995C6.2 2.55812 6.55817 2.19995 7 2.19995ZM18.15 7.99995C19.2822 7.99995 20.2 8.85875 20.2 9.91813V18.2818C20.2 19.3412 19.2822 20.2 18.15 20.2H5.85C4.71782 20.2 3.8 19.3412 3.8 18.2818V9.91813C3.8 8.85875 4.71782 7.99995 5.85 7.99995H18.15Z" fill="#BE1E18" />
              </svg>
            }
          </span>
          <DatePicker selected={props.selected} onChange={props.onChange} name={props.name} id={props.id} dateFormat='MM/dd/yyyy' showMonthDropdown showYearDropdown dropdownMode="select" popperPlacement='bottom-start' ref={datePickerRef} onCalendarOpen={handleCalendarOpen}
            minDate={minDate} maxDate={maxDate} className={EmptyError ? 'input-date-error' : ''}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarDateInput;
