import UTIF from "utif";

export const replaceImages = () => {
    let images = document.getElementsByTagName("img");
    let suffixes = ["tif", "tiff", "dng", "cr2", "nef"];

    for (var i = 0; i < images.length; i++) {
        let image = images[i];
        let extension = getFileExtension(image);

        if (extension && suffixes.indexOf(extension) !== -1) {
            downloadImage(image);
        }
    }
}

const downloadImage = (image: HTMLImageElement) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", image.src);
    xhr.responseType = "arraybuffer";
    xhr.onload = e => imageLoaded(e, image);
    xhr.send();
}

const getFileExtension = (image: HTMLImageElement) => {
    let src = image.getAttribute("src");
    let suffix = src?.split('.').pop()?.toLowerCase();
    if (suffix === undefined) { return; }

    let adjustedSuffixes = /[^?]*/.exec(suffix);
    return adjustedSuffixes ? adjustedSuffixes[0] : undefined;
}

const bufferToURI = (buff: Buffer | ArrayBuffer) => {
    var ifds: any = UTIF.decode(buff);
    var vsns = ifds, ma = 0, page = vsns[0];
    if (ifds[0].subIFD) vsns = vsns.concat(ifds[0].subIFD);

    for (var i = 0; i < vsns.length; i++) {
        var img = vsns[i];
        if (img["t258"] == null || img["t258"].length < 3) continue;
        var ar = img["t256"] * img["t257"];

        if (ar > ma) {
            ma = ar;
            page = img;
        }
    }

    // Decode the image
    UTIF.decodeImage(buff, page);
    var rgba = UTIF.toRGBA8(page), width = page.width, height = page.height;

    // Create the canvas
    var canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    // Add the image to the canvas
    var context = canvas.getContext("2d");
    var imageData = new ImageData(new Uint8ClampedArray(rgba.buffer), width, height);
    context?.putImageData(imageData, 0, 0);
    return canvas.toDataURL();
}

const imageLoaded = (e: any, image: HTMLImageElement) => {
    image.setAttribute("src", bufferToURI(e.target.response));
}
