import requests from "./apiRequest";
import axios from "axios";
import { IRemitters, IRemittersCreateEdit } from "../interfaces/IRemitters";

export interface RemitterReportRequest {
    selectedRemitters?: number[]
    fileType: "csv"
}

export const getRemitters = async (body?: {filters: string}): Promise<IRemitters[]> => {
  let remitters;
  if(body) {
    remitters = await requests.getParams("/remitters?sorts=-modifiedOn", body);
  } else {
    remitters = await requests.get("/remitters?sorts=-modifiedOn");
  }
  return remitters;
}
export const getRemitter = async (id: number): Promise<IRemittersCreateEdit> => {
  let remitter = await requests.get("/remitters/" + id);
  return remitter;
}

export const createRemitter = async (remitter: IRemittersCreateEdit) => {
      await axios.post("/remitters", remitter);
}

export const createRemitterReport = async (request: RemitterReportRequest) => {
    await axios.post("/remitters/report", request);
}

export const editRemitter = async (id: number, remitter: IRemittersCreateEdit) => {
      await axios.post("/remitters/" + id, remitter);
}

export const deleteRemitter = async (id: number) => {
  await requests.delete("/remitters/" + id);
}
