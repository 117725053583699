import requests from "./apiRequest";

import { ILockboxSummary_req_body, LockboxSummaryReportRequest } from "../interfaces/ILockboxSummary";

const LockboxdataSummary = {
  details: async (body: ILockboxSummary_req_body) => {
    try {
      let result = await requests.getParams('summaries/lockbox-summary', body);
      return result;
    } catch (error) {
      console.log('error from lockbox-summaries api integration: ', error);
    }
    },

    generateReport: async (body: LockboxSummaryReportRequest) => {
        let result = await requests.post("summaries/lockbox-summary/report", body);
        return result;
    }
}
export default LockboxdataSummary;
