import { useRef } from "react";
import Pluralize from "../utils/use-pluralize";
import { useFocusOnElemnt } from "../utils/use-focus-on-element";

function HeadingReportingSummary(props: any) {
  const initialTextRef = useRef<HTMLHeadingElement | null>(null);

  useFocusOnElemnt(initialTextRef);
  return (
    <div className="banker-reporting-summary">
      <h4
        ref={initialTextRef}
        tabIndex={-1}
        className="text-style-heading-4  heading-focus-text"
      >
        Reporting summary <Pluralize length={props.length} />
      </h4>
    </div>
  );
}
export default HeadingReportingSummary;
