import requests from "./apiRequest";
import { IClients_req_body } from "../interfaces/IClientsLockboxSearch";

export const searchClients = async (body: IClients_req_body) => {
    try {
      let result = await requests.getParams('/clients', body);
      return result;
    } catch (error) {
      console.log('error from client search api integration: ', error);
    }
  }

  export const addClientEmulation = async (code: string) => {
    try {
        await requests.postParams("users/client-emulation/" + code);
    } catch (error) {
        console.log('error from remitters/{id} post api integration: ', error);
        return(error)
    }
  }

  export const deleteClientEmulation = async () => {
    await requests.delete("users/client-emulation/");
  }