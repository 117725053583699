import '@ux/balance-base/build/assets/style.css';
import { Container } from '@ux/balance-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom-v6';
import reportWebVitals from './reportWebVitals';
import { router } from './routing/routes';
import './styles/local-app-styles.scss'; // Project styles after Balance CSS.

//@ts-ignore
ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <div className='container_gap'>
            <Container isFullWidth>
                <RouterProvider router={router} />
            </Container>
        </div>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
