type EmptyStateProps = {
    description?: string
    descriptionClassName?: string 
}

function EmptyState({ description, descriptionClassName }: EmptyStateProps) {
    return (
        <div>
            {description && <p className={descriptionClassName}>{description}</p> }
        </div>
    )
}

export default EmptyState;
