interface DisplayCurrencyProps {
    currency?: number
};

function CurrencyDisplay({ currency }: DisplayCurrencyProps) {
    if (currency === undefined) {
        return <span></span>
    }

    const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(currency);

    return (
        <span>{formattedValue}</span>
    )
}

export default CurrencyDisplay;