/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, forwardRef, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import UTIF from 'utif';
import IconEnlarge from '../../src/assets/images/IconEnlarge.png';
import { LastFocusedImageBtn } from '../atom';

interface ImageViewerProps {
    id: string
    correspondenceImage?: boolean
    imgSource: string
    onClickEnlarge: () => void
    children?: ReactNode
    altImageText: string
    iconButtonRef: React.RefObject<HTMLButtonElement>
    imageButtonRef: React.RefObject<HTMLButtonElement>
}

const ImageViewer = forwardRef<HTMLButtonElement | null, ImageViewerProps>(
    ({ correspondenceImage, imgSource, id, onClickEnlarge, iconButtonRef, imageButtonRef, children, altImageText }, ref) => {
    const [imgDimension, setImgDimension] = useState<{width: number, height: number}>({width: 0, height: 0});
    const setLastFocusedBtn = useSetRecoilState(LastFocusedImageBtn);

    useEffect(() => {
        UTIF.replaceIMG();
    }, [correspondenceImage, imgSource, onClickEnlarge, children]);

    const handleClick = (buttonType: 'icon' | 'image') => {
        onClickEnlarge();
        setLastFocusedBtn(prev => ({...prev, [id]: buttonType}));
        UTIF.replaceIMG();
    }

    const handleImgLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const { naturalHeight } = event.currentTarget as HTMLImageElement;
        const { naturalWidth } = event.currentTarget as HTMLImageElement;
        setImgDimension({width: naturalWidth, height: naturalHeight});
    }
    
    const showTop25 = imgDimension.height >  imgDimension.width;
    const showTopStyles: React.CSSProperties ={
        clipPath: showTop25 ? 'inset(0 0 75% 0)' : 'none',
    }

    if (!correspondenceImage) {
        return (
            <div className='image-container'>
                {
                    (typeof imgSource !== 'undefined' && imgSource !== 'undefined') ?
                        <div>
                            <div className="image-enlarge-button">
                                <span className='enlarge-text' aria-hidden="true" id='enlarge-instructions'>Click image to enlarge</span><button onClick={() => {handleClick('icon')}} tabIndex={0} autoFocus ref={iconButtonRef}><span className='sr-only'>enlarge image</span><img alt="" className='image-enlarge-icon' src={IconEnlarge} /></button>
                            </div>
                            <div className="image">
                                <button aria-describedby='enlarge-instructions' onClick={() => { handleClick('image')}} tabIndex={0} autoFocus ref={imageButtonRef}>
                                    <img
                                        alt={altImageText || 'check/document image'}
                                        className="small-image"
                                        src={`${imgSource}`}
                                        style={showTopStyles}
                                        onLoad={handleImgLoad}
                                        crossOrigin="anonymous"
                                    />
                                </button>
                            </div>
                        </div>
                    :
                        <p>No Image Available</p>
                }
                <div className="image-pagination">
                    {children}
                </div>
            </div>
        )
    } else {
        return (
            <div className='image-container'>
                {
                    (typeof imgSource !== 'undefined' && imgSource !== 'undefined') ?
                        <div>
                            <div className="image-enlarge-button">
                            <span className='enlarge-text' aria-hidden="true" id='enlarge-instructions'>Click image to enlarge</span><button onClick={() => {handleClick('icon')}} tabIndex={0} autoFocus ref={iconButtonRef}><span className='sr-only'>enlarge image</span><img alt="" className='image-enlarge-icon' src={IconEnlarge} /></button>
                            </div>
                            <div className="image">
                                <button aria-describedby='enlarge-instructions' onClick={() => { handleClick('image')}} tabIndex={0} autoFocus ref={imageButtonRef}>
                                    <img
                                        alt={altImageText || 'check/document image'}
                                        className="small-image"
                                        src={`${imgSource}`}
                                        style={showTopStyles}
                                        onLoad={handleImgLoad}
                                        crossOrigin="anonymous"
                                    />
                                </button>
                            </div>
                        </div>
                    :
                        <p>No Image Available</p>
                }
            </div>
        )
    }

});

export default ImageViewer;