import requests from "./apiRequest";

export interface Site {
    code: string,
    name: string
};

export const getSites = async (): Promise<Site[]> => {
    let sites = await requests.get("/sites");
    return sites;
}
