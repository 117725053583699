import { AdvancedTable } from '@ux/balance-react';

function Table(props: any) {

    return (
        <div className='responsive-table'>
            <AdvancedTable 
                ariaDescribedBy={props.ariaDescribedBy || null}
                caption={props.caption}
                classes={props.classes}
                columnData={props.columnData}
                data={props.data}
                dataTestId={null}
                hasFooter={false}
                hasPagination
                hasVerticalDividers={false}
                isContained={false}
                isLined={false}
                isSortable
                showCaption={false}
                showFooterLabelInFooter={false}
                showStatus
                tablePaginationSizes={
                    [
                        10,
                        20,
                        30,
                        40
                    ]
                }
                tableRowComponents={props.tableRowComponents}
                showSupplementalStatus={props.showSupplementalStatus || false}
                supplementalStatus={props.supplementalStatus}
                isFullWidth
            />
        </div>
    )
}
export default Table;
