function Loader(props: any) {
  if (props.transactionLoader) {
    return (
      <div role="alert" aria-live="polite" className={props.isLoading ? 'custom-loader-visible' : 'custom-loader-hidden'}>
        <div className="loader">
          <div className="loader__icon-wrapper">
            <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" fill-rule="currentColor" aria-hidden="true" role="presentation" className="icon icon--loading" data-testid="loading-icon">
              <path d="M9 17a8 8 0 1 0-.06 0" stroke="currentColor" fill="transparent"></path>
            </svg>
            <span className="sr-only">Content is currently loading.</span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="loader-section" role="alert" aria-live="polite">
        <div className="loader">
          <div className="loader__icon-wrapper">
            <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" fill-rule="currentColor" aria-hidden="true" role="presentation" className="icon icon--loading" data-testid="loading-icon">
              <path d="M9 17a8 8 0 1 0-.06 0" stroke="currentColor" fill="transparent"></path>
            </svg>
            <span className="sr-only">Content is currently loading.</span>
          </div>
        </div>
      </div>
    );
  }
}
export default Loader;
