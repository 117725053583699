import { OktaAuthOptions } from "@okta/okta-auth-js";
import { getEnvironmentFromHostname } from "./utils/config-utils";

const BASENAME = process.env.PUBLIC_URL || '';
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;
const SCOPES = ['lbiq', 'openid', 'offline_access'];

type Configuration = {
    apiUrl: string;
    oidc: OktaAuthOptions;
}

const getConfiguration = (): Configuration => {
    const environment = getEnvironmentFromHostname(window.location.hostname);

    switch (environment) {
        case 'lockbox-dev':
            return {
                apiUrl: "https://lockbox-dev-api.regionstest.com",
                oidc: {
                    clientId: "0oa9dkqablv71eiUD1d7",
                    issuer: "https://regionscommercialfedtest.regionstest.com/oauth2/aus66uwn10xKW38t41d7",
                    redirectUri: REDIRECT_URI,
                    scopes: SCOPES,
                    pkce: true
                }
            };
        case 'lockbox-test':
            return {
                apiUrl: "https://lockbox-test-api.regionstest.com",
                oidc: {
                    clientId: "0oaa2uyhrnBYCPd3m1d7",
                    issuer: "https://regionscommercialfedqa.regionsqa.com/oauth2/aus69yzfbqndPIHHh1d7",
                    redirectUri: REDIRECT_URI,
                    scopes: SCOPES,
                    pkce: true
                }
            };
        case 'production':
            return {
                apiUrl: "https://lockbox-api.regions.com",
                oidc: {
                    clientId: "0oadjoy9ytOKwxWCG5d7",
                    issuer: "https://regionscommercialfed.regions.com/oauth2/aus7jbvl0d736HZjx5d7",
                    redirectUri: REDIRECT_URI,
                    scopes: SCOPES,
                    pkce: true
                }
            };
    }

    return {
        apiUrl: "https://6sm95ktyqi.execute-api.us-east-1.amazonaws.com/sandbox",
        oidc: {
            clientId: "0oa97yqcw81gPv5pd1d7",
            issuer: "https://regionscommercialfeddev.regionstest.com/oauth2/aus4wfe3w81Dzm89L1d7",
            redirectUri: REDIRECT_URI,
            scopes: SCOPES,
            pkce: true
        }
    };
}

export default getConfiguration;
