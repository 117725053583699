import { useRecoilValue } from "recoil";
import { userState, ClientNameBanker, ClientSelected } from "../atom";
import Breadcrumbs from "./Breadcrumbs";
import DateDisplay from "./DateDisplay";
import ExceptionBreadcrumbs from "./ExceptionBreadcrumbs";

const ClientHeader = (props: any) => {
    const user = useRecoilValue(userState);
    const clientNameBanker = useRecoilValue(ClientNameBanker);
    const client_selected = useRecoilValue(ClientSelected);

    return (
        <div className="client-header">
            {(props.showClientName) ?
                <div className='lockbox-name'>
                    <p className='text-style-heading-3'>{(client_selected && user.isBanker) ? clientNameBanker :(!client_selected && user.isBanker) ? 'Regions associate' :  user.clientName}</p>
                </div>
                : (props.bankerLockboxSearch) ?
                    <div className='lockbox-name'>
                        <p className='text-style-heading-3'>Regions associate</p>
                    </div>
                : (props.helpAndSupport) ?
                <div className='lockbox-name'>
                    <p className='text-style-heading-3'>Help and support</p>
                </div>
                :
                <></>
            }
            <div>
                <hr className='menu-devider-header' />
            </div>
            <div className={`showBreadcrumbs ? timestamp-breadcrumbs-container : timestamp-container`}>
                <div>
                    {
                        props.showExceptionsBreadcrumbs ?
                         <ExceptionBreadcrumbs />
                         :
                         <Breadcrumbs />
                    }
                </div>
                {user.lastLogin && (
                    <div className="time-stamp">
                        <p className='text-style-body-4-italic'>Last login on {<DateDisplay date={user.lastLogin} format="fullDate" />}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ClientHeader;
